import LoginForm from "./LoginForm";
import { H2, P } from "../AbstractElements";
import {
  AccountMissed,
  OneOfUs,
} from "../Constant";

const LoginPage = () => {
  return (
    <div>
        <div className="cont text-center">
          <LoginForm/>
          <div className="sub-cont">
            <div className="img">
              <div className="img__text m--up">
                <H2>{OneOfUs}</H2>
                <P>{AccountMissed}</P>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default LoginPage;
