import { Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "sweetalert2";
import {
  deleteAdminAsync,
  getAllAdminsAsync,
} from "../../../redux-toolkit/slices/adminSlice";
import TableComp from "../../../Components/dataTable/TableComp";
import { useEffect, useState } from "react";
import { Edit, Trash, Lock } from "react-feather";
import { useNavigate } from "react-router-dom";
import UpdatePasswordForm from "./UpdatePasswordForm";
const AdminPageContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen2, setIsOpen2] = useState(false);

  const adminsData = useSelector((state) => state.admin.admins);
  useEffect(() => {
    dispatch(getAllAdminsAsync({ page: 1, limit: 10 }));
  }, [dispatch]);

  const handelNavigateEditService = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/affectation-manager/admins/edit/${rowName}`, { state: row });
    props.setIsOpen(true);
  };

  const handelDeleteAdmin = async (row) => {
    const adminId = [row?._id];
    if (!adminId) {
      return;
    }
      SweetAlert.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this admin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            const deletedAdmin = await dispatch(deleteAdminAsync(adminId));
            if (deleteAdminAsync.fulfilled.match(deletedAdmin)) {
              SweetAlert.fire(
                "Deleted",
                deletedAdmin.payload.data.message,
                "success"
              );
              dispatch(getAllAdminsAsync({page:1,limit:10}));
            } else {
              SweetAlert.fire("Error!", `${deletedAdmin.payload}`, "error");
            }
          } catch (error) {
            SweetAlert.fire("Error!", "Admin could not be deleted.", "error");
          }
        }
      });
  };

  const handleEditPassword = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/affectation-manager/admins/editPassword/${rowName}`, {
      state: row,
    });
    setIsOpen2(true);
  };

  const AdminsTableColumns = [
    {
      name: "Email",
      selector: (row) => row["email"],
      sortable: true,
      center: true,
    },
    {
      name: "Username",
      selector: (row) => row["username"],
      sortable: true,
      center: true,
    },
    {
      name: "FirstName",
      selector: (row) => row?.firstName,
      sortable: true,
      center: true,
    },
    {
      name: "LastName",
      selector: (row) => row?.lastName,
      sortable: true,
      center: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a className="btn-xs" style={{ cursor: "pointer" }}>
            <Trash
              width={20}
              color="red"
              onClick={() => handelDeleteAdmin(row)}
            />
          </a>
          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditService(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
          <a
            className="btn-xs"
            title="EditPassword"
            onClick={() => handleEditPassword(row)}
            style={{ cursor: "pointer" }}
          >
            <Lock color="orange" width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <>
      <UpdatePasswordForm isOpen2={isOpen2} setIsOpen2={setIsOpen2} />
      <Container fluid={true}>
        <Row>
          <TableComp
            data={adminsData}
            columns={AdminsTableColumns}
            toggleDelete={false}
            expandableRows={false}
            highlightOnHover={true}
            fixedHeader={true}
            customStyles={customStyles}
          />
        </Row>
      </Container>
    </>
  );
};

export default AdminPageContainer;
