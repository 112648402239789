import axiosInstance from "../../api/axios";

const GET_ALL_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service?page=1&perPage=10`;
const ADD_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service/addService`;
const DELETE_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service/delete`;
const UPDATE_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service/update`;

export const getAllServices = async (establishmentId) => {
  try {
    const response = await axiosInstance.post(GET_ALL_SERVICES, {
      establishment: establishmentId,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const addNewService = async (serviceData) => {
  try {
    const response = await axiosInstance.post(ADD_SERVICES, serviceData);
    return response;
  } catch (error) {
    console.log("🚀 ~ addNewService ~ error:", error)
    throw error;
  }
    
};

export const deleteService = async (serviceId) => {
  try {
    const response = await axiosInstance.delete(
      `${DELETE_SERVICES}/${serviceId}`
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateService = async (serviceId, serviceData) => {
  console.log(
    "🚀 ~ file: serviceService.jsx:48 ~ updateService ~ serviceData:",
    serviceData
  );
  try {
    const response = await axiosInstance.post(
      `${UPDATE_SERVICES}/${serviceId}`,
      serviceData
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
