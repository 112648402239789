import axiosInstance from "../../api/axios";

const GET_ALL_OPTION_TEMPLATES = `${process.env.REACT_APP_BACK_URL}/api/v1/optionTemplate/`;
const CREATE_OPTION_TEMPLATE= `${process.env.REACT_APP_BACK_URL}/api/v1/optionTemplate/createOptionTemplate`;
const DELETE_OPTION_TEMPLATE= `${process.env.REACT_APP_BACK_URL}/api/v1/optionTemplate/deleteOptionTemplate`;
const UPDATE_OPTION_TEMPLATE= `${process.env.REACT_APP_BACK_URL}/api/v1/optionTemplate/updateOptionTemplate`;

export const getAllOptionTemplates = async (page, limit) => {
    try {
      const queryParams = new URLSearchParams();
      if (page) queryParams.append('page', page);
      if (limit) queryParams.append('limit', limit);
    
      const url = `${GET_ALL_OPTION_TEMPLATES}?${queryParams.toString()}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      throw error;
    }
};

export const createOptionTemplate = async (data) => {
    try {
        const response = await axiosInstance.post(`${CREATE_OPTION_TEMPLATE}`,data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateOptionTemplate= async ({optionTemplateId, data}) => {
    try {
      const response = await axiosInstance.put(`${UPDATE_OPTION_TEMPLATE}/${optionTemplateId}`, data);
      return response;
    } catch (error) {
        throw error;
    }
  };

export const deleteOptionTemplate = async (optionTemplateId) => {
    try {
        const response = await axiosInstance.delete(`${DELETE_OPTION_TEMPLATE}/${optionTemplateId}`);
        return response;
    } catch (error) {
        throw error;
    }
        
};