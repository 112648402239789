import { AddNewAdmin } from "../../../Constant";
import { Row, Col, Button, Form, Label, FormGroup } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import AdminPageContainer from "./AdminPageContainer";
import { FormControl } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getAllAdminsAsync } from "../../../redux-toolkit/slices/adminSlice";
import { toast } from "react-toastify";
import { useState } from "react";
import AdminsForm from "./AdminsForm";

const AdminPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handelNavigateToForm = () => {
    // navigate("/affectation-manager/admins/new");
    setIsOpen(true);
  };

  const { handleSubmit, control,reset } = useForm({
    defaultValues: {
      username : "",
      phone: ""
    }
  });

  const onSubmit = async (data) => {
    try {
      const filteredAdmins = await dispatch(getAllAdminsAsync(data,{page:1,limit:10}));
      if (getAllAdminsAsync.rejected.match(filteredAdmins))
        toast.error("Error",filteredAdmins?.payload);
    } catch (error) {
      toast.error("Fatal error getting admins !");
    }
  };

  const handleResetAdmins = () => {
    reset();
    dispatch (getAllAdminsAsync({page:1,limit:10}))
    }
  return (
    <>
      <AdminsForm
      isOpen = {isOpen}
      setIsOpen = {setIsOpen}
      />
      <div className="page-body">
        <Breadcrumbs />
          <Row
            className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
            style={{ marginTop: "-48px", marginBottom: "20px" }}
          >
            <Col xl="6" className="d-flex align-items-center" >
                <Form className="w-100 d-flex justify-content-between align-items-end my-2" onSubmit={handleSubmit(onSubmit)}>
                      <Col xl="4" md="4" sm="12">
                      <Label>Username</Label>
                      <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            {...field}
                            type="search"
                            placeholder="username"
                            aria-label="username"
                          />
                        )}
                      />
                      </Col>
                      <Col xl="4" md="4" sm="12">
                        <Label>Phone</Label>
                        <Controller
                          name="phone"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              {...field}
                              type="search"
                              placeholder="phone"
                              aria-label="phone"
                            />
                          )}
                        />
                      </Col>
                      <Col xl="auto" md="auto" sm="12">
                        <Button variant="outline-success" type="submit">
                          Search
                        </Button>
                      </Col>
                      <Col xl="auto" md="auto" sm="12">
                        <Button color="warning" onClick={()=> handleResetAdmins()}>
                          Reset
                        </Button>
                      </Col>
                </Form>
            </Col>
            <Col xl="3" sm="12" className="d-flex align-items-end justify-content-end">
                <Btn
                  color="btn btn-primary mb-2"
                  onClick={() => handelNavigateToForm()}
                >
                  {t(AddNewAdmin)}
                </Btn>
            </Col>
          </Row>
        <AdminPageContainer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
      </div>
    </>
    
  );
};
export default AdminPage;