import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEstablishment, getAllEstablishments, deleteEstablishment, createEstablishmentWithRole, updateEstablishment, createEstablishment } from "../services/establishmentService";
import axiosInstance from "../../api/axios";

const initialState = {
  establishments: [],
  loading: false,
  error: null,
};

export const getAllEstablishmentsAsync = createAsyncThunk(
  "Establishment/fetchData",
  async (params = {}, { rejectWithValue }) => {
    const { page = null, limit = null, name = "" } = params;
    try {
      const response = await getAllEstablishments({page,limit,name});
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);

export const createEstablishmentAsync = createAsyncThunk(
  "Establishment/create",
  async (data,{ rejectWithValue }) => {
    try {
      const response = await createEstablishment(data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);

export const createEstablishmentWithRoleAsync = createAsyncThunk(
  "Establishment/createWithRole",
  async (data,{ rejectWithValue }) => {
    try {
      const response = await createEstablishmentWithRole(data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);

export const deleteEstablishmentAsync = createAsyncThunk(
  "Establishment/delete",
  async(establishmentId,{rejectWithValue}) => {
    try {
      const response = await deleteEstablishment(establishmentId);
      return response ;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const updateEstablishmentAsync = createAsyncThunk(
  "establishment/update",
  async ({ establishmentId, establishmentData },{rejectWithValue}) => {
    try {
      const response = await updateEstablishment(establishmentId, establishmentData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



const establishmentSlice = createSlice({
  name: "establishment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getAllEstablishmentsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

    .addCase(getAllEstablishmentsAsync.fulfilled, (state, action) => {
      state.establishments = action.payload.data;
      state.loading = false;
      state.error = null;
    })

    .addCase(getAllEstablishmentsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(createEstablishmentWithRoleAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
    .addCase(createEstablishmentWithRoleAsync.fulfilled, (state, action) => {
      if (action.payload.data.etablissement) {
        state.establishments.push(action.payload.data.etablissement);
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(createEstablishmentWithRoleAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(updateEstablishmentAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updateEstablishmentAsync.fulfilled, (state, action) => {
      // Mettre à jour l'établissement dans le state après la réussite de la mise à jour
      const updatedEstablishment = action.payload.data;
      const index = state.establishments.findIndex(est => est._id === updatedEstablishment._id);
      if (index !== -1) {
        state.establishments[index] = updatedEstablishment;
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(updateEstablishmentAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(deleteEstablishmentAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deleteEstablishmentAsync.fulfilled, (state, action) => {
      const deletedEstablishmentId = action.payload.data;
      state.establishments = state.establishments.filter(
        (est) => est._id !== deletedEstablishmentId
      );
      state.loading = false;
      state.error = null;
    })
    .addCase(deleteEstablishmentAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const establishmentReducer = establishmentSlice.reducer;
export const { actions } = establishmentSlice;
