import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdmin, getAllAdmins, deleteAdmin, createAdmin, updateAdmin, updateAdminPassword } from "../services/adminService";
import axiosInstance from "../../api/axios";

const initialState = {
  admins: [],
  loading: false,
  error: null,
};

export const getAllAdminsAsync = createAsyncThunk(
  "Admin/fetchData",
  async (params={},{ rejectWithValue }) => {
    const { username = '', phone = '', page = null, limit = null }=  params;
    try {
      const response = await getAllAdmins({username,phone,page,limit});
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);

export const createAdminsAsync = createAsyncThunk(
  "Admin/create",
  async (adminData,{ rejectWithValue }) => {
    try {
      const response = await createAdmin(adminData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);

export const deleteAdminAsync = createAsyncThunk(
  "Admin/delete",
  async(adminId,{rejectWithValue}) => {
    try {
      const response = await deleteAdmin(adminId);
      return response ;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const updateAdminAsync = createAsyncThunk(
  "admin/update",
  async ({ adminId, adminData },{rejectWithValue}) => {
    try {
      const response = await updateAdmin({adminId, adminData});
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAdminPasswordAsync = createAsyncThunk(
  "admin/updatePassword",
  async({adminId,updatedAdminPasswordData}, {rejectWithValue}) => {
    try {
      const response = await updateAdminPassword({adminId,updatedAdminPasswordData});
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)


const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getAllAdminsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

    .addCase(getAllAdminsAsync.fulfilled, (state, action) => {
      state.admins = action.payload.data;
      state.loading = false;
      state.error = null;
    })

    .addCase(getAllAdminsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(createAdminsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
    .addCase(createAdminsAsync.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.admins.push(action.payload.data);
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(createAdminsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(updateAdminAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updateAdminAsync.fulfilled, (state, action) => {
      // Mettre à jour l'admin dans le state après la réussite de la mise à jour
      const updatedAdmin = action.payload.data;
      const index = state.admins.findIndex(admin => admin._id === updatedAdmin._id);
      if (index !== -1) {
        state.admins[index] = updatedAdmin;
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(updateAdminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateAdminPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateAdminPasswordAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updateAdminPasswordAsync.fulfilled, (state, action) => {
      const updatedAdmin = action.payload.data;
      const index = state.admins.findIndex(admin => admin._id === updatedAdmin._id);
      if (index !== -1) {
        state.admins[index].password = updatedAdmin.password;
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(deleteAdminAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deleteAdminAsync.fulfilled, (state, action) => {
      const deletedAdminId = action.payload.data;
      state.admins = state.admins.filter(
        (est) => est._id !== deletedAdminId
      );
      state.loading = false;
      state.error = null;
    })
    .addCase(deleteAdminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const adminReducer = adminSlice.reducer;
export const { actions } = adminSlice;
