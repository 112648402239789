import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LayoutRoutes from "./LayoutRoutes";
import PrivateRoute from "./PrivateRoute";
import Logins from "../Auth";
import { useSelector } from "react-redux";
import routes from "./Route";
import Layout from "../Layout/Layout";
import PublicRoute from "./PublicRoute";

const Routers = () => {
  // const login = localStorage.getItem("login");
  const accessToken = localStorage.getItem("accessToken");
  console.log("🚀 ~ file: index.tsx:14 ~ Routers ~ accessToken:", accessToken);

  const redirectAuth = () => {
    if (accessToken) {
      return <Navigate to="/dashboard" />;
    } else {
      return <Navigate to="/login" />;
    }
  };
  //one
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={redirectAuth()} />

        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Logins />} />
        </Route>

        <Route element={<PrivateRoute />}>
          {routes.map(({ path, Component }, i) => (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          ))}
        </Route>

        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
