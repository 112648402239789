import { AddNewPrestation } from "../../../Constant";
import { Row, Col, Label, Form, Button } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import PrestationPageContainer from "./PrestationPageContainer"
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getAllPrestationsAsync } from "../../../redux-toolkit/slices/prestationSlice";
import { FormControl } from "react-bootstrap";

const PrestationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelNavigateToForm = () => {
    navigate("/affectation-manager/prestations/new");
  };

  const { handleSubmit, control,reset } = useForm({
    defaultValues: {
      name : "",
    }
  });
  const onSubmit = async (data) => {
    try {
      const filteredPrestations = await dispatch(getAllPrestationsAsync(data));
      if (getAllPrestationsAsync.rejected.match(filteredPrestations))
        toast.error("Error",filteredPrestations?.payload);
    } catch (error) {
      toast.error("Fatal error getting Prestations !");
    }
  };
  
  return (
    <div className="page-body">
      <Breadcrumbs />
        <Row
          className={`d-flex align-items-end justify-content-center bg-light-info p-2`}
          style={{ marginTop: "-48px", marginBottom: "20px" }}
        >
          <Col xl="6" lg="6" md="6" sm="12" className="d-flex align-items-center" >
              <Form className="w-100 d-flex justify-content-between align-items-end my-2" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="d-flex align-items-end">
                      <Col md="6" sm="6">
                        <Label>Prestation name</Label>
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              {...field}
                              type="search"
                              placeholder="Prestation name"
                              aria-label="Prestation name"
                            />
                          )}
                        />
                      </Col>
                      <Col  md="3" sm="3">
                        <Button variant="outline-success" type="submit">
                          Search
                        </Button>
                      </Col>
                      <Col  md="3" sm="3">
                        <Button color="warning" onClick={()=> {
                          reset();
                          dispatch (getAllPrestationsAsync())
                          }}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                </Form>
          </Col>
          <Col xl="6" lg="6" md="6" sm="12" className="d-flex align-items-center justify-content-end">
            <Btn
              color="btn btn-primary mb-2"
              onClick={() => handelNavigateToForm()}
            >
              {t(AddNewPrestation)}
            </Btn>
          </Col>
        </Row>
      <PrestationPageContainer/>
    </div>
  );
};
export default PrestationPage;