// import { Fragment, useState } from "react";
// import { Col, Card, CardBody, Form, CardHeader } from "reactstrap";
// import Dropzone from "react-dropzone-uploader";
// import { H5 } from "../../AbstractElements";

// const UploadDropzone = (props) => {
//   console.log("🚀 ~ UploadDropzone ~ props:",`${process.env.REACT_APP_MINIO_URL}${props.image}`)
//   // const { getUploadParams = "", handleChangeStatus = "" } = props;
  
//   const { getUploadParams = ""} = props;
//   console.log("🚀 ~ UploadDropzone ~ props:llllllllllllllllllllllllllllllllll", props)
//   const [imageURL, setImageURL] = useState(`${process.env.REACT_APP_MINIO_URL}${props.image}`)
//   const handleChangeStatus = ({ meta, file }, status) => {
//     if (status === "done") {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         setImageURL(e.target.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };
//   return (
//     <Fragment>
//        <div className="upload-container" style={{ position: "relative", width: "100%" }}>
//         <div className="image-preview" style={{ position: "absolute", width: "100%" }}>
//           <img src={imageURL} alt="Uploaded or default" style={{ width: "100%" }} />
//         </div>
//         <div className="dz-message needsclick" style={{ position: "relative", zIndex: 1,  height: "100%", width: "100%" }}>
//           <Dropzone
//             getUploadParams={getUploadParams}
//             onChangeStatus={handleChangeStatus}
//             maxFiles={1}
//             multiple={false}
//             canCancel={true}
//             inputContent="Drop A File"
//             styles={{
//               dropzone: { height: "100%", borderColor: "transparent" },
//               dropzoneActive: { borderColor: "green" },
//             }}
//             accept="image/png, image/jpeg"
//           />
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default UploadDropzone;
   

import React, { useState, Fragment } from 'react';
import Dropzone from 'react-dropzone-uploader';

const UploadDropzone = (props) => {
  const { getUploadParams, handleChangeStatus } = props;

  const [imageURL, setImageURL] = useState(`${process.env.REACT_APP_MINIO_URL}${props.image}`);

  const handleChangeStatuss = ({ meta, file }, status) => {
    if (handleChangeStatus) {
      handleChangeStatus({ meta, file }, status);
    }
    
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Fragment>
      <div className="upload-container" style={{ position: "relative", width: "100%" }}>
        {/* <div className="image-preview" style={{ position: "absolute", width: "100%" }}>
          <img src={imageURL} alt="Uploaded or default" style={{ width: "100%" }} />
        </div> */}
        <div className="dz-message needsclick" style={{ position: "relative", zIndex: 1, height: "100%", width: "100%" }}>
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatuss}
            maxFiles={1}
            multiple={false}
            canCancel={true}
            inputContent="Drop A File"
            styles={{
              dropzone: { height: "100%", borderColor: "transparent", color: "#ffffff" },
              dropzoneActive: { borderColor: "green" },
            }}
            accept="image/png, image/jpeg"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default UploadDropzone;



 // <Fragment>
    //   <div className="dz-message needsclick">
    //     <Dropzone
    //       getUploadParams={getUploadParams}
    //       onChangeStatus={handleChangeStatus}
    //       maxFiles={1}
    //       multiple={false}
    //       canCancel={true}
    //       inputContent="Drop A File"
    //       styles={{
    //         dropzoneActive: { borderColor: "green" },
    //       }}
    //       accept="image/png, image/jpeg"
    //     />
    //   </div>
    // </Fragment>