import { Col, Container, Row, Card, CardBody } from "reactstrap";
import HeadingCommon from "../../Common/HeadingCommon";
import { SampleCard } from "../../Constant";
import { P, Image } from "../../AbstractElements";
import CardService from "./ServiceCard/CardService";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteServices,
  fetchServices,
  fetchSubServices,
} from "../../redux-toolkit/slices/serviceSlice";
import TableComp from "../../Components/dataTable/TableComp";
import { Btn, H4 } from "../../AbstractElements";
import { Delete, AddNewService } from "../../Constant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Eye, Edit, Trash } from "react-feather";
import { dynamicImage } from "../../Service";
import { useNavigate } from "react-router-dom";
import { getAllServices } from "../../redux-toolkit/services/serviceService";
import { toast } from "react-toastify";

const ServicePageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const servicesData = useSelector((state) => state.services.services);
  console.log(
    "🚀 ~ file: index.jsx:22 ~ ServicePageContainer ~ servicesData:",
    servicesData
  );
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (userAdmin) {
      dispatch(
        fetchServices({
          establishmentId: affectationSelected?.establishments._id,
        })
      );
    }
  }, [dispatch, affectationSelected]);

  const handelModal = () => {
    setIsOpen(!isOpen);
  };
  const handelNavigateEditService = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/services-manager/services/edit/${rowName}`, { state: row });
  };

  const handelDeleteService = async (row) => {
    console.log("rrrrrrrrrrow", row);
    if (row?.products?.length <= 0) {
      try {
        const response = await dispatch(deleteServices(row?._id));
        if (response?.message) {
          toast.error(response.message);
        } else {
          dispatch(
            fetchServices({
              establishmentId: affectationSelected?.establishments._id,
            })
          );

          toast.success("Service deleted successfully!");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the service.");
      }
    } else {
      toast.error("This Service has already Products! ");
      toast.error("You must delete the products at first!");
    }
  };
  const ServiceTableColumns = [
    {
      name: "Image",
      selector: (row) => (
        <div>
          {console.log("rrr", row)}
          <Image
            src={
              row?.images[0]
                ? process.env.REACT_APP_MINIO_URL + row?.images[0]
                : dynamicImage("ecommerce/options.png")
            }
            style={{ width: "70px", padding: "10px" }}
          />
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      center: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Departement",
      selector: (row) => row?.departement?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Products",
      selector: (row) => row?.products?.length,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Trash
              width={20}
              color="red"
              onClick={() => handelDeleteService(row)}
            />
          </a>
          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditService(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <Container fluid={true}>
      <Row>
        <TableComp
          data={servicesData}
          columns={ServiceTableColumns}
          // expandableRowsComponent={ExpandedComponent}
          // handleRowSelected={handleRowSelected}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          fixedHeader={true}
          customStyles={customStyles}
          // onRowClicked={(row) => handelNavigateToSubCategory(row)}
        />
      </Row>
    </Container>
  );
};

export default ServicePageContainer;
