import axiosInstance from "../../api/axios";

const GET_ALL_PACK_OPTIONS = `${process.env.REACT_APP_BACK_URL}/api/v1/packOption/`;
const GET_PACK_OPTIONS_BY_PACK_ID = `${process.env.REACT_APP_BACK_URL}/api/v1/packOption/getPackOptionByPackId`;
const CREATE_PACK_OPTION = `${process.env.REACT_APP_BACK_URL}/api/v1/packOption/createPackOption`;
const DELETE_PACK_OPTION = `${process.env.REACT_APP_BACK_URL}/api/v1/packOption/deletePackOption`;
const UPDATE_PACK_OPTION = `${process.env.REACT_APP_BACK_URL}/api/v1/packOption/updatePackOption`;

export const getAllPackOptions = async (page, limit) => {
  try {
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);

    const url = `${GET_ALL_PACK_OPTIONS}?${queryParams.toString()}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPackOptionsByPackId = async (packId) => {
  try {
    const response = await axiosInstance.get(
      `${GET_PACK_OPTIONS_BY_PACK_ID}/${packId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createPackOption = async (data) => {
  try {
    const response = await axiosInstance.post(`${CREATE_PACK_OPTION}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePackOption = async ({ packOptionId, data }) => {
  try {
    const response = await axiosInstance.put(
      `${UPDATE_PACK_OPTION}/${packOptionId}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletePackOption = async (packOptionId) => {
  try {
    const response = await axiosInstance.delete(
      `${DELETE_PACK_OPTION}/${packOptionId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
