import { useEffect, useState } from "react";
import ServicePageContainer from "../../../Components/ServicePage";
import { AddNewService } from "../../../Constant";
import { Row, Col } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
const ServicePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handelNavigateToForm = () => {
    navigate("/services-manager/services/new");
  };
  //test
  return (
    <div className="page-body">
      
      <Breadcrumbs />

      <Row
        className={`d-flex align-items-center justify-content-end bg-light-info p-2`}
        style={{ marginTop: "-48px", marginBottom: "20px" }}
      >
        <Col xl="4" className="d-flex align-items-center justify-content-end">
          <Btn
            color="btn btn-primary mb-2"
            onClick={() => handelNavigateToForm()}
          >
            {t(AddNewService)}
          </Btn>
        </Col>
      </Row>
      <ServicePageContainer/>
    </div>
  );
};

export default ServicePage;
