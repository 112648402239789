import React from "react";
import DataTable from "react-data-table-component";

const TableComp = ({
  columns,
  data,
  handleRowSelected,
  toggleDelete,
  expandableRowsComponent,
  expandOnRowClicked,
  fixedHeader,
  expandableRows,
  highlightOnHover,
  customStyles,
  selectableRowsSingle,
  onRowClicked,
  selectableRowDisabled,
}) => {
  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        striped={true}
        selectableRowsSingle={selectableRowsSingle}
        pagination
        //    paginationPerPage={10} // Specify the number of rows per page
        // paginationTotalRows={data.length} // Specify the total number of rows
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelete}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        expandOnRowClicked={expandOnRowClicked}
        highlightOnHover={highlightOnHover}
        fixedHeader={fixedHeader}
        customStyles={customStyles}
        onRowClicked={onRowClicked}
        selectableRowDisabled={selectableRowDisabled}
      />
    </div>
  );
};

export default TableComp;
