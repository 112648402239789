import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllServices,
  addNewService,
  deleteService,
  updateService,
} from "../services/serviceService";

const initialState = {
  services: [],
  subService: [],
  newService: null,
  loading: false,
  error: null,
};

export const fetchServices = createAsyncThunk(
  "services/fetchData",
  async ({ establishmentId }) => {
    try {
      const response = await getAllServices(establishmentId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSubServices = createAsyncThunk(
  "services/fetchSubService",
  async ({ establishmentId, id }) => {
    try {
      const response = await getAllServices(establishmentId, id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addService = createAsyncThunk(
  "services/addService",
  async (serviceData,{ rejectWithValue }) => {
    console.log("🚀 ~ file: serviceSlice.jsx:45 ~ serviceData:", serviceData);
    try {
      const response = await addNewService(serviceData);
      return response.data;

  } catch (error) {
    console.log("🚀 ~ error:", error)
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message || "An unexpected error has occurred!");
    }
  }
  }
);


export const updateTheService = createAsyncThunk(
  "services/updateService",
  async ({ serviceId, serviceData }) => {
    console.log("🚀 ~ file: serviceSlice.jsx:55 ~ serviceData:", serviceData);
    try {
      const response = await updateService(serviceId, serviceData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteServices = createAsyncThunk(
  "services/deleteServices",
  async (serviceId) => {
    try {
      const response = await deleteService(serviceId);
      console.log("🚀 ~ file: serviceSlice.jsx:38 ~ response:", response);
      return response.data;
    } catch (error) {
      console.log("🚀 ~ file: serviceSlice.jsx:40 ~ error:", error);
      throw error;
    }
  }
);

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.services = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchSubServices.fulfilled, (state, action) => {
      state.subService = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addService.fulfilled, (state, action) => {
      // state.services = [...state.services, action.payload];
      state.newService = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateTheService.fulfilled, (state, action) => {
      
      // state.services = [...state.services, action.payload];
      state.services = state.services.map((elem)=>{
        if(elem._id === action.payload.data._id) {
          return action.payload.data
        }
        return elem
      })
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateTheService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteServices.rejected, (state, action) => {
      console.log("action rejected", action);
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteServices.fulfilled, (state, action) => {
      console.log("done");
      state.services = state.services.filter(
        (service) => service._id !== action.payload?.data?._id
      );
      state.loading = false;
      state.error = null;
    });
  },
});

export const servicesReducer = servicesSlice.reducer;
export const { actions } = servicesSlice;
