import { Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "sweetalert2";
import {deletePackAsync, getAllPacksAsync} from "../../../redux-toolkit/slices/packSlice";
import TableComp from "../../../Components/dataTable/TableComp";
import { useEffect } from "react";
import { Edit, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
const PacksPageContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const packsData = useSelector((state)=> state.pack.packs);
  useEffect(() => {
      dispatch(getAllPacksAsync({page:1,limit:10}));
    }
  ,[dispatch]);

  const handelNavigateEditPack = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/subscription-manager/packs/edit/${rowName}`, { state: row });
    props.setIsOpen(true);
  };

  const handelDeletePack = async (row) => {
    const packId = [row?._id];
    if (!packId) {
      return;
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this pack?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          const deletedPack = await dispatch(deletePackAsync(packId));
          if (deletePackAsync.fulfilled.match(deletedPack)) {
            SweetAlert.fire("Deleted",deletedPack.payload.data.message,"success");
            dispatch(getAllPacksAsync({page:1,limit:10}));
          } else {
            SweetAlert.fire("Error!",`${deletedPack.payload}`,"error");
          }
        } catch (error) {
          SweetAlert.fire("Error!", "Pack could not be deleted.", "error");
        }
      }
    });
  };

  const PacksTableColumns = [
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row["description"],
      sortable: true,
      center: true,
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
      center: true,
    },
   
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            style={{ cursor: "pointer" }}
          >
            <Trash
              width={20}
              color="red"
              onClick={() => handelDeletePack(row)}
            />
          </a>
          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditPack(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
      <Container fluid={true}>
        <Row>
          <TableComp
            data={packsData}
            columns={PacksTableColumns}
            toggleDelete={false}
            expandableRows={false}
            highlightOnHover={true}
            fixedHeader={true}
            customStyles={customStyles}
          />
        </Row>
      </Container>
  );
};

export default PacksPageContainer;