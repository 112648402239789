import { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch} from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../Components/Inputs/simpleInput";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Btn } from "../../../AbstractElements";
import { createPackAsync, updatePackAsync } from "../../../redux-toolkit/slices/packSlice";
import { message } from "../../../Constant";
function PacksForm(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    let location = useLocation();
    const pathname = location.pathname.split("/");
    const routeParams = useParams();
    const types = ["Establishment","Prestation"]

    const schema = yup.object().shape({
      name: yup.string().required("You must enter the name"),
      description: yup.string().required("You must enter the description"),
      price: yup.number().typeError("You must enter the price"),
      type: yup.string().transform((value) => value?.value ?? value).required("You must select the type"),

      
    });

    const {
      control,
      handleSubmit,
      reset,
      formState: {errors },
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  
   const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("type", data.type);

    if (!pathname.includes("edit")) {
      const packAdded = await dispatch(createPackAsync(formData));
      if(createPackAsync.rejected.match(packAdded)){
        SweetAlert.fire(
          "Error",
          packAdded.payload,
          "error"
        );
      }else{
        SweetAlert.fire(
          "Added!",
          packAdded.payload.data.message,
          "success"
        );
        props.setIsOpen(false);
        reset();
      }
          
    }else {
      const packUpdated = await dispatch(updatePackAsync({
        packId: location?.state?._id,
        data: formData,
      }));
      
      if(updatePackAsync.rejected.match(packUpdated)){
        SweetAlert.fire(
          "Error",
          packUpdated.payload,
          "error"
        );
      }else{
        SweetAlert.fire(
          "Updated!",
          packUpdated.payload.data.message,
          "success"
        );
        props.setIsOpen(false);
        reset();
        navigate('/subscription-manager/packs');
      }
    }
  }
    useEffect(() => {
      if (location?.state && pathname.includes("edit")) {
          reset({
            name: location?.state?.name,
            description: location?.state?.description,
            price: location?.state?.price,
            type:{
              label: location?.state?.type,
              value: location?.state?.type,
            }
        })
      }
      else{
        reset({
          name: "",
            description: "",
            price: null,
            type: null,
        })
      }
    }, [location]);
  
    return (
      <Modal isOpen={props.isOpen} size="lg" centered={true}>
        <ModalHeader>Create New Pack</ModalHeader>
        <ModalBody>
          <div className="page-body">
            <Col xl="12">
              <form className="card">
                <CardBody>
                    <Row>
                      <Col xl="12">
                        <Row>
                          <Col lg="6" className="m-b-15">
                            <Label>name</Label>
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <SimpleInput
                                  {...field}
                                  errclassname="error-msg-input"
                                  className="form-control"
                                  placeholder="Name"
                                  errors={errors.name?.message}
                                />
                              )}
                            />
                          </Col>
                          <Col lg="6" className="m-b-15">
                            <Label>Description</Label>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field }) => (
                                <SimpleInput
                                  {...field}
                                  errclassname="error-msg-input"
                                  className="form-control"
                                  placeholder="Description"
                                  errors={errors.description?.message}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="m-b-15">
                            <Label>Price</Label>
                            <Controller
                              name="price"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="$"
                                  {...field}
                                />
                              )}
                            />
                            <span className="error-msg-input">{errors.price?.message}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="m-b-15">
                            <Label>Type</Label>
                            <Controller
                                name="type"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Select
                                      options={types?.map((t) => ({
                                        label: t,
                                        value: t,
                                      }))}
                                      {...field}
                                    />
                                    {errors.type && <span style={{color:"red"}}>{errors.type.message}</span>}
                                  </>
                                )}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                </CardBody>
              </form>
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Btn
            color="primary"
            onClick={() => {
              reset();
              props.setIsOpen(false);
              navigate(`/subscription-manager/packs`);
            }}
          >
            Close
          </Btn>
          <Button onClick={handleSubmit(onSubmit)} color="secondary">
            Save
        </Button>
        </ModalFooter>
      </Modal>
    );
}
export default PacksForm