import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Col,
  CardBody,
  Row,
  Label,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import UploadDropzone from "../../../../Components/Dropzone/UploadDropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchDepartment } from "../../../../redux-toolkit/slices/departementSlice";
import { getAllCategoryTreeAsync } from "../../../../redux-toolkit/slices/categorySlice";
import { addService } from "../../../../redux-toolkit/slices/serviceSlice";
import {
  addProduct,
  fetchProducts,
  updateProductAsync,
} from "../../../../redux-toolkit/slices/productSlice";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { message } from "../../../../Constant";
import Dropzone from "react-dropzone-uploader";
import { FaTrash } from "react-icons/fa";
const Products = ({ selectedProduct }) => {
  const dispatch = useDispatch();
  const [hasAnImage, setHasAnImage] = useState(false);
  const newService = useSelector((state) => state.services.newService);
  console.log(
    "🚀 ~ file: products.jsx:29 ~ Products ~ newService:",
    newService
  );
  let location = useLocation();
  let routParams = useParams();
  const pathname = location.pathname.split("/");
  const schema = yup.object().shape({
    name: yup.string().required("You must enter a first name"),
    description: yup.string().required("You must enter the description"),
    price: yup.number().typeError("You must enter a price (must be a number)")
  });
  const getUploadParams = (body) => {
    return { url: "https://httpbin.org/post" };
  };
  const handleSubmitFile = ({ meta, file, remove }, status) => {
    setValue("images", [file]);
    if (status === "headers_received") {
      toast.success(`${meta.name} uploaded!`);
    } else if (status === "aborted") {
      toast(`${meta.name}, upload failed...`);
    } else if (status === "removed") {
      resetField("images");
    }
  };

  const {
    control ,
    handleSubmit,
    reset,
    resetField,
    formState: {errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (selectedProduct) {
       if (Array.isArray(selectedProduct.images) && selectedProduct.images.length > 0) {
      setHasAnImage(true);
    }
      reset({
        name: selectedProduct?.name,
        description: selectedProduct?.description,
        type: { label: selectedProduct?.type, value: selectedProduct?.type },
        duration: selectedProduct?.duration,
        price: selectedProduct?.price,
      });
    }
  
  }, [selectedProduct]);

  console.log(
    "🚀 ~ file: index.jsx:98 ~ useEffect ~selectedProduct :",
    selectedProduct
  );


   const [finishEditP,setFinishEditP]=useState(false);
   
  async function onSubmit(data) {
    console.log("dataaa",data)
    if (pathname.includes("edit") && !selectedProduct) {
      const formData = new FormData();

      console.log("dataaa", data);
      formData.append("name", data?.name);
      formData.append("description", data?.description);
      formData.append("price", data?.price);
      formData.append("duration", data?.duration);
      formData.append("type", data?.type?.value);
      formData.append("service", location?.state?._id);
      for (let i = 0; i < data?.images?.length; i++) {
        formData.append("images", data.images[i]);
      }
      await dispatch(addProduct(formData)).then((res) => {
        toast.success("Product was add Success...!");
        reset({
          name: "",
          description: "",
          type: { label: "", value: "" },
          duration: "08:00:00",
          price: "",
          // images:null,
        });


        //   navigate("/services-manager/department");
      });
      
    } else if (pathname.includes("edit") && selectedProduct) {
      const formData = new FormData();

      console.log("dataaa", data);
      formData.append("name", data?.name);
      formData.append("description", data?.description);
      formData.append("price", data?.price);
      formData.append("duration", data?.duration);
      formData.append("type", data?.type?.value);
      formData.append("service", location?.state?._id);
      for (let i = 0; i < data?.images?.length; i++) {
        formData.append("images", data.images[i]);
      }
      await dispatch(
        updateProductAsync({
          productId: selectedProduct?._id,
          productData: formData,
        })
      ).then((res) => {
        toast.success("Product was updated Success...!");
        // console.log("llllllllllllll", location?.state);
        dispatch(fetchProducts(location?.state?._id));
        //   navigate("/services-manager/department");
        reset({
          name: "",
          description: "",
          type: { label: "", value: "" },
          duration: "08:00:00",
          price: "",
        });
        setFinishEditP(true);
      });
    } else {
      const formData = new FormData();

      console.log("dataaa", data);
      formData.append("name", data?.name);
      formData.append("description", data?.description);
      formData.append("price", data?.price);
      formData.append("duration", data?.duration);
      formData.append("type", data?.type?.value);
      formData.append("service", newService?.data?._id);
      for (let i = 0; i < data?.images?.length; i++) {
        formData.append("images", data.images[i]);
      }
      await dispatch(addProduct(formData)).then((res) => {
        toast.success("Product was add Success...!");
        reset({
          name: "",
          description: "",
          type: { label: "", value: "" },
          duration: "08:00:00",
          price: "",

        });


      });
    }
  
  }

  const TYPE = [
    { label: "Appointement vue", value: "clock" },
    { label: "Product vue", value: "product" },
  ];

  // const [typeClock, setTypeClock] = useState(false);
  // useEffect(() => {
  //   if (watch("type") === 'clock') {
  //     setTypeClock(true);
  //   }
  // });

  const handleDeleteImage = () => {
    setHasAnImage(false);
  };

  return (
    <Col xl="12">
      <form className="card">
        <CardBody>
          <Row>
            <Col xl="12">
              <Row>
                <Col md="6">
                  <Label>Product name</Label>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <SimpleInput
                        {...field}
                        errclassname="error-msg-input" // change to lowercase
                        className="form-control"
                        placeholder="Name"
                        errors={errors.name?.message}
                      />
                    )}
                  />
                </Col>
                <Col md="6">
                  <Label>Description</Label>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <SimpleInput
                        {...field}
                        errclassname="error-msg-input" // change to lowercase
                        className="form-control"
                        placeholder="Description"
                        errors={errors.name?.message}
                      />
                    )}
                  />
                </Col>
                </Row>

      
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col xl="12">
              <Row>
                <Col md="6">
                  {/* <Label>Product name</Label> */}
                  <Label>Price</Label>

                   <Controller
                    name="price"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputGroup>
                        <InputGroup>
                          <Input
                            {...field}
                            type="text"
                            className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                            placeholder="Price"
                            aria-label="Amount (to the nearest dollar)"
                          />
                          <InputGroupText>{"TND"}</InputGroupText>
                        </InputGroup>
                          {errors.price && (
                              <span className="error-msg-input">
                                {errors.price.message}
                              </span>
                            
                          )}
                      </InputGroup>
                      
                    )}
                  />
                </Col>
                <Col md="6">
                  <Label>Type</Label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={TYPE?.map((e) => ({
                          label: e?.label,
                          value: e?.value,
                        }))}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col md="6">{/* <Col md="6" className={typeClock ? "d-none" : ""}> */}
                  <Label >Duration</Label>
                  <Controller
                    name="duration"
                    control={control}
                    defaultValue="08:00:00"
                    render={({ field }) => (
                      <Input
                        className="digits"
                        type="time"
                        {...field}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
          <Col xl="3" className="position-relative">
                {hasAnImage ? (
                  <div className="position-relative">
                    <img
                      src={process.env.REACT_APP_MINIO_URL + selectedProduct.images[0]}
                      alt="Preview"
                      className="img-fluid"
                    />
                    <FaTrash className="position-absolute top-0 start-0 text-danger" style={{ cursor: 'pointer' }} onClick={handleDeleteImage} />
                  </div>
                ) : (
                  <Controller
                    name="images"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <UploadDropzone
                        getUploadParams={getUploadParams}
                        handleChangeStatus={handleSubmitFile}
                        {...field}
                      />
                    )}
                  />
                )}
              </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            onClick={handleSubmit(onSubmit)}
            color="secondary"
            disabled={
              pathname?.includes("edit") || newService?.data?._id ? false : true
            }
          >
            { 
              (pathname.includes("edit") || pathname.includes("new")) && finishEditP 
              ? "Add"
              // ? `Add ${finishEditP}`
              :
               (pathname.includes("edit") || pathname.includes("new") ) && selectedProduct
              ? "Edit"
              : (pathname.includes("edit") || pathname.includes("new") )  && !selectedProduct 
              ? "Add"
             
              : !pathname.includes("edit") && "Add"}
          </Button>
        </CardFooter>
      </form>
    </Col>
  );
};

export default Products;
