import { Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "sweetalert2";
import TableComp from "../../../Components/dataTable/TableComp";
import { useEffect } from "react";
import { Edit, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import { deletePackOptionAsync, getAllPackOptionsAsync } from "../../../redux-toolkit/slices/packOptionSlice";

const PacksOptionsPageContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const packOptionsData = useSelector((state)=> state.packOption.packsOptions);
  useEffect(() => {
      dispatch(getAllPackOptionsAsync({page:1,limit:10}));
    }
  ,[dispatch]);

  const handelNavigateEditPackOption = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/subscription-manager/packOptions/edit/${rowName}`, { state: row });
    props.setIsOpen(true);
  };

  const handelDeletePackOption = async (row) => {
    const packOptionId = [row?._id];
    if (!packOptionId) {
      return;
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this pack Option?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          const deletedPackOption = await dispatch(deletePackOptionAsync(packOptionId));
          if (deletePackOptionAsync.fulfilled.match(deletedPackOption)) {
            SweetAlert.fire("Deleted",deletedPackOption.payload.data.message,"success");
            dispatch(getAllPackOptionsAsync({page:1,limit:10}));
          } else {
            SweetAlert.fire("Error!",`${deletedPackOption.payload}`,"error");
          }
        } catch (error) {
          SweetAlert.fire("Error!", "Pack Option could not be deleted.", "error");
        }
      }
    });
  };

  const PackOptionsTableColumns = [
    {
      name: "Pack",
      selector: (row) => (
        <div className="text-center">
          <div>{row?.pack?.name}</div>
          <div style={{ fontSize: '10px'}}>({row?.pack?.type})</div>
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Option Template",
      selector: (row) =>  (
        <div className="text-center">
          <div>{row?.optionTemplate?.customName}</div>
          <div style={{ fontSize: '10px'}}>({row?.optionTemplate?.type})</div>
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Price",
      selector: (row) => row?.priceOptionTemplate,
      sortable: true,
      center: true,
    },
   
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            style={{ cursor: "pointer" }}
          >
            <Trash
              width={20}
              color="red"
              onClick={() => handelDeletePackOption(row)}
            />
          </a>
          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditPackOption(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
      <Container fluid={true}>
        <Row>
          <TableComp
            data={packOptionsData}
            columns={PackOptionsTableColumns}
            toggleDelete={false}
            expandableRows={false}
            highlightOnHover={true}
            fixedHeader={true}
            customStyles={customStyles}
          />
        </Row>
      </Container>
  );
};

export default PacksOptionsPageContainer;