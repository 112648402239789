import { AddNewAffectationAdmin } from "../../../Constant";
import { Row, Col, Button, Form, Label } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import AdminPageContainer from "./AffectationAdminPageContainer";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getAllEstablishmentsAsync } from "../../../redux-toolkit/slices/establishmentSlice";
import { getAllRolesAsync } from "../../../redux-toolkit/slices/roleSlice";
import { getAllAffectationAdminAsync } from "../../../redux-toolkit/slices/affectationAdminSlice";
import { toast } from "react-toastify";
import { getAllPrestationsAsync } from "../../../redux-toolkit/slices/prestationSlice";
import AffectationsAdminsForm from "./AffectationsAdminsForm";
const AffectationAdminPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9,
    }),
  };
  const [isOpen, setIsOpen] = useState(false);
  const handelOpenForm = () => {
    setIsOpen(true);
  };
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      role: null,
      establishment: null,
    },
  });

  const establishmentsData = useSelector(
    (state) => state.establishment.establishments
  );
  const prestationsData = useSelector((state) => state.prestation.prestations);
  const rolesData = useSelector((state) => state.role.roles);

  const establishmentsRequested = useRef(false);
  const prestationsRequested = useRef(false);
  const rolesRequested = useRef(false);

  useEffect(() => {
    if (!establishmentsRequested.current && establishmentsData.length === 0) {
      dispatch(getAllEstablishmentsAsync());
      establishmentsRequested.current = true;
    }
  }, [dispatch, establishmentsData.length]);

  useEffect(() => {
    if (!prestationsRequested.current && prestationsData.length === 0) {
      dispatch(getAllPrestationsAsync());
      prestationsRequested.current = true;
    }
  }, [dispatch, prestationsData.length]);
  // Concaténer les données des établissements et des prestations
  const combinedData = [...establishmentsData, ...prestationsData];

  useEffect(() => {
    if (!rolesRequested.current && rolesData.length === 0) {
      dispatch(getAllRolesAsync());
      rolesRequested.current = true;
    }
  }, [dispatch, rolesData.length]);

  // Process rolesData to get unique role names
  const uniqueRoleNames = [...new Set(rolesData.map((role) => role.name))];

  const onSubmit = async (data) => {
    const establishmentId = data?.establishment?.value;
    const roleName = data?.role?.value;
    try {
      const filteredAffectations = await dispatch(
        getAllAffectationAdminAsync({
          establishmentId,
          roleName,
          page: 1,
          limit: 10,
        })
      );
      if (getAllAffectationAdminAsync.rejected.match(filteredAffectations))
        toast.error("Error", filteredAffectations?.payload);
      else toast.success(filteredAffectations?.payload);
    } catch (error) {
      toast.error("Fatal error getting affectationsAdmins !");
    }
  };
  const handleReset = () => {
    reset();
    dispatch(getAllAffectationAdminAsync({ page: 1, limit: 10 }));
  };

  return (
    <>
      {isOpen && (
        <AffectationsAdminsForm isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
      <div className="page-body">
        <Breadcrumbs />
        <Row
          className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
          style={{ marginTop: "-48px", marginBottom: "20px" }}
        >
          <Col xl="6" className="d-flex align-items-center">
            <Form
              className="w-100 d-flex justify-content-between align-items-end my-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col xl="4" md="4" sm="12">
                <Label>Establishment</Label>
                <Controller
                  name="establishment"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={combinedData?.map((e) => ({
                        label: e.name,
                        value: e._id,
                      }))}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />
              </Col>
              <Col xl="4" md="4" sm="12">
                <Label>Role name </Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={uniqueRoleNames?.map((e) => ({
                        label: e,
                        value: e,
                      }))}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />
              </Col>
              <Col xl="auto" md="auto" sm="12">
                <Button variant="outline-success" type="submit">
                  Search
                </Button>
              </Col>

              <Col xl="auto" md="auto" sm="12">
                <Button color="warning" onClick={() => handleReset()}>
                  Reset
                </Button>
              </Col>
            </Form>
          </Col>
          <Col
            xl="3"
            sm="12"
            className="d-flex align-items-end justify-content-end"
          >
            <Btn color="btn btn-primary mb-2" onClick={() => handelOpenForm()}>
              {t(AddNewAffectationAdmin)}
            </Btn>
          </Col>
        </Row>
        <AdminPageContainer isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};
export default AffectationAdminPage;
