import React, { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Input } from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import UploadDropzone from "../../../../Components/Dropzone/UploadDropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchDepartment } from "../../../../redux-toolkit/slices/departementSlice";
import { getAllCategoryTreeAsync } from "../../../../redux-toolkit/slices/categorySlice";
import SweetAlert from "sweetalert2";
import {
  addService,
  fetchServices,
  updateTheService,
} from "../../../../redux-toolkit/slices/serviceSlice";
import Products from "./products";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ProductList from "./productList";
import { FaTrash } from "react-icons/fa";

const ServiceForm = () => {
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(new Date());
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const categoryData = useSelector((state) => state.category.category);
  const departementData = useSelector((state) => state.departement.departement);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hasAnImage, setHasAnImage] = useState(false);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState({});
  let location = useLocation();
  const pathname = location.pathname.split("/");
  console.log(
    "🚀 ~ file: index.jsx:18 ~ ServiceForm ~ categoryData:",
    categoryData
  );
  const getOptionsFromTree = (tree, options = []) => {
    tree.forEach(category => {
      options.push({ label: category.name, value: category._id });
      if (category.children && category.children.length > 0) {
        getOptionsFromTree(category.children, options);
      }
    });
    return options;
  };
  const [options,setOptions]=useState([])
  useEffect(()=>{
    categoryData && setOptions( getOptionsFromTree(categoryData))
  },[categoryData])
 
  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelected?.establishments._id) {
        await dispatch(fetchDepartment(affectationSelected?.establishments._id));
      }
    };
    fetchData();
  }, [dispatch,affectationSelected]);
  
  useEffect(() => {
    categoryData &&
      dispatch(
        getAllCategoryTreeAsync({
          establishment: affectationSelected?.establishments._id,
        })
      );
  }, [dispatch]);


  const schema = yup.object().shape({
    name: yup.string().required("You must enter the name"),
    description: yup.string().required("You must enter the description"),
  });
  const getUploadParams = (body) => {
    return { url: "https://httpbin.org/post" };
  };
  const handleSubmitFile = ({ meta, file, remove }, status) => {
    setValue("images", [file]);
    if (status === "headers_received") {
      toast.success(`${meta.name} uploaded!`);
    } else if (status === "aborted") {
      toast(`${meta.name}, upload failed...`);
    } else if (status === "removed") {
      resetField("images");
    }
  };
  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { isValid, dirtyFields,isSubmitting , errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });


  const [newServiceId, setNewServiceId] = useState(null);

  // useEffect(() => {
  //   if (serviceId) {
  //     setNewServiceId(serviceId);
  //   }
  //     console.log("🚀 ~ useEffect ~ serviceId:", serviceId)
  // }, [serviceId]);

  // Cleanup effect pour réinitialiser newServiceId à null lors du démontage du composant
  useEffect(() => {
    return () => {
      setNewServiceId(null);
    };
  }, []);

// console.log("pathname",pathname)
// console.log("newServiceId *************",newServiceId)

async function onSubmit(data) {
  const formData = new FormData();

  console.log("dataaa", data);
  formData.append("name", data?.name);
  formData.append("description", data?.description);
  formData.append("category", data?.category?.value);
  formData.append("departement", data?.departement?.value);
  formData.append("establishment", affectationSelected?.establishments._id);
  for (let i = 0; i < data?.images?.length; i++) {
    formData.append("images", data.images[i]);
  }
  if (!pathname.includes("edit")) {
      const serviceAdded = await dispatch(addService(formData));
      console.log('aaaaaaaaaaaa: ',serviceAdded)

    if(addService.rejected.match(serviceAdded)){
      console.log("serviceAdded",serviceAdded);
      SweetAlert.fire(
        "Conflict!",
        serviceAdded.payload,
        "error"
      );
    }else{
      setNewServiceId(serviceAdded.payload.data._id);
      SweetAlert.fire(
        "Added!",
        "Service was add successfully!",
        "success"
      );
      await dispatch(fetchServices({ establishmentId: affectationSelected?.establishments._id})
      );
    }

  } else {
    await dispatch(
      updateTheService({
        serviceId: location?.state?._id,
        serviceData: formData,
      })
    ).then((res) => {
      toast.success("Service was updated Success...!");
      //   navigate("/services-manager/department");
    });
  }
  
  // await dispatch(createCategoryAsync(formData)).then((res) => {
  //   console.log(
  //     "🚀 ~ file: index.jsx:101 ~ onSubmit ~ categoryCreated:",
  //     res
  //   );
  // })
}
  useEffect(() => {
    console.log("🚀 ~ file: index.jsx:98 ~ useEffect ~ location:", location);
    if (location?.state && pathname.includes("edit")) {
      reset({
        name: location?.state?.name,
        description: location?.state?.description,
        statut: location?.state?.statut,
        category: {
          label: location?.state?.category?.name,
          value: location?.state?.category?._id,
        },
        departement: {
          label: location?.state?.departement?.name,
          value: location?.state?.departement?._id,
        },
      });
      setSelectedCategory({
        label: location?.state?.category?.name,
        value: location?.state?.category?._id,
      });
      setSelectedDepartment({
        label: location?.state?.departement?.name,
        value: location?.state?.departement?._id,
      });
      if (location?.state?.products?.length > 0) {
        setProducts(location?.state?.products);
      }
      if (Array.isArray(location?.state?.images) && location.state.images.length > 0) {
        setHasAnImage(true);
      }
    }
      }, [location]);
  useEffect(() => {
    const fetchData = async () => {
      if (userAdmin?.establishment) {
        await dispatch(fetchDepartment(userAdmin?.establishment));
        await dispatch(
          getAllCategoryTreeAsync({ establishment: userAdmin?.establishment })
        );
      }
    };
    fetchData();
  }, [dispatch, userAdmin?.establishment]);


  
  const handleDeleteImage = () => {
    setHasAnImage(false);
  };  


  return (
    <div className="page-body">
      <Breadcrumbs />
      <Col xl="12">
        <form className="card">
          <CardBody>
            <Row>
            <Col xl="3" className="position-relative">
                {hasAnImage ? (
                  <div className="position-relative">
                    <img
                      src={process.env.REACT_APP_MINIO_URL + location?.state?.images[0]}
                      alt="Preview"
                      className="img-fluid"
                    />
                    <FaTrash className="position-absolute top-0 start-0 text-danger" style={{ cursor: 'pointer' }} onClick={handleDeleteImage} />
                  </div>
                ) : (
                  <Controller
                    name="images"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <UploadDropzone
                        getUploadParams={getUploadParams}
                        handleChangeStatus={handleSubmitFile}
                        {...field}
                      />
                    )}
                  />
                )}
              </Col>
              <Col xl="9">
                <Row>
                  <Col md="6">
                    <Label>Service name</Label>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input" // change to lowercase
                          className="form-control"
                          placeholder="Name"
                          errors={errors.name?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Description</Label>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input" // change to lowercase
                          className="form-control"
                          placeholder="Description"
                          errors={errors.description?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                    <Label>Category</Label>
                    <Controller
                      name="category"
                      control={control}
                      // defaultValue={
                      //   pathname.includes("edit") ? selectedCategory : null
                      // }
                      render={({ field }) => (
                        <Select
                          // defaultValue={
                          //   pathname.includes("edit") ? selectedCategory : null
                          // }
                          options={options}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Departement</Label>
                    <Controller
                      name="departement"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={departementData?.map((e) => ({
                            label: e?.name,
                            value: e?._id,
                          }))}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            <Button onClick={handleSubmit(onSubmit)} color="secondary"
            disabled={pathname?.includes("new") && newServiceId}
              >
              Save
            </Button>
          </CardFooter>
        </form>
      </Col>
      <>
      {(newServiceId || (location.state && location.state._id)) && (
        <div>
          <h4 style={{ paddingBottom: "10px" }}>Add new products</h4>
          <div  id="productEdit" >
            <Products selectedProduct={selectedProduct} />
          </div>
          <h4 style={{ paddingBottom: "10px" }}>List products</h4>
          <ProductList
            // products={location.state.products}
            setSelectedProduct={setSelectedProduct}
          />
        </div>
      )} 
    </>
      
    </div>
  );
};

export default ServiceForm;
