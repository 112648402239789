import { configureStore } from "@reduxjs/toolkit";
import { BookMarkReducer } from "./reducers/BookMarkReducer";
import { ThemeCustomizer } from "./reducers/ThemeCustomizer";
import { serviceReducer } from "./reducers/serviceReducer";
import { refreshTokenReducer } from "./reducers/refreshTokenReducer";
import { roleReducer } from "./slices/roleSlice";
import { servicesReducer } from "./slices/serviceSlice";
import { usersReducer } from "./slices/userSlice";
import { affectationsReducer } from "./slices/affectationSlice";
import { categoryReducer } from "./slices/categorySlice";
import { departmentReducer } from "./slices/departementSlice";
import { productsReducer } from "./slices/productSlice";
import { permissionReducer } from "./slices/permissionSlice";
import { establishmentReducer } from "./slices/establishmentSlice";
import { authReducer } from "./slices/authSlice";
import { prestationReducer } from "./slices/prestationSlice";
import { adminReducer } from "./slices/adminSlice";
import { affectationsAdminsReducer } from "./slices/affectationAdminSlice";
import {packReducer} from "./slices/packSlice"
import { optionTemplateReducer } from "./slices/OptionTemplateSlice";
import { packOptionReducer } from "./slices/packOptionSlice";
import { subscriptionReducer } from "./slices/subscriptionSlice";
import { subscriptionPackOptionReducer } from "./slices/subscriptionPackOptionSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    admin: adminReducer,
    affectationAdmin: affectationsAdminsReducer,
    affectations: affectationsReducer,
    BookMarkReducer,
    category: categoryReducer,
    departement: departmentReducer,
    establishment: establishmentReducer,
    optionTemplate: optionTemplateReducer,
    products: productsReducer,
    permission: permissionReducer,
    prestation: prestationReducer,
    pack: packReducer,
    packOption:packOptionReducer,
    refreshTokenReducer,
    role: roleReducer,
    services: servicesReducer,
    serviceReducer,
    subscription: subscriptionReducer,
    subscriptionPackOption: subscriptionPackOptionReducer,
    ThemeCustomizer,
    users: usersReducer,
    
    
    
    
    
  },
});
