import axiosInstance from "../../api/axios";

const GET_ALL_ROLE_BY_ESTABLISHMENT_ID = `${process.env.REACT_APP_BACK_URL}/api/v1/role/getAllRolesByEstablishmentId`;
const GET_ALL_ROLES = `${process.env.REACT_APP_BACK_URL}/api/v1/role/`;
const ADD_ROLE = `${process.env.REACT_APP_BACK_URL}/api/v1/role/createRole`;
const UPDATE_ROLE = `${process.env.REACT_APP_BACK_URL}/api/v1/role/updateRole`;

const DELETE_ROLE = `${process.env.REACT_APP_BACK_URL}/api/v1/role/deleteRole`;

export const getAllRolesByEstablishmentId = async (establishmentId, page = null, limit = null) => {
  try {
    const queryParams = new URLSearchParams();
    if (page) queryParams.append('page', page);
    if (limit) queryParams.append('limit', limit);
    queryParams.append('establishmentId', establishmentId);
    
    const url = `${GET_ALL_ROLE_BY_ESTABLISHMENT_ID}?${queryParams.toString()}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllRoles = async () =>{
  try {
    const response = await axiosInstance.get(
      `${GET_ALL_ROLES}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const addRole = async (data) => {
  try {
    const response = await axiosInstance.post(ADD_ROLE, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateRole = async (id, data) => {
  console.log("id", id);
  console.log("data", data);
  try {
    const response = await axiosInstance.put(`${UPDATE_ROLE}/${id}`, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteRole = async (id) => {
  try {
    const response = await axiosInstance.post(`${DELETE_ROLE}/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
