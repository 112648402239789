import TictketStatus from "../../Components/Dashboard/serviceDashboard/TictketStatus";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";

const index = () => {
  return (
    <div className="page-body">
      <Breadcrumbs />
      <Container fluid={true}>
        <Row>
          <TictketStatus />
        </Row>
      </Container>
    </div>
  );
};

export default index;
