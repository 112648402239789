import { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { P } from "../../AbstractElements";
import HeadingCommon from "../../Common/HeadingCommon";
// import EstablishmentSetting from "../../Components/settingsCompoents/EstablishmentSetting";
import Roles from "../../Components/settingsCompoents/roles";
import { useSelector } from "react-redux";

const SettingsPage = () => {
  const span = `'Add <code>.border-tab</code> class with nav class`;
  const [BasicLineTab, setBasicLineTab] = useState("1");
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );

  return (
    <div className="page-body">
      <Col sm="12" xl="12 xl-100" style={{ marginTop: "100px" }}>
        {affectationSelectedData && (
          <Card>
            <CardBody>
              <Nav className="border-tab" tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={BasicLineTab === "1" ? "active" : ""}
                    onClick={() => setBasicLineTab("1")}
                  >
                    <i className="icofont icofont-ui-home"></i>Etablishement
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={BasicLineTab === "2" ? "active" : ""}
                    onClick={() => setBasicLineTab("2")}
                  >
                    <i className="icofont icofont-man-in-glasses"></i>Roles
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={BasicLineTab === "3" ? "active" : ""}
                    onClick={() => setBasicLineTab("3")}
                  >
                    <i className="icofont icofont-contacts"></i>Contact
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={BasicLineTab}>
                <TabPane className="fade show" tabId="1">
                  {/* <EstablishmentSetting/> */}
                </TabPane>
                <TabPane tabId="2">
                  <Roles />
                </TabPane>
                <TabPane tabId="3">
                  <P className="mb-0 m-t-30">
                    {
                      "tab3 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
                    }
                  </P>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        )}
      </Col>
    </div>
  );
};

export default SettingsPage;
