import React from "react";
import Offcanvas from "../../../../CommonElements/Offcanvas";
import EnhanceTree from "../TreeView/EnhanceTree";

const CategoryView = ({ isOpen, toggleClose, data }) => {
  console.log("cccccccccccccccccccc", data);
  return (
    <Offcanvas
      isOpen={isOpen}
      toggleClose={toggleClose}
      titleSideBar={data?.Details}
      style={{ width: "30%" }}
    >
      <h1>{data?.name}</h1>
      <EnhanceTree data={data} />
    </Offcanvas>
  );
};

export default CategoryView;
