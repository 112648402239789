import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createOptionTemplate, deleteOptionTemplate, getAllOptionTemplates, updateOptionTemplate } from "../services/OptionTemplateService";

const initialStateOptionTemplate = {
    optionsTemplates: [],
    loading: false,
    error: null,
  };

  export const getAllOptionTemplatesAsync = createAsyncThunk(
    "OptionTemplate/fetchData",
    async (params={},{ rejectWithValue }) => {
        const {page=null, limit=null} = params;
      try {
        const response = await getAllOptionTemplates({page, limit});
        return response.data;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );
  export const createOptionTemplateAsync = createAsyncThunk(
    "OptionTemplate/create",
    async (data,{rejectWithValue}) => {
        try {
            const response = await createOptionTemplate(data);
            return response;
        } catch (error) {
            if (error.response && error.response.data) {
              return rejectWithValue(error.response.data.message);
            } else {
              return rejectWithValue(error.message);
            }
        }
    }
  )

  export const deleteOptionTemplateAsync = createAsyncThunk(
    "OptionTemplate/delete",
    async(optionTemplateId,{rejectWithValue}) => {
      try {
        const response = await deleteOptionTemplate(optionTemplateId);
        return response ;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );

  export const updateOptionTemplateAsync = createAsyncThunk(
    "OptionTemplate/update",
    async ({optionTemplateId,data},{rejectWithValue}) => {
        try {
            const response = await updateOptionTemplate({optionTemplateId,data});
            return response;
        } catch (error) {
          if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
          } else {
            return rejectWithValue(error.message);
          }
        }
    }
  )
  
  
const optionTemplateSlice = createSlice({
    name: "optionTemplate",
    initialState: initialStateOptionTemplate,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllOptionTemplatesAsync.fulfilled, (state, action) => {
          state.optionsTemplates = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(getAllOptionTemplatesAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            
        })
        .addCase(getAllOptionTemplatesAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(createOptionTemplateAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
        .addCase(createOptionTemplateAsync.fulfilled, (state, action) => {
          if (action.payload.data.data) {
            state.optionsTemplates.push(action.payload.data.data);
          }
          state.loading = false;
          state.error = null;
        })
        .addCase(createOptionTemplateAsync.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error;
        })
        .addCase(updateOptionTemplateAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(updateOptionTemplateAsync.fulfilled, (state, action) => {
            const updatedOptionTemplate = action.payload.data.data;
            const index = state.optionsTemplates.findIndex(optionTemplate => optionTemplate._id === updatedOptionTemplate._id);
            if (index !== -1) {
              state.optionsTemplates[index] = updatedOptionTemplate;
            }
            state.loading = false;
            state.error = null;
          })
          .addCase(updateOptionTemplateAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
          })
          .addCase(deleteOptionTemplateAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(deleteOptionTemplateAsync.fulfilled, (state, action) => {
            const deletedOptionTemplateId = action.payload.data;
            state.optionsTemplates = state.optionsTemplates.filter(
              (optionTemplate) => optionTemplate._id !== deletedOptionTemplateId
            );
            state.loading = false;
            state.error = null;
          })
          .addCase(deleteOptionTemplateAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
          });
    
    }

})
export const optionTemplateReducer = optionTemplateSlice.reducer;
const {actions} = optionTemplateSlice;