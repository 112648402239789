import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

// Set Leaflet's default marker icon URLs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const LocationMarker = ({ position, setPosition }) => {
  useMapEvents({
    click(e) {
      setPosition([e.latlng.lat, e.latlng.lng]);
    },
  });

  const customIcon = L.icon({
    iconUrl: "https://icon-library.com/images/position-icon/position-icon-8.jpg",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return position === null ? null : (
    <Marker position={position} icon={customIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

const MapComponent = ({ initialPosition, zoom ,style,onPositionChange}) => {
  const [position, setPosition] = useState(initialPosition);

  // useEffect(() => {
  //   if (initialPosition) {
  //     setPosition(initialPosition);
  //   }
  // }, [initialPosition]);

  useEffect(() => {
    if (onPositionChange) {
      onPositionChange(position);
    }
//   }, [position, onPositionChange]);
}, [position]);
  
  return (
    <MapContainer
      center={position}
      zoom={zoom}
      style={style}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker position={position} setPosition={setPosition} />
    </MapContainer>
  );
};

export default MapComponent;
