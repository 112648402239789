import { useEffect } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

function SubscriptionsView(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const pathname = location.pathname.split("/");
  const startDate = location?.state?.createdAt
    ? new Date(location.state.createdAt).toISOString().split("T")[0]
    : location?.state?.createdAt;
  const endDate = location?.state?.dateEnd
    ? new Date(location.state.dateEnd).toISOString().split("T")[0]
    : location?.state?.dateEnd;

  const { control, reset } = useForm();

  useEffect(() => {
    const packOptions = location?.state?.subscriptionPackOptions?.map(
      (option) => ({
        optionTemplateName: option.packOption?.optionTemplate?.customName,
        pricePackOption: option.pricePackOption,
      })
    );
    if (location?.state && pathname.includes("view")) {
      reset({
        username: location?.state?.admin?.username,
        email: location?.state?.admin?.email,
        name: location?.state?.pack?.name,
        description: location?.state?.pack?.description,
        price: location?.state?.pack?.price,
        type: location?.state?.pack?.type,
        pricePack: location?.state?.pricePack,
        total: location?.state?.total,
        startDate: startDate,
        endDate: endDate,
        packOptions: packOptions,
      });
    }
  }, [location]);

  return (
    <Modal isOpen={props.isOpen} size="lg" centered>
      <ModalHeader>View Subscription Details</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col xl="12">
                <h5 style={{ color: "#655af3" }}>Admin</h5>
                <Row className="mb-3">
                  <Col lg="6">
                    <Label>Username</Label>
                    <Controller
                      name="username"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                  <Col lg="6">
                    <Label>Email</Label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                </Row>
                <h5 style={{ color: "#655af3" }} className="mt-3">Pack</h5>
                <Row className="mb-3">
                  <Col lg="6">
                    <Label>Name</Label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                  <Col lg="6">
                    <Label>Description</Label>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg="6">
                    <Label>Price</Label>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                  <Col lg="6">
                    <Label>Type</Label>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                </Row>
                <h5 style={{ color: "#655af3" }} className="mt-3">Other Details</h5>
                <Row className="mb-3">
                  <Col lg="6">
                    <Label>Price Pack</Label>
                    <Controller
                      name="pricePack"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                  <Col lg="6">
                    <Label>Total Price</Label>
                    <Controller
                      name="total"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg="6">
                    <Label>Start Date</Label>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                  <Col lg="6">
                    <Label>End Date</Label>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </Col>
                </Row>
                <h5 style={{ color: "#655af3" }} className="mt-3">List of Subscription Pack Options</h5>
                {location?.state?.subscriptionPackOptions.map((option, index) => (
                  <Row className="mb-3" key={index}>
                    <Col lg="6">
                      <Label>Pack Option Name</Label>
                      <Controller
                        name={`packOptions[${index}].optionTemplateName`}
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="text"
                            {...field}
                            disabled
                          />
                        )}
                      />
                    </Col>
                    <Col lg="3">
                      <Label>Pack Option Price</Label>
                      <Controller
                        name={`packOptions[${index}].pricePackOption`}
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="text"
                            {...field}
                            disabled
                          />
                        )}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          reset();
          props.setIsOpen(false);
          navigate(`/subscription-manager/subscriptions`);
        }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default SubscriptionsView;
