import React, { useState, useEffect } from "react";
import TableComp from "../../../../Components/dataTable/TableComp";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, deleteProductAsync, fetchProducts } from "../../../../redux-toolkit/slices/productSlice";
import { Btn, H6, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Edit, Trash } from "react-feather";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const ProductList = ({ products, setSelectedProduct }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const [dataProducts, setDataProducts] = useState([]);

  const pathname = location.pathname.split("/");
  //const [selectedProduct, setSelectedProduct] = useState({});
  const productsData = useSelector((state) => state.products.products);
  console.log(
    "🚀 ~ file: productList.jsx:13 ~ ProductList ~ productsData:11",
    productsData
  );

  // console.log(
  //   "🚀 ~ file: selectedProduct.jsx:13 ~ ProductList ~ selectedProduct:",
  //   selectedProduct
  // );
 
  const newService = useSelector((state) => state.services.newService);
  const handelSelectProduct = (row) => {
    setSelectedProduct(row);
  };

  const handelDeleteProduct = async (row) => {
    console.log("deletedProduct=",row);
      try {
        const response = await dispatch(
          deleteProductAsync(row)
        );
        if (response?.message) {
          toast.error(response.message);
        } else {
          if(pathname.includes('edit')){
           dispatch( fetchProducts(location?.state?._id))
          }else{
            dispatch( fetchProducts(newService?.data?._id))
          }
          toast.success("Product deleted successfully!");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the Product.");
      }
    
  };
  const ProductsTableColumns = [
    {
      name: "Image",
      selector: (row) => (
        <Image
        src={row.images[0] ? process.env.REACT_APP_MINIO_URL + row.images[0] : dynamicImage("ecommerce/options.png")}
        style={{width:"70px",padding:"10px"}}
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row["description"],
      sortable: true,
      center: true,
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      sortable: true,
      center: true,
    },
    {
      name: "Duration",
      selector: (row) => row?.duration,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Trash width={20} color='red'onClick={()=>handelDeleteProduct(row?._id)}/>
          </a>
          <a
            // href="#productEdit"
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit onClick={() => handelSelectProduct(row)} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };
console.log("pathname=====>",pathname);
  useEffect(() => {
    const fetchData = async () => {
      if (newService?.data?._id && !pathname.includes("edit")) {
        console.log('newService?.data',newService);
        await dispatch(fetchProducts(newService?.data?._id));
      } else if (pathname.includes("edit") && location?.state?._id) {
        console.log('newService?.data1111',location);

        await dispatch(fetchProducts(location?.state?._id));
      }
    };
    fetchData();
  }, [dispatch, newService?.data?._id|| location?.state?._id]);
  console.log("newService", newService);
  console.log("location", location);
  return (
    <div>
      <TableComp
        data={ productsData}
        columns={ProductsTableColumns}
        // expandableRowsComponent={ExpandedComponent}
        // handleRowSelected={handleRowSelected}
        toggleDelete={false}
        expandableRows={false}
        highlightOnHover={true}
        fixedHeader={true}
        customStyles={customStyles}
        // onRowClicked={(row) => handelNavigateToSubCategory(row)}
      />
    </div>
  );
};

export default ProductList;
