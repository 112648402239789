import axiosInstance from "../../api/axios";

const CREATE_CATEGORY = `${process.env.REACT_APP_BACK_URL}/api/v1/category/addCategory`;
const GET_ALL_CATEGORY_TREE = `${process.env.REACT_APP_BACK_URL}/api/v1/category/getcategory/categoryandservice`;
const GET_ALL_PARENT_CATEGORY = `${process.env.REACT_APP_BACK_URL}/api/v1/category`;
const UPDATE_CATEGORY = (idCategory) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/category/update/${idCategory}`;
const DELETE_CATEGORY = (idCategory) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/category/delete/${idCategory}`;

export const createCategory = async (categoryData) => {
  try {
    const response = await axiosInstance.post(CREATE_CATEGORY, categoryData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateCategory = async (idCategory, categoryData) => {
  try {
    const response = await axiosInstance.post(
      UPDATE_CATEGORY(idCategory),
      categoryData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (idCategory) => {
  try {
    const response = await axiosInstance.delete(DELETE_CATEGORY(idCategory));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const gelAllCategoryTree = async (establishmentId) => {
  try {
    const response = await axiosInstance.post(
      GET_ALL_CATEGORY_TREE,
      establishmentId
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const gelAllParentCategory = async (establishmentId) => {
  try {
    const response = await axiosInstance.post(
      GET_ALL_PARENT_CATEGORY,
      establishmentId
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSubCategory = async (categoryId, establishmentId) => { // Accept both categoryId and establishmentId
  console.log("categoryId",categoryId);
    console.log("establishmentId",establishmentId);try {
    const response = await axiosInstance.post(
      `${GET_ALL_PARENT_CATEGORY}/${categoryId}`, // Include categoryId in the URL
       {establishment:establishmentId}  // Include establishmentId in the request body
    );
    
    return response.data;
  } catch (error) {
    throw error;
  }
};
