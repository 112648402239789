import axiosInstance from "../../api/axios";

const GET_ALL_ADMINS = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/`;
const CREATE_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/createAdmin`
const UPDATE_ADMIN =`${process.env.REACT_APP_BACK_URL}/api/v1/admin/updateAdmin`;
const UPDATE_ADMIN_PASSWORD =`${process.env.REACT_APP_BACK_URL}/api/v1/admin//updateAdminPassword`;
const DELETE_ADMIN =  `${process.env.REACT_APP_BACK_URL}/api/v1/admin/deleteAdmin`;


export const getAllAdmins = async ({ username="", phone="", page= null, limit= null }) => {
  try {
    const queryParams = new URLSearchParams();
    if (username) queryParams.append('username', username);
    if (phone) queryParams.append('phone', phone);
    if (page) queryParams.append('page', page);
    if (limit) queryParams.append('limit', limit);

    const queryString = queryParams.toString();
    const url = queryString ? `${GET_ALL_ADMINS}?${queryString}` : GET_ALL_ADMINS;

    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}

  export const createAdmin = async (adminData) => {
    try {
      const response = await axiosInstance.post(CREATE_ADMIN,adminData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const deleteAdmin = async (adminIds) => {
    try{
      const response = await axiosInstance.post(DELETE_ADMIN,{adminIds});
      return response;
    } catch (error) {
      throw error;
    }
}

export const updateAdmin = async ({adminId, adminData}) => {
  if (adminData instanceof FormData) {
    console.log("🚀 ~ updateAdmin ~ adminData:", adminData)
    for (let pair of adminData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  } else {
    console.log("🚀 ~ updateAdminData ~ adminData:", adminData);
  }
  try {
    const response = await axiosInstance.put(
      `${UPDATE_ADMIN}/${adminId}`,
      adminData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAdminPassword = async ({adminId,updatedAdminPasswordData}) => {
try {
  const response = await axiosInstance.put(
    `${UPDATE_ADMIN_PASSWORD}/${adminId}`,
    updatedAdminPasswordData
  );
  return response;
} catch (error) {
  throw error;
}
}
