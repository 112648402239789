import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPackOption,
  deletePackOption,
  getAllPackOptions,
  getPackOptionsByPackId,
  updatePackOption,
} from "../services/packOptionService";

const initialStatePackOption = {
  packsOptions: [],
  loading: false,
  error: null,
};

export const getAllPackOptionsAsync = createAsyncThunk(
  "PackOption/fetchData",
  async (params = {}, { rejectWithValue }) => {
    const { page = null, limit = null } = params;
    try {
      const response = await getAllPackOptions({ page, limit });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPackOptionsByPackIdAsync = createAsyncThunk(
  "PackOption/fetchDataByPAck",
  async ({packId}, { rejectWithValue }) => {
    try {
      const response = await getPackOptionsByPackId(packId);
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createPackOptionAsync = createAsyncThunk(
  "PackOption/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createPackOption(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deletePackOptionAsync = createAsyncThunk(
  "PackOption/delete",
  async (packOptionId, { rejectWithValue }) => {
    try {
      const response = await deletePackOption(packOptionId);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePackOptionAsync = createAsyncThunk(
  "PackOption/update",
  async ({ packOptionId, data }, { rejectWithValue }) => {
    try {
      const response = await updatePackOption({ packOptionId, data });
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const packOptionSlice = createSlice({
  name: "packOption",
  initialState: initialStatePackOption,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPackOptionsAsync.fulfilled, (state, action) => {
        state.packsOptions = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllPackOptionsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getAllPackOptionsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPackOptionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPackOptionAsync.fulfilled, (state, action) => {
        if (action.payload.data.data) {
          state.packsOptions.push(action.payload.data.data);
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(createPackOptionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(updatePackOptionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePackOptionAsync.fulfilled, (state, action) => {
        const updatedPackOption = action.payload.data.data;
        const index = state.packsOptions.findIndex(
          (packOption) => packOption._id === updatedPackOption._id
        );
        if (index !== -1) {
          state.packsOptions[index] = updatedPackOption;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updatePackOptionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(deletePackOptionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePackOptionAsync.fulfilled, (state, action) => {
        const deletedPackOptionId = action.payload.data;
        state.packsOptions = state.packsOptions.filter(
          (packOption) => packOption._id !== deletedPackOptionId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deletePackOptionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
export const packOptionReducer = packOptionSlice.reducer;
const { actions } = packOptionSlice;
