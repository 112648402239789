import HomePage from "../page/HomePage";
import ServicePage from "../page/ServiceManagerPage/ServicePage";
import CategoryPage from "../page/ServiceManagerPage/CategoryPage";
import CategoryForm from "../page/ServiceManagerPage/CategoryPage/CategoryForm";
import DepartmentPage from "../page/ServiceManagerPage/DepartmentPage";
import ServiceManagerPage from "../page/ServiceManagerPage/";
import SingleCategory from "../page/ServiceManagerPage/CategoryPage/category[id]";
import DepartementForm from "../page/ServiceManagerPage/DepartmentPage/DepartementForm";
import ServiceForm from "../page/ServiceManagerPage/ServicePage/serviceForm";
import SettingsPage from "../page/settingsPage";
import Profile from "../page/ProfilePage/Profile";
import AffectationManagerPage from "../page/AffectationManagerPage"
import EstablishmentsPage from "../page/AffectationManagerPage/EstablishmentsPage"
import PrestationsPage from "../page/AffectationManagerPage/PrestationsPage"
import AdminsPage from "../page/AffectationManagerPage/AdminsPage"
import AffectationsAdminsPage from "../page/AffectationManagerPage/AffectationsAdminsPage"
import EstablishmentsForm from "../page/AffectationManagerPage/EstablishmentsPage/EstablishmentsForm";
import PrestationsForm from "../page/AffectationManagerPage/PrestationsPage/PrestationsForm";
import PacksPage from "../page/SubscriptionManagerPage/PacksPage"
import OptionTemplatePage from "../page/SubscriptionManagerPage/OptionTemplatePage"
import SubscriptionManagerPage from "../page/SubscriptionManagerPage"
import PackOptionPage from "../page/SubscriptionManagerPage/PackOptionPage";
import SubscriptionPage from "../page/SubscriptionManagerPage/SubscriptionPage";
import AddSubscriptionForm from "../page/SubscriptionManagerPage/SubscriptionPage/AddSubscriptionForm"; 
import EditSubscriptionForm from "../page/SubscriptionManagerPage/SubscriptionPage/EditSubscriptionForm"
const routes = [
  { path: "/dashboard", Component: <HomePage /> },
  { path: "/services-manager", Component: <ServiceManagerPage /> },
  { path: "/services-manager/services", Component: <ServicePage /> },
  { path: "/services-manager/services/new", Component: <ServiceForm /> },
  { path: "/services-manager/services/edit/:id", Component: <ServiceForm /> },
  { path: "/services-manager/category", Component: <CategoryPage /> },
  { path: "/services-manager/category/:categoryId", Component: <SingleCategory /> },
  { path: "/services-manager/category/:categoryId/subCategory/new", Component: <CategoryForm/> },
  { path: "/services-manager/category/:categoryId/subCategory/edit/:subCategoryId", Component: <CategoryForm/> },
  { path: "/services-manager/category/new", Component: <CategoryForm /> },
  // { path: "/services-manager/category/new/:id", Component: <CategoryForm /> },
  { path: "/services-manager/category/edit/:categoryId", Component: <CategoryForm /> },
  { path: "/services-manager/department", Component: <DepartmentPage /> },
  { path: "/services-manager/department/new", Component: <DepartementForm /> },
  { path: "/services-manager/department/:departmentId/edit", Component: <DepartementForm /> },
  { path: "/settings-page", Component: <SettingsPage /> },
  { path: "/user/user-profile", Component: <Profile /> },
  // { path: "/swiper", Component: <Swipper /> },

  { path: "/affectation-manager", Component: <AffectationManagerPage/> },
  { path: "/affectation-manager/establishments", Component: <EstablishmentsPage/> },
  { path: "/affectation-manager/establishments/new", Component: <EstablishmentsForm/> },
  { path: "/affectation-manager/establishments/edit/:id", Component: <EstablishmentsForm/> },
  { path: "/affectation-manager/prestations", Component: <PrestationsPage/> },
  { path: "/affectation-manager/prestations/new", Component: <PrestationsForm/> },
  { path: "/affectation-manager/prestations/edit/:id", Component: <PrestationsForm/> },
  { path: "/affectation-manager/admins", Component: <AdminsPage/> },
  { path: "/affectation-manager/admins/edit/:id", Component: <AdminsPage/> },
  { path: "/affectation-manager/admins/editPassword/:id", Component: <AdminsPage/> },
  { path: "/affectation-manager/affectationsAdmins", Component: <AffectationsAdminsPage/> },
  { path: "/affectation-manager/affectationsAdmins/edit/:id", Component: <AffectationsAdminsPage/> }, 
  { path: "/subscription-manager",Component:<SubscriptionManagerPage/>},
  { path: "/subscription-manager/packs", Component: <PacksPage/> },
  { path: "/subscription-manager/packs/edit/:id", Component: <PacksPage/> },
  { path: "/subscription-manager/optionTemplates", Component: <OptionTemplatePage/> },
  { path: "/subscription-manager/optionTemplates/edit/:id", Component: <OptionTemplatePage/> },
  { path: "/subscription-manager/packOptions", Component: <PackOptionPage/> },
  { path: "/subscription-manager/packOptions/edit/:id", Component: <PackOptionPage/> },
  { path: "/subscription-manager/subscriptions", Component: <SubscriptionPage/> },
  { path: "/subscription-manager/subscriptions/new", Component: <AddSubscriptionForm/> },
  { path: "/subscription-manager/subscriptions/edit/:id", Component: <EditSubscriptionForm/> },
  { path: "/subscription-manager/subscriptions/view/:id", Component: <SubscriptionPage/> },
];
export default routes;
