import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getUserByUniqueId,
  updateUser,
  getUsersFromAffectationByEstablishmentId,
  updateRoleUser,
  CreateUser,
  passportOcrTun,
} from "../services/userService";

const initialStateUsers = {
  users: [],
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk("users/fetchData", async () => {
  try {
    const response = await getAllUsers();
    return response.data;
   
  } catch (error) {
    throw error;
  }
});

export const getUsersFromAffectation = createAsyncThunk(
  "users/fetchDataAff",
  async (establishmentId) => {
    try {
      const response = await getUsersFromAffectationByEstablishmentId(
        establishmentId
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserByUniqueIdAsync = createAsyncThunk(
  "users/fetchDataById",
  async (uniqueId) => {
    try {
      const response = await getUserByUniqueId(uniqueId);
      console.log("response===========", response);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "users/update",
  async ({ userId, email, status, firstName, lastName, role }) => {
    try {
      const response = await updateUser(
        userId,
        email,
        status,
        firstName,
        lastName,
        role
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateRoleUserAsync = createAsyncThunk(
  "users/updateRole",
  async ({ userId, role }) => {
    try {
      const response = await updateRoleUser(userId, role);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const passportOcrTunAsync = createAsyncThunk(
  "users/passportOcrTun",
  async (file) => {
    try {
      const response = await passportOcrTun(file);
      return response; // Return the entire response object
    } catch (error) {
      throw error;
    }
  }
);
export const createUserAsync = createAsyncThunk(
  "users/create",
  async ({
    email,
    password,
    firstName,
    lastName,
    phone,
    username,
    role,
    uniqueId,
  // },{ rejectWithValue }) => { // if we want to return theerr from the backend
  }) => {
    try {
      const response = await CreateUser(
        email,
        password,
        firstName,
        lastName,
        phone,
        username,
        role,
        uniqueId
      );
      return response.data;
    } catch (error) {
      throw error;
      // if (error.response && error.response.data) if we want to return theerr from the backend
      //   return rejectWithValue(error.response.data.message);
      // } else {
      //   return rejectWithValue(error.message);
      // }
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: initialStateUsers,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export const usersReducer = usersSlice.reducer;
const { actions } = { usersSlice};
