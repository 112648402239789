import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  const accessToken = localStorage.getItem("accesToken");

  // Check if admin object and access token are present
  if (!accessToken) {
    return <Outlet />;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default PublicRoute;
