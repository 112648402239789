import axios from "axios";
import axiosInstance from "../../api/axios";

const api = `${process.env.REACT_APP_BACK_URL}/api/v1`;

export const LOGIN_API = `${api}/admin/loginSuperAdmin`;
export const LOGOUT_API = `${api}/admin/logout`;
export const GET_ADMIN = `${api}/admin/getAdminById`;
export const GET_ADMIN_AFFECTATION = `${api}/affectationAdmin/getAffectationsByAdminId`;
export const GET_AFFECTATION_BY_ID = `${api}/affectationAdmin/getAffectationById`;

export const signInAuth = async (authData) => {
  try {
    const response = await axios.post(LOGIN_API, authData);
    if (response.data.accessToken) {
      localStorage.setItem('accessToken', response.data.accessToken);
    }
    return response.data;
  } catch (error) {
    throw error.response?.data?.error;
  }
};

export const logoutAuth = async () => {
  try {
    localStorage.removeItem('accessToken');
    const response = await axiosInstance.post(LOGOUT_API);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const getAdminAuth = async (adminId) => {
  try {
    const response = await axiosInstance.get(`${GET_ADMIN}/${adminId}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

// export const getAdminAffectations = async (adminId) => {
//   try {
//     const response = await axiosInstance.get(
//       `${GET_ADMIN_AFFECTATION}/${adminId}`
//     );
//     return response.data;
//   } catch (error) {
//     throw error.response.data.error;
//   }
// };

// export const getAffectationById = async (affectationId) => {
//   try {
//     const response = await axiosInstance.get(
//       `${GET_AFFECTATION_BY_ID}/${affectationId}`
//     );
//     return response.data;
//   } catch (error) {
//     throw error.response.data.error;
//   }
// };
