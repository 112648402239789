import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableComp from "../../../Components/dataTable/TableComp";
import { Eye, Edit } from "react-feather";
import { Btn, H6, Image } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";
import { Label, Input } from "reactstrap";
import { toast } from "react-toastify";

import { deleteCategoryAsync, getAllCategoryTreeAsync, getSubCategoryAsync } from "../../../redux-toolkit/slices/categorySlice";
const SingleCategory = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const categoryData = useSelector((state) => state.category.subCategory);

  useEffect(() => {
    location &&affectationSelected&&
    dispatch(
      getSubCategoryAsync(
        {categoryId:params.categoryId,
          establishmentId: affectationSelected?.establishments._id,}
      )
    );
  }, [dispatch,affectationSelected,params]);

  const categoryTableColumns = [
    {
      name: "Image",
      selector: (row) => (
        <Image
          src={dynamicImage("ecommerce/options.png")}
          className="w-50 m-l-10"
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    // {
    //   name: "Department",
    //   selector: (row) => row?.name,
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: "Statut",
    //   selector: (row) => (
    //     <div className="icon-state switch-outline">
    //       <Label className="switch">
    //         <Input type="checkbox" checked={row?.statut} />
    //         <span className="switch-state bg-primary"></span>
    //       </Label>
    //     </div>
    //   ),
    //   sortable: true,
    //   center: true,
    // },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const categoryTableColumnChildren = [
    {
      name: "Image",
      selector: (row) => (
        <Image
          src={dynamicImage("ecommerce/options.png")}
          className="w-50 m-l-10"
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Sub Category",
      selector: (row) => row?.children,
      sortable: true,
      center: true,
    },
    // {
    //   name: "Services",
    //   selector: (row) => row?.services?.length,
    //   sortable: true,
    //   center: true,
    // },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            title="View"
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>

          <a
            className="btn-xs"
            title="Edit"
             onClick={() => handelNavigateEditSubCategory(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };
  const handelDeleteCategory = async () => {
    if (selectedRows?.[0]?.children === 0) {
      try {
        const response = await dispatch(
          deleteCategoryAsync(selectedRows?.[0]?._id)
          
        );
        dispatch(
          getSubCategoryAsync(
            {categoryId:params.categoryId,
              establishmentId: affectationSelected?.establishments._id,}
          )
        );
        if (response?.message) {
          toast.error(response.message);
        } else {
          toast.success("Category deleted successfully!");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the category.");
      }
    } else {
      toast.error("This category have subCategory");
    }
  };
  const handelNavigateToForm=(row)=>{
    const rowName = location?.state?.name.replace(/ /g, "-");

    navigate(`/services-manager/category/${params.categoryId}/subCategory/new`)
  }

  const handelNavigateToSubCategory = (row) => {
    const rowName = row.name.replace(/ /g, "-");
    navigate(`/services-manager/category/${row?._id}`, { state: row });
  };
  const handelNavigateEditSubCategory=(row)=>{
    console.log(row)
    const rowName = location?.state?.name.replace(/ /g, "-");
    navigate(`/services-manager/category/${params.categoryId}/subCategory/edit/${row?._id}`, { state: row });
  };
  
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };
  return (
    <div className="page-body">
      <Breadcrumbs />
      <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
        <Btn
          color="danger mb-2 m-r-15"
          onClick={() => handelDeleteCategory()}
          disabled={
            selectedRows.length != 0 ? false : true
            // !selectedRows.some((e) =>
            //   categoryData.some((item) => item._id === e._id)
            // )
          }
        >
          Delete
        </Btn>
        <Btn
          color="btn btn-primary mb-2"
           onClick={() => handelNavigateToForm()}
        >
          Add New Sub Category
        </Btn>
      </div>
      <TableComp
        data={
          categoryData
        }
        columns={
          
             categoryTableColumnChildren
        }
        // expandableRowsComponent={ExpandedComponent}
        handleRowSelected={handleRowSelected}
        toggleDelete={false}
        expandableRows={false}
        highlightOnHover={true}
        fixedHeader={true}
        customStyles={customStyles}
        onRowClicked={(row) => handelNavigateToSubCategory(row)}
      />
    </div>
  );
};

export default SingleCategory;
