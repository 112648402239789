import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getAllSubscriptionPackOption,
  getAllSubscriptionPackOptions,
} from "../services/subscriptionPackOptionService";

const initialState = {
  subscriptionPackOptions: [],
  loading: false,
  error: null,
};

export const getAllSubscriptionPackOptionAsync = createAsyncThunk(
  "SubscriptionPackOption/fetchData",
  async (params = {}, { rejectWithValue }) => {
    const { page = null, limit = null } = params;
    try {
      const response = await getAllSubscriptionPackOption({ page, limit });
      console.log("🚀 ~ response.data:", response.data)
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


const subscriptionPackOptionSlice = createSlice({
  name: "subscriptionPackOption",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSubscriptionPackOptionAsync.fulfilled, (state, action) => {
      state.subscriptionPackOptions = action.payload.data;
      state.loading = false;
      state.error = null;
    })
    .addCase(getAllSubscriptionPackOptionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        
    })
    .addCase(getAllSubscriptionPackOptionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
    })

}

})
export const subscriptionPackOptionReducer = subscriptionPackOptionSlice.reducer;
const {actions} = subscriptionPackOptionSlice;