import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPrestation, getAllPrestations, deletePrestation, createPrestation, updatePrestation, createPrestationWithRole } from "../services/prestationService";
import axiosInstance from "../../api/axios";

const initialState = {
  prestations: [],
  loading: false,
  error: null,
};

export const getAllPrestationsAsync = createAsyncThunk(
  "Prestation/fetchData",
  async (params={},{ rejectWithValue }) => {
    const { page = null, limit = null, name = "" } = params;
    try {
      const response = await getAllPrestations({page,limit,name});
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);

export const createPrestationsAsync = createAsyncThunk(
  "Prestation/create",
  async (data,{ rejectWithValue }) => {
    try {
      const response = await createPrestation(data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);
export const createPrestationWithRoleAsync = createAsyncThunk(
  "Prestation/createWithRole",
  async (data,{ rejectWithValue }) => {
    try {
      const response = await createPrestationWithRole(data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
      
  }
);

export const deletePrestationAsync = createAsyncThunk(
  "Prestation/delete",
  async(prestationId,{rejectWithValue}) => {
    try {
      const response = await deletePrestation(prestationId);
      return response ;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const updatePrestationAsync = createAsyncThunk(
  "prestation/update",
  async ({ prestationId, prestationData },{rejectWithValue}) => {
    try {
      const response = await updatePrestation(prestationId, prestationData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



const prestationSlice = createSlice({
  name: "prestation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getAllPrestationsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

    .addCase(getAllPrestationsAsync.fulfilled, (state, action) => {
      state.prestations = action.payload.data;
      state.loading = false;
      state.error = null;
    })

    .addCase(getAllPrestationsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(createPrestationWithRoleAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
    .addCase(createPrestationWithRoleAsync.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.prestations.push(action.payload.data.etablissement);
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(createPrestationWithRoleAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(updatePrestationAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updatePrestationAsync.fulfilled, (state, action) => {
      // Mettre à jour l'établissement dans le state après la réussite de la mise à jour
      const updatedPrestation = action.payload.data;
      const index = state.prestations.findIndex(est => est._id === updatedPrestation._id);
      if (index !== -1) {
        state.prestations[index] = updatedPrestation;
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(updatePrestationAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(deletePrestationAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deletePrestationAsync.fulfilled, (state, action) => {
      const deletedPrestationId = action.payload.data;
      state.prestations = state.prestations.filter(
        (est) => est._id !== deletedPrestationId
      );
      state.loading = false;
      state.error = null;
    })
    .addCase(deletePrestationAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const prestationReducer = prestationSlice.reducer;
export const { actions } = prestationSlice;