import React, { useEffect, useState } from "react";
import { Edit, Eye } from "react-feather";
import TableComp from "../../dataTable/TableComp";
import DataTable from "react-data-table-component";

import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOneRole,
  getAllRolesByEstablishmentIdAsync,
  setRoles,
} from "../../../redux-toolkit/slices/roleSlice";
import AddRole from "./addRole";
import { Btn } from "../../../AbstractElements";
import SweetAlert from "sweetalert2";

const Roles = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dataRow, setDataRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const rolesData = useSelector((state) => state.role.roles);
  console.log("🚀 ~ Roles ~ rolesData:", rolesData);

  const handleClose = () => {
    setOpen(false);
  };

  const handelNavigateToRow = (row) => {
    setDataRow(row);
    setOpen(true);
  };

  const addRole = () => {
    setOpen(true);
  };

  const RolesTableColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      center: true,
    },
    {
      name: "Permissions",
      selector: (row) => row?.permissions.length,
      sortable: true,
      center: true,
    },
    {
      name: "Owner",
      selector: (row) => (row.isRoot ? "Owner" : "Not owner"),
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>
          <a
            className="btn-xs"
            onClick={() => handelNavigateToRow(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handelDeleteRole = async () => {
    try {
      const role = selectedRows[0];
      const idRole = role._id;
      const result = await SweetAlert.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "cancel",
        reverseButtons: true,
      });
      if (result.value) {
        const RoleDeleted = await dispatch(deleteOneRole(idRole));
        if (deleteOneRole.fulfilled.match(RoleDeleted)) {
          SweetAlert.fire(
            "Deleted!",
            "Employee(s) have been deleted.",
            "success"
          );
          await dispatch(
            getAllRolesByEstablishmentIdAsync({
              establishementId: affectationSelectedData?.establishments._id,
              page: 1,
              limit: 10,
            })
          );
          setSelectedRows([]);
        } else {
          SweetAlert.fire(
            "Error!",
            "Employee(s) have been not deleted.",
            "error"
          );
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (affectationSelectedData) {
      dispatch(
        getAllRolesByEstablishmentIdAsync({
          establishementId: affectationSelectedData?.establishments._id,
          page: 1,
          limit: 10,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ selectedRows:", selectedRows);
  }, [selectedRows]);

  return (
    <Container fluid={true}>
      <Row>
        <Col xl="12" className="d-flex align-items-center justify-content-end">
          <Btn
            color="btn btn-primary mb-2"
            style={{ marginRight: "20px" }}
            onClick={addRole}
          >
            Add Role
          </Btn>
          <Btn color="btn btn-danger mb-2" onClick={handelDeleteRole}>
            Delete Role
          </Btn>
        </Col>
      </Row>

      {rolesData && (
        <Row>
          <DataTable
            data={rolesData.data}
            columns={RolesTableColumns}
            striped={true}
            pagination
            selectableRows
            selectableRowsSingle
            onSelectedRowsChange={handleRowSelected}
            highlightOnHover={true}
            fixedHeader={true}
          />
        </Row>
      )}
      <AddRole
        open={open}
        handleClose={handleClose}
        dataRow={dataRow}
        setDataRow={setDataRow}
      />
    </Container>
  );
};

export default Roles;
