import { Home, Server, Users, ShoppingBag, Key, Settings } from "react-feather";

const MENUITEMS = [
  {
    title: "DashBoard",
    icon: <Home />,
    url: "/dashboard",
    type: "link",
    // menu: [
    //   {
    //     title: "Home",
    //     url: `${process.env.PUBLIC_URL}/home`,
    //     type: "link",
    //   },
    // ],
  },
  {
    title: "Services Manager",
    icon: <Server />,
    url: "/services-manager",
    type: "link",
    menu: [
      {
        title: "Services",
        url: `/services-manager/services`,
        type: "link",
      },
      {
        title: "Category",
        url: `/services-manager/category`,
        type: "link",
      },
      {
        title: "Department",
        url: `/services-manager/department`,
        type: "link",
      },
    ],
  }, 
  {
    title: "Affectation Manager",
    icon: <Server />,
    url: "/affectation-manager",
    type: "link",
    menu: [
      {
        title: "Establishments",
        url: `/affectation-manager/establishments`,
        type: "link",
      },
      {
        title: "External services",
        url: `/affectation-manager/prestations`,
        type: "link",
      },
      {
        title: "Admins",
        url: `/affectation-manager/admins`,
        type: "link",
      }, 
      {
        title: "AffectationsAdmins",
        url: `/affectation-manager/affectationsAdmins`,
        type: "link",
      },
    ],
  },
  {
    title: "Subscription Manager",
    icon: <Server />,
    url: "/subscription-manager",
    type: "link",
    menu: [
      {
        title: "Packs",
        url: `/subscription-manager/packs`,
        type: "link",
      },
      {
        title: "Option Templates",
        url: `/subscription-manager/optionTemplates`,
        type: "link",
      },
      {
        title: "Pack Options",
        url: `/subscription-manager/packOptions`,
        type: "link",
      }, 
      {
        title: "Subscriptions",
        url: `/subscription-manager/subscriptions`,
        type: "link",
      }, 
    ],
  },
  {
    title: "Settings",
    icon: <Settings />,
    url: "/settings-page",
    type: "link",
  },
];

export default MENUITEMS;
