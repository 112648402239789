import axios from "axios";
import axiosInstance from "../../api/axios";


const GET_ALL_AFFECTATION_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationAdmin/`;
const CREATE_AFFECTATION_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationAdmin/createAffectationAdmin`;
const UPDATE_AFFECTATION_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationAdmin/updateAffectationAdmin`;
const DELETE_AFFECTATION_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationAdmin/deleteAffectationAdmin`;

export const getAllAffectationAdmin = async ({ establishmentId, roleName, page, limit }) =>{
  try {
    const queryParams = new URLSearchParams();
    if (establishmentId) queryParams.append('establishmentId', establishmentId);
    if (roleName) queryParams.append('roleName', roleName);
    if (page) queryParams.append('page', page);
    if (limit) queryParams.append('limit', limit);

    const queryString = queryParams.toString();
    const url = queryString ? `${GET_ALL_AFFECTATION_ADMIN}?${queryString}` : GET_ALL_AFFECTATION_ADMIN;
    console.log("🚀 ~ getAllAffectationAdmin ~ queryString:", queryString)
    const response = await axiosInstance.get(url);
    console.log("🚀 ~ getAllAffectationAdmin ~ response:", response)

    return response;
  } catch (error) {
    throw error;
  }
}

export const createAffectationAdmin = async (data) => {
  console.log("🚀 ~ createAffectationAdmin ~ data:", data)
  try {
    const response = await axiosInstance.post(CREATE_AFFECTATION_ADMIN, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAffectationAdmin = async (affectationId, affectationData) => {
  try {
    const response = await axiosInstance.post(`${UPDATE_AFFECTATION_ADMIN}/${affectationId}`, affectationData);
    return response;
  } catch (error) {
    throw error;
  }
};


// export const getAffectationByAdminId = async (adminId) => {
//   try {
//     const response = await axiosInstance.get(
//       GET_AFFECTATION_BY_ADMIN_ID(adminId)
//     );

//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const deleteAffectationAdminById = async (affectationAdminId) =>{
  try {
    const response = await axiosInstance.delete(`${DELETE_AFFECTATION_ADMIN}/${affectationAdminId}`)
    return response;
  } catch (error) {
    throw error;
  }
}