import React, { useState, useEffect } from "react";
import { Col, CardBody, Row, Label, CardFooter } from "reactstrap";
import { Button } from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UploadDropzone from "../../../../Components/Dropzone/UploadDropzone";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import {
  createCategoryAsync,
  getAllCategoryTreeAsync,
  getAllParentCategoryAsync,
  getSubCategoryAsync,
  updateCategoryAsync,
} from "../../../../redux-toolkit/slices/categorySlice";
import { FaTrash } from "react-icons/fa";

const index = () => {
  const dispatch = useDispatch();
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const categoryParentData = useSelector((state) => state.category.parents);
  const routeParams = useParams();
  const navigate = useNavigate();
  const [hasAnImage, setHasAnImage] = useState(false);
  let location = useLocation();
  const pathname = location.pathname.split("/");
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const schema = yup.object().shape({
    name: yup.string().required("You must enter a category name"),
  });

  const categoryTableColumns = [
    {
      name: "Image",
      selector: (row) => (
        <Image
          src={dynamicImage("ecommerce/options.png")}
          className="w-50 m-l-10"
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Sub Category",
      selector: (row) => row?.children?.length,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
console.log("affecctation selected",affectationSelected)
  useEffect(() => {
    if (userAdmin) {
      categoryParentData &&
        dispatch(
          getAllParentCategoryAsync({
            establishment: affectationSelected?.establishments._id,
          })
        );
    }
  }, [dispatch, userAdmin]);

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { isValid, dirtyFields, errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    console.log("🚀 ~ file: index.jsx:98 ~ useEffect ~ location:", location);
    if (location?.state?.name) {
      
      reset(
        { name: location?.state?.name },
        { parentCategory: location?.state?.parent }
      );
    }
    if (Array.isArray(location?.state?.images) && location.state.images.length > 0) {
      setHasAnImage(true);
    }
  }, [location]);

  const getUploadParams = (body) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleSubmitFile = ({ meta, file, remove }, status) => {
    setValue("images", [file]);
    if (status === "headers_received") {
      toast.success(`${meta.name} uploaded!`);
    } else if (status === "aborted") {
      toast(`${meta.name}, upload failed...`);
    } else if (status === "removed") {
      resetField("images");
    }
  };
  async function onSubmit(data) {
    const formData = new FormData();
    console.log(data)
    if (pathname.includes("new")&&!pathname.includes("subCategory")) {
      if (data?.parentCategory?.value) {
        formData.append("parent", data?.parentCategory?.value);
      }
      formData.append("name", data?.name);
      formData.append("cible", "internal");
      formData.append("establishment", affectationSelected?.establishments._id  );
      for (let i = 0; i < data?.images?.length; i++) {
        formData.append("images", data.images[i]);
      }
      
      await dispatch(createCategoryAsync(formData)).then((res) => {
        console.log(
          "🚀 ~ file: index.jsx:101 ~ onSubmit ~ categoryCreated:",
          res
        );

        toast.success("Category added successfully!");
dispatch(
        getAllCategoryTreeAsync({
          establishment: affectationSelected?.establishments._id,
        })
      );
        // dispatch(
        //   getSubCategoryAsync(
        //     {categoryId:routeParams.categoryId,
        //       establishmentId: affectationSelected?.establishments._id,}
        //   )
        // );
        navigate("/services-manager/category/");
       
      });
    } else if(!pathname.includes("new")&&!pathname.includes("subCategory")) {
      const updatePayload = {};
      console.log("ediitttttttttttttt",location?.state?.name)
      if (data.name) {
        if (data.name !== location?.state?.name) {
          formData.append("name", data?.name);
          console.log(formData)
        } else {
          formData.append("name", location?.state?.name);
          console.log(formData)
        }
      }

      formData.append("cible", "internal");
      console.log(formData)
      if (data?.parentCategory?.value) {
        formData.append("parent", data?.parentCategory?.value);
        console.log(formData)
      }
      formData.append("establishment", affectationSelected?.establishments._id);
      console.log(formData)
      for (let i = 0; i < data?.images?.length; i++) {
        formData.append("images", data.images[i]);

      }
      console.log(formData)
      await dispatch(
        updateCategoryAsync({
          idCategory: location?.state?._id,
          categoryData: formData,
        })
      ).then((res) => {
        console.log("res ====>",res)
        toast.success("Category updated successfully!");
        navigate("/services-manager/category/");
      });
    }
    else if(pathname.includes("new")&&pathname.includes("subCategory"))
  {
    
      formData.append("parent", routeParams.categoryId);
    
    formData.append("name", data?.name);
    formData.append("cible", "internal");
    formData.append("establishment", affectationSelected?.establishments._id);
    for (let i = 0; i < data?.images?.length; i++) {
      formData.append("images", data.images[i]);
    }
    
    await dispatch(createCategoryAsync(formData)).then((res) => {
      console.log(
        "🚀 ~ file: index.jsx:101 ~ onSubmit ~ categoryCreated:",
        res
      );
      const rowName = location?.state?.name.replace(/ /g, "-");
      toast.success("SubCategory added successfully!");
      
      navigate(`/services-manager/category/${routeParams.categoryId}`);
      dispatch(
        getSubCategoryAsync(
          {categoryId:routeParams.categoryId,
            establishmentId: affectationSelected?.establishments._id}
        )
      );
    });

  }
    else if(!pathname.includes("new")&&pathname.includes("subCategory"))
   {
    if (data.name) {
      if (data.name !== location?.state?.name) {
        formData.append("name", data?.name);
      } else {
        formData.append("name", location?.state?.name);
      }
    }
    formData.append("cible", "internal");
    if (data?.parentCategory?.value) {
      formData.append("parent", routeParams.categoryId);
    }
    // if(location?.state?.images){
    //   console.log("lalalalalalallal")
    // }
    formData.append("establishment", affectationSelected?.establishments._id);
    for (let i = 0; i < data?.images?.length; i++) {
      formData.append("images", data.images[i]);
    }
    await dispatch(
      updateCategoryAsync({
        idCategory: location?.state?._id,
        categoryData: formData,
      })
    ).then((res) => {
     
      toast.success("SubCategory updated successfully!");
      navigate(`/services-manager/category/${routeParams.categoryId}`);
    });
   }
  
  
  }
  const handleDeleteImage = () => {
    setHasAnImage(false);
  };
 

  return ( <div className="page-body">
    <Breadcrumbs />
    <Col xl="12">
      <form className="card">
        <CardBody>
          <Row>
          <Col xl="3" className="position-relative">
                {hasAnImage ? (
                  <div className="position-relative">
                    <img
                      src={process.env.REACT_APP_MINIO_URL + location?.state?.images[0]}
                      alt="Preview"
                      className="img-fluid"
                    />
                    <FaTrash className="position-absolute top-0 start-0 text-danger" style={{ cursor: 'pointer' }} onClick={handleDeleteImage} />
                  </div>
                ) : (
                  <Controller
                    name="images"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <UploadDropzone
                        getUploadParams={getUploadParams}
                        handleChangeStatus={handleSubmitFile}
                        {...field}
                      />
                    )}
                  />
                )}
              </Col>
            <Col xl="9">
              <Row>
                <Col md="12">
                  <Label>Category Name</Label>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <SimpleInput
                        {...field}
                        errclassname="error-msg-input"
                        className="form-control"
                        placeholder="Name"
                        errors={errors.name?.message}
                      />
                    )}
                  />
                </Col>
                
                {!pathname.includes("subCategory") && (
                  <Col lg="12" className="p-t-15">
                    <Label>Parent Category</Label>
                    <Controller
                      name="parentCategory"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={categoryParentData?.map((e) => ({
                            label: e.name,
                            value: e._id,
                          }))}
                          {...field}
                          isClearable={true}
                        />
                      )}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Button onClick={handleSubmit(onSubmit)} color="secondary">
            Save
          </Button>
        </CardFooter>
      </form>
    </Col>
  </div>
);
};

export default index;
