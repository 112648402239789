import axiosInstance from "../../api/axios";

const GET_ALL_PRESTATIONS = `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/`;
const CREATE_PRESTATION = `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/add`;
const CREATE_PRESTATION_WITH_ROLE = `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/createEtablissementWithRole`
const UPDATE_PRESTATION =`${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/update`;
const DELETE_PRESTATION = (idPrestation) => 
  `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/delete/${idPrestation}`;

// export const getAllPrestations = async () => {
//   try{
//     const response = await axiosInstance.get(GET_ALL_PRESTATIONS);
//     console.log("🚀 ~ getAllPrestations ~ response:", response)
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export const getAllPrestations = async ({ page= null, limit=null , name="" }) => {
  try{
    const queryParams = new URLSearchParams();
    if (page) queryParams.append('page', page);
    if (limit) queryParams.append('limit', limit);
    if (name) queryParams.append('name', name);
    queryParams.append('onModel', 'Prestation');
    
    const queryString = queryParams.toString();
    const url = queryString ? `${GET_ALL_PRESTATIONS}?${queryString}` : GET_ALL_PRESTATIONS;

    const response = await axiosInstance.get(url);
    console.log("🚀 ~ getAllPrestations ~ response:", response)
    return response;
  } catch (error) {
    throw error;
  }
}

  export const createPrestation = async (data) => {
    try {
      const response = await axiosInstance.post(CREATE_PRESTATION,data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  export const createPrestationWithRole = async (data) => {
    try {
      const response = await axiosInstance.post(CREATE_PRESTATION_WITH_ROLE,data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const deletePrestation = async (idPrestation) => {
    try{
      const response = await axiosInstance.delete(DELETE_PRESTATION(idPrestation));
      console.log("🚀 ~ deletePrestation ~ response:", response)
      return response;
    } catch (error) {
      throw error;
    }
}

export const updatePrestation = async (id, data) => {
  if (data instanceof FormData) {
    console.log("🚀 ~ updatePrestation ~ FormData:", FormData)
    for (let pair of data.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  } else {
    console.log("🚀 ~ updatePrestationData ~ data:", data);
  }
  try {
    const response = await axiosInstance.put(
      `${UPDATE_PRESTATION}/${id}`,
      data
    );
    console.log("🚀 ~ updatePrestation ~ response:", response);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};