import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import React from "react";

type CustomAxiosRequestHeaders = {
  [key: string]: string;
};

axios.defaults.withCredentials = true;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACK_URL}/api/v1`, // Your API base URL
  timeout: 10000, // Request timeout in milliseconds
  withCredentials: true, // Enable sending cookies with requests
});

// Helper function to refresh token
const refreshToken = async (): Promise<string> => {
  try {
    const response = await axiosInstance.get(
      "/refreshToken/refreshtokenendpoint",
      { withCredentials: true }
    );
    const newToken: string = response.data.accessToken;
    return newToken;
  } catch (error: any) {
    redirectToLogin();
    // Handle token refresh error
    return error;
  }
};
// Function to redirect to login page
const redirectToLogin = () => {
  // Replace '/login' with the actual URL of your login page
  localStorage.removeItem("accessToken");
  localStorage.removeItem("affectation");
  localStorage.removeItem("admin");
  window.location.replace("/login");
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      if (config.headers) {
        // Ensure that config.headers is of type CustomAxiosRequestHeaders
        (config.headers as CustomAxiosRequestHeaders)[
          "Authorization"
        ] = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      // Redirect to login page on unauthorized access
      redirectToLogin();
    } else if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshToken();
        console.log("🚀 ~ newToken:", newToken);

        if (newToken) {
          localStorage.setItem("accessToken", newToken);
          // Update the authorization header with the new token
          if (!originalRequest.headers) {
            originalRequest.headers = {};
          }
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        // Handle token refresh error
        redirectToLogin(); // Redirect to login on refresh token failure
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;