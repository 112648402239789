import { AddNewEstablishment } from "../../../Constant";
import { Row, Col, Form, Label, Button } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import EstablishmentPageContainer from "./EstalishmentPageContainer"
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAllEstablishmentsAsync } from "../../../redux-toolkit/slices/establishmentSlice";
import { useDispatch } from "react-redux";
const EstablishmentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelNavigateToForm = () => {
    navigate("/affectation-manager/establishments/new");
  };

  const { handleSubmit, control,reset } = useForm({
    defaultValues: {
      name : "",
    }
  });
  const onSubmit = async (data) => {
    try {
      const filteredEstablishments = await dispatch(getAllEstablishmentsAsync(data));
      if (getAllEstablishmentsAsync.rejected.match(filteredEstablishments))
        toast.error("Error",filteredEstablishments?.payload);
    } catch (error) {
      toast.error("Fatal error getting establishments !");
    }
  };

  return (
    <div className="page-body">
      
      <Breadcrumbs />

      <Row
        className={`d-flex align-items-end justify-content-center bg-light-info p-2`}
        style={{ marginTop: "-48px", marginBottom: "20px" }}
      >
        <Col xl="6" md="6" sm="12" className="d-flex align-items-center" >
            <Form className="w-100 d-flex justify-content-between align-items-end my-2" onSubmit={handleSubmit(onSubmit)}>
                  <Row className="d-flex align-items-end">
                    <Col md="6" sm="6">
                      <Label>Establishment name</Label>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            {...field}
                            type="search"
                            placeholder="Establishment name"
                            aria-label="Establishment name"
                          />
                        )}
                      />
                    </Col>
                    <Col  md="3" sm="3">
                      <Button variant="outline-success" type="submit">
                        Search
                      </Button>
                    </Col>
                    <Col  md="3" sm="3">
                      <Button color="warning" onClick={()=> {
                        reset();
                        dispatch (getAllEstablishmentsAsync())
                        }}>
                        Reset
                      </Button>
                    </Col>
                  </Row>
              </Form>
        </Col>
        <Col xl="6" md="6" sm="12" className="d-flex align-items-center justify-content-end">
          <Btn
            color="btn btn-primary mb-2"
            onClick={() => handelNavigateToForm()}
          >
            {t(AddNewEstablishment)}
          </Btn>
        </Col>
      </Row>
      <EstablishmentPageContainer/>
    </div>
  );
};
export default EstablishmentPage;