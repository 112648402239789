import { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter } from "reactstrap";
import SweetAlert from "sweetalert2";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { Btn } from "../../../AbstractElements";
import { getAllAdminsAsync } from "../../../redux-toolkit/slices/adminSlice";
import { getAllPacksAsync } from "../../../redux-toolkit/slices/packSlice";
import { getPackOptionsByPackIdAsync } from "../../../redux-toolkit/slices/packOptionSlice";
import DatePicker from "react-datepicker";
import { updateSubscriptionAsync } from "../../../redux-toolkit/slices/subscriptionSlice";

const SubscriptionsForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const subscription = location.state;
  console.log("🚀 ~ SubscriptionsForm ~ subscription:", subscription);

  const [packOptionsList, setPackOptionsList] = useState([]);
  const selectedPackOptions =
    subscription?.subscriptionPackOptions.map((option) => option._id) || [];
  console.log(
    "🚀 ~ SubscriptionsForm ~ selectedPackOptions:",
    selectedPackOptions
  );

  const schema = yup.object().shape({
    admin: yup
      .object()
      .shape({
        label: yup.string().required("Admin is required"),
        value: yup.string().required("Admin is required"),
      })
      .required("Admin is required"),
    pack: yup
      .object()
      .shape({
        value: yup.string().required("Pack is required"),
      })
      .required("Pack is required"),
    pricePack: yup.number().required("Price Pack is required"),
    total: yup.number().required("Total is required"),
    selectedDate: yup.date().required("Date End is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      admin: {
        label: subscription?.admin?.username,
        value: subscription?.admin?._id,
      },
      pack: {
        label: subscription?.pack?.name,
        value: subscription?.pack?._id,
      },
      pricePack: subscription?.pricePack,
      total: subscription?.total,
      selectedDate: subscription.dateEnd
        ? new Date(subscription.dateEnd)
        : null,
      }
    });

  const admins = useSelector((state) => state.admin.admins);
  const packs = useSelector((state) => state.pack.packs);

  const selectedPack = watch("pack");

  useEffect(() => {
    if (admins.length === 0) {
      dispatch(getAllAdminsAsync());
    }
  }, [dispatch, admins]);

  useEffect(() => {
    if (packs.length === 0) {
      dispatch(getAllPacksAsync());
    }
  }, [dispatch, packs]);

  useEffect(() => {
    if (selectedPack?.value) {
      (async () => {
        try {
          const result = await dispatch(
            getPackOptionsByPackIdAsync({ packId: selectedPack.value })
          ).unwrap();
          setPackOptionsList(result);
        } catch (error) {
          setPackOptionsList([])
        }
      })();
    }
  }, [dispatch, selectedPack?.value]);

  const onSubmit = async (data) => {
    try {
      const { packOptionsList } = data;

      const formattedPackOptions = packOptionsList ? Object.keys(packOptionsList)
        .map((key) => ({
          id: key,
          selected: packOptionsList[key].selected,
          price: packOptionsList[key].price,
        }))
        .filter((option) => option.selected)
        : [];

      const formattedData = {
        adminId: data.admin.value,
        packId: data.pack.value,
        packOptions: formattedPackOptions.map((option) => ({
          id: option.id,
          pricePackOption: option.price,
        })),
        dateEnd: data.selectedDate.toISOString(),
        total: data.total,
        pricePack: data.pricePack,
      };

      const subscriptionUpdated = await dispatch(
        updateSubscriptionAsync({
          subscriptionId: subscription._id,
          data: formattedData,
        })
      );

      if (updateSubscriptionAsync.rejected.match(subscriptionUpdated)) {
        SweetAlert.fire(
          "Error",
          subscriptionUpdated.payload || "An unexpected error occurred.",
          "error"
        );
      } else {
        SweetAlert.fire(
          "Added!",
          "Subscription was updated successfully!",
          "success"
        );
        navigate(`/subscription-manager/subscriptions`);
      }
    } catch (error) {
      SweetAlert.fire(
        "Error",
        error.message || "An unexpected error occurred.",
        "error"
      );
    }
  };

  return (
    <>
      <div className="page-body">
        <Breadcrumbs />
        <Col xl="12">
          <form className="card" onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <Row>
                <Col xl="12">
                  <Row>
                    <Col lg="6" className="m-b-15">
                      <Label>Admin</Label>
                      <Controller
                        name="admin"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              options={admins.map((a) => ({
                                label: a.username,
                                value: a._id,
                              }))}
                              {...field}
                            />
                            {errors.admin && (
                              <span style={{ color: "red" }}>
                                {errors.admin.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col lg="6" className="m-b-15">
                      <Label>Pack</Label>
                      <Controller
                        name="pack"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              options={packs.map((p) => ({
                                label: p.name,
                                value: p._id,
                              }))}
                              {...field}
                            />
                            {errors.pack && (
                              <span style={{ color: "red" }}>
                                {errors.pack.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <h5 style={{ color: "#655af3" }} className="mt-3">
                      Other details
                    </h5>
                    <Col lg="6" className="m-b-15">
                      <Label>Price Pack</Label>
                      <Controller
                        name="pricePack"
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="text"
                            {...field}
                          />
                        )}
                      />
                    </Col>
                    <Col lg="6" className="m-b-15">
                      <Label>Total price</Label>
                      <Controller
                        name="total"
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="text"
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="6"
                      sm="12"
                      className="m-b-15 d-flex gap-2 align-items-center"
                    >
                      <Label>Date End</Label>
                      <Controller
                        name="selectedDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            className="form-control digits"
                            showPopperArrow={false}
                            selected={field.value}
                            showTimeSelect
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => {
                              field.onChange(date);
                              setValue("selectedDate", date);
                            }}
                          />
                        )}
                      />
                      {errors.selectedDate && (
                        <div className="w-100">
                          <span className="error-msg-input">
                            {errors.selectedDate.message}
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={9}>
                      <h5 style={{ color: "#655af3" }} className="mt-3">
                        List of subscription pack options
                      </h5>
                      {(selectedPack && packOptionsList.length != 0) && packOptionsList?.map((packOption) => (
                        <div key={packOption._id} className="d-flex align-items-center gap-2 mb-3">
                        <Controller
                          name={`packOptionsList.${packOption._id}.selected`}
                          control={control}
                          defaultValue={selectedPackOptions.includes(packOption._id)}
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              id={packOption._id}
                              {...field}
                              onChange={(e) => field.onChange(e.target.checked)}
                              checked={field.value}
                            />
                          )}
                        />
                          <label htmlFor={packOption._id}>
                            {packOption.optionTemplate.customName}
                          </label>
                          <Controller
                            name={`packOptionsList.${packOption._id}.price`}
                            control={control}
                            defaultValue={packOption.priceOptionTemplate}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control mx-4"
                                {...field}
                                style={{ width: "100px" }}
                              />
                            )}
                          />
                        </div>
                      ))}
                      {(selectedPack && packOptionsList.length === 0) && (
                        <div>
                         <p>No available options for the selected pack.</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="d-flex justify-content-end gap-2">
              <Button color="secondary" type="submit">
                Save
              </Button>
              <Btn
                color="primary"
                onClick={() => {
                  reset();
                  navigate(`/subscription-manager/subscriptions`);
                }}
              >
                Close
              </Btn>
            </CardFooter>
          </form>
        </Col>
      </div>
    </>
  );
};

export default SubscriptionsForm;
