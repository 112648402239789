import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../../redux-toolkit/slices/serviceSlice";
import { Col, Input, Label, Row } from "reactstrap";

const Permissions = ({ permissions, setPermissions, dataRow }) => {
  const PERMISSIONS_CONDITIONS = [
    "dashboard",
    "profile",
    "settings",
    "service manager",
    "employees",
    "clients",
    "room",
    "orders",
  ];

  const dispatch = useDispatch();
  const [servicesFetched, setServicesFetched] = useState(false);
  const [permissionsFetched, setPermissionsFetched] = useState(false);

  const servicesData = useSelector((state) => state.services.services);
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );

  const handlePermissionChange = (index, type) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[index][type] = !permissions[index][type];
    setPermissions(updatedPermissions);
  };

  useEffect(() => {
    if (userAdmin && !servicesFetched) {
      dispatch(
        fetchServices({
          establishmentId: affectationSelected?.establishments._id,
        })
      );
      setServicesFetched(true);
    }
  }, []);

  useEffect(() => {
    let mergedPermissions = [];
    console.log("servicesData dataRow data", servicesData);

    if (
      servicesData &&
      servicesData.length > 0 &&
      !permissionsFetched &&
      servicesFetched &&
      !dataRow
    ) {
      console.log("are weeeeeeeeeeee data");
      mergedPermissions = [
        ...permissions,
        ...servicesData.map((service) => ({
          name: service.name,
          read: service.permissions ? service.permissions.read : false,
          execute: service.permissions ? service.permissions.execute : false,
          update: service.permissions ? service.permissions.update : false,
          delete: service.permissions ? service.permissions.delete : false,
          service: service ? service._id : null,
        })),
      ];
      setPermissions(mergedPermissions);
      setPermissionsFetched(true);
    } else {
      if (
        servicesData &&
        servicesData.length > 0 &&
        !permissionsFetched &&
        servicesFetched &&
        dataRow
      ) {
        console.log("dataRow in dataRow data ", dataRow);
        console.log("permission in dataRow data ", permissions);

        const newAddedServices = servicesData.filter(
          (service) =>
            !permissions.some((permission) => permission.name === service.name)
        );

        // const con = [...permissions, ...newAddedServices];
        // console.log("🚀 ~ useEffect ~ con:", con);

        const updatedPermissions = [...permissions, ...newAddedServices].map(
          (permission) => ({
            name: permission.name,
            read: permission.role ? permission.read : false,
            execute: permission.role ? permission.execute : false,
            update: permission.role ? permission.update : false,
            delete: permission.role ? permission.delete : false,
            service: PERMISSIONS_CONDITIONS.includes(permission.name)
              ? null
              : permission.establishment
              ? permission._id
              : permission.service,
            role: permission.createdAt ? permission.role : null,
            _id: permission.establishment ? null : permission._id,
          })
        );

        console.log("🚀 ~ useEffect ~ updatedPermissions:", updatedPermissions);

        setPermissions(updatedPermissions);
        setPermissionsFetched(true);
      }
    }
  }, [servicesData, permissions, dataRow]);

  return (
    <Col>
      <div className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
        {permissionsFetched &&
          permissions &&
          permissions.map((data, index) => (
            <Row key={index} style={{ display: "flex", flexDirection: "row" }}>
              <Col xl="12" style={{ display: "flex", width: "100%" }}>
                <Label
                  style={{
                    marginBottom: "0px",
                    textAlign: "center",
                    paddingTop: "8px",
                    width: "30%",
                  }}
                >
                  {data.name}
                </Label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "70%",
                  }}
                >
                  <div className="checkbox checkbox-dark">
                    <Input
                      id={`inline-${index}-read`}
                      type="checkbox"
                      checked={data.read}
                      onChange={() => handlePermissionChange(index, "read")}
                    />
                    <Label htmlFor={`inline-${index}-read`} className="mb-0">
                      Read
                    </Label>
                  </div>
                  <div className="checkbox checkbox-dark">
                    <Input
                      id={`inline-${index}-execute`}
                      type="checkbox"
                      checked={data.execute}
                      onChange={() => handlePermissionChange(index, "execute")}
                    />
                    <Label htmlFor={`inline-${index}-execute`} className="mb-0">
                      Execute
                    </Label>
                  </div>
                  <div className="checkbox checkbox-dark">
                    <Input
                      id={`inline-${index}-update`}
                      type="checkbox"
                      checked={data.update}
                      onChange={() => handlePermissionChange(index, "update")}
                    />
                    <Label htmlFor={`inline-${index}-update`} className="mb-0">
                      Update
                    </Label>
                  </div>
                  <div className="checkbox checkbox-dark">
                    <Input
                      id={`inline-${index}-delete`}
                      type="checkbox"
                      checked={data.delete}
                      onChange={() => handlePermissionChange(index, "delete")}
                    />
                    <Label htmlFor={`inline-${index}-delete`} className="mb-0">
                      Delete
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
      </div>
    </Col>
  );
};

export default Permissions;
