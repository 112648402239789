import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import {
  getAllDepartement,
  addNewDepartement,
  deleteDepartment,
  getDepartmentById,
} from "../services/departementService";

const initialState = {
  departement: [],
  departementId: [],
  loading: false,
  error: null,
};

export const fetchDepartment = createAsyncThunk(
  "departements/fetchData",
  async (establishment) => {
    console.log(
      "🚀 ~ file: departementSlice.jsx:13 ~ establishmentId:",
      establishment
    );
    try {
      const response = await getAllDepartement(establishment);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addDepartement = createAsyncThunk(
  "departements/addDepartement",
  async (departementData) => {
    console.log(
      "🚀 ~ file: serviceSlice.jsx:45 ~ departementData:",
      departementData
    );
    const response = await addNewDepartement(departementData);
    console.log("🚀 ~ file: departementSlice.jsx:43 ~ response:", response);
    try {
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteDepartmentAsync = createAsyncThunk(
  "departement/deleteData",
  async (idDepartement, { rejectWithValue }) => {
    console.log("🚀 ~ idDepartement:", idDepartement)
    try {
      const response = await deleteDepartment(idDepartement);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getDepartmentByIdAsync = createAsyncThunk(
  "departement/fetshDepartmentId",
  async (idDepartement) => {
    console.log("🚀 ~ idDepartement:", idDepartement)
    try {
      const response = await getDepartmentById(idDepartement);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const departementSlice = createSlice({
  name: "departements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepartment.fulfilled, (state, action) => {
      state.departement = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getDepartmentByIdAsync.fulfilled, (state, action) => {
      state.departementId = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addDepartement.fulfilled, (state, action) => {
      state.services = [...state.departement, action.payload];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteDepartmentAsync.fulfilled, (state, action) => {
      state.departement = state.departement.filter(
        (departement) => departement._id !== action.payload?._id
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteDepartmentAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const departmentReducer = departementSlice.reducer;
export const { actions } = departementSlice;
