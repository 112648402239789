import Customizer from "./ThemeCustomizer";
import FooterLayout from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar-Layout/SideBar";
import Loader from "./Loader";
import Taptop from "./Taptop";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  return (
    <>
      <Loader />
      <Taptop />
      <div className={`page-wrapper ${"compact-wrapper"}`}>
        <Header />
        <div
          className={`page-body-wrapper sidebar-icon`}
        >
          <SideBar />
          <div>
            <Outlet />
          </div>
          <Customizer />
          <FooterLayout />
        </div>
      </div>
    </>
  );
};

export default Layout;