import { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch} from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../Components/Inputs/simpleInput";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { createAdminsAsync, getAllAdminsAsync, updateAdminAsync } from "../../../redux-toolkit/slices/adminSlice";
import { Btn } from "../../../AbstractElements";
function AdminsForm(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    let location = useLocation();
    const pathname = location.pathname.split("/");
    const routeParams = useParams();

    const status = [
      { label: "active", value: "active" },
      { label: "inactive", value: "inactive" },
    ];

    const passwordSchema = yup.string()
    .required('You must enter a password')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{3,}$/,
      'Password must contain at least one uppercase letter, one number, one special character and be at least 3 characters long'
    );
  
  const repeatPasswordSchema = yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('You must repeat the password');
  
    const schema = yup.object().shape({
      firstName: yup.string().required("You must enter a first name"),
      lastName: yup.string().required("You must enter a last name"),
      email: yup
        .string()
        .email("Invalid email format")
        .required("You must enter an email"),
      phone: yup.string().required("You must enter a phone number"),
      username: yup.string().required("You must enter a username"),
      password: !routeParams.id ? passwordSchema : yup.string(),
      repeatPassword: !routeParams.id ? repeatPasswordSchema : yup.string(),
      status: yup.string().transform((value) => value?.value ?? value).required("You must enter a status"),
    });

    const {
      control,
      handleSubmit,
      reset,
      formState: {errors },
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  
  async function onSubmit(data) {
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("username", data.username);
    formData.append("status", data.status);

    if (!pathname.includes("edit")) {
      formData.append("password", data.password);
      const adminAdded = await dispatch(createAdminsAsync(formData));
      if(createAdminsAsync.rejected.match(adminAdded)){
        SweetAlert.fire(
          "Error",
          adminAdded.payload,
          "error"
        );
      }else{
        SweetAlert.fire(
          "Added!",
          adminAdded.payload.message,
          "success"
        );
        props.setIsOpen(false);
        reset();
        dispatch(getAllAdminsAsync({page:1,limit:10}));
      }
    }else {
      const adminUpdated= await dispatch(updateAdminAsync({
        adminId: location?.state?._id,
        adminData: formData,
      }));
      if(updateAdminAsync.rejected.match(adminUpdated)){
        SweetAlert.fire(
          "Error",
          adminUpdated.payload,
          "error"
        );
      }else{
        SweetAlert.fire(
          "Updated!",
          adminUpdated.payload.message,
          "success"
        );
        props.setIsOpen(false);
        reset();
        dispatch(getAllAdminsAsync({page:1,limit:10}));

      }
    }
  }
    useEffect(() => {
      if (location?.state && pathname.includes("edit")) {
          reset({
            firstName: location?.state?.firstName,
            lastName: location?.state?.lastName,
            email: location?.state?.email,
            phone: location?.state?.phone,
            username: location?.state?.username,
            status: {
              label: location?.state?.status,
              value: location?.state?.status,
            }
        })
      }
      else{
        reset({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          username: "",
          status: null,
        })
      }
    }, [location]);
  
    return (
      <Modal isOpen={props.isOpen} size="lg" centered={true}>
        <ModalHeader>Create new admin</ModalHeader>
        <ModalBody>
          <div className="page-body">
            <Col xl="12">
              <form className="card">
                <CardBody>
                    <Row>
                      <Col xl="12">
                        <Row>
                          <Col lg="6" className="m-b-15">
                            <Label>First name</Label>
                            <Controller
                              name="firstName"
                              control={control}
                              render={({ field }) => (
                                <SimpleInput
                                  {...field}
                                  errclassname="error-msg-input"
                                  className="form-control"
                                  placeholder="First name"
                                  errors={errors.firstName?.message}
                                />
                              )}
                            />
                          </Col>
                          <Col lg="6" className="m-b-15">
                            <Label>Last name</Label>
                            <Controller
                              name="lastName"
                              control={control}
                              render={({ field }) => (
                                <SimpleInput
                                  {...field}
                                  errclassname="error-msg-input"
                                  className="form-control"
                                  placeholder="Last name"
                                  errors={errors.lastName?.message}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="m-b-15">
                            <Label>Email</Label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Email address"
                                  {...field}
                                />
                              )}
                            />
                            <span className="error-msg-input">{errors.email?.message}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" className="m-b-15">
                              <Label>Username</Label>
                              <Controller
                                name="username"
                                control={control}
                                render={({ field }) => (
                                  <SimpleInput
                                    {...field}
                                    errclassname="error-msg-input"
                                    className="form-control"
                                    placeholder="Custom username"
                                    errors={errors.username?.message}
                                  />
                                )}
                              />
                          </Col>
                          <Col lg="6" className="m-b-15">
                            <Label>Phone number</Label>
                            <Controller
                              name="phone"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Phone number"
                                  {...field}
                                />
                              )}
                            />
                            <span className="error-msg-input">{errors.phone?.message}</span>
                          </Col>
                        </Row>
                        <Row>
                          {!pathname.includes("edit") && 
                          <>
                            <Col lg="12" className="m-b-15">
                              <Label>Password</Label>
                              <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Password"
                                    {...field}
                                  />
                                )}
                              />
                              <span className="error-msg-input">
                                {errors.password?.message}
                              </span>
                            </Col>
                            <Col lg="12" className="m-b-15">
                              <Label>Repeat password</Label>
                              <Controller
                                name="repeatPassword"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Repeat Password"
                                    {...field}
                                  />
                                )}
                              />
                              <span className="error-msg-input">
                                {errors.repeatPassword?.message}
                              </span>
                            </Col>
                          </>
                          }
                          <Col lg="12" className="m-b-15">
                            <Label>Status</Label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Select
                                      options={status?.map((e) => ({
                                        label: e.label,
                                        value: e.value,
                                      }))}
                                      {...field}
                                    />
                                    {errors.status && <span style={{color:"red"}}>{errors.status.message}</span>}
                                  </>
                                )}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                </CardBody>
              </form>
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Btn
            color="primary"
            onClick={() => {
              reset();
              props.setIsOpen(false);
              navigate(`/affectation-manager/admins`);
            }}
          >
            Close
          </Btn>
          <Button onClick={handleSubmit(onSubmit)} color="secondary">
            Save
        </Button>
        </ModalFooter>
      </Modal>
    );
}
export default AdminsForm