import axiosInstance from "../../api/axios";
const GET_ALL_SUBSCRIPTION_PACK_OPTION= `${process.env.REACT_APP_BACK_URL}/api/v1/subscriptionPackOption/`;

export const getAllSubscriptionPackOption = async (page, limit) => {
  try {
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);

    const url = `${GET_ALL_SUBSCRIPTION_PACK_OPTION}?${queryParams.toString()}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
