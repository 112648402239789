import axiosInstance from "../../api/axios";

const GET_ALL_PACKS = `${process.env.REACT_APP_BACK_URL}/api/v1/pack/`;
const CREATE_PACK= `${process.env.REACT_APP_BACK_URL}/api/v1/pack/createPack`;
const DELETE_PACK= `${process.env.REACT_APP_BACK_URL}/api/v1/pack/deletePack`;
const UPDATE_PACK= `${process.env.REACT_APP_BACK_URL}/api/v1/pack/updatePack`;

export const getAllPacks = async (page, limit) => {
    try {
      const queryParams = new URLSearchParams();
      if (page) queryParams.append('page', page);
      if (limit) queryParams.append('limit', limit);
    
      const url = `${GET_ALL_PACKS}?${queryParams.toString()}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      throw error;
    }
};

export const createPack = async (data) => {
    try {
        const response = await axiosInstance.post(`${CREATE_PACK}`,data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const updatePack= async ({packId, data}) => {
    try {
      const response = await axiosInstance.put(`${UPDATE_PACK}/${packId}`, data);
      return response;
    } catch (error) {
        throw error;
    }
  };

export const deletePack = async (packId) => {
    try {
        const response = await axiosInstance.delete(`${DELETE_PACK}/${packId}`);
        return response;
    } catch (error) {
        throw error;
    }
        
};