import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useState } from "react";
import PacksForm from "../PacksPage/PacksForm";
import PacksPageContainer from "./SubscriptionsPageContainer";
import { Col, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useNavigate } from "react-router-dom";

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const handelNavigateToForm = () => {
    navigate(`/subscription-manager/subscriptions/new`);
  };

  return (
    <div className="page-body">
      <Breadcrumbs />
      <Row
        className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
        style={{ marginTop: "-48px", marginBottom: "20px" }}
      >
        <Col
          xl="12"
          sm="12"
          className="d-flex align-items-end justify-content-end"
        >
          <Btn
            color="btn btn-primary mb-2"
            onClick={() => handelNavigateToForm()}
          >
            Add New Subscription
          </Btn>
        </Col>
      </Row>
      <PacksPageContainer/>
    </div>
  );
};
export default SubscriptionPage;
