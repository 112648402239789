import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createSubscription,
  deleteSubscription,
  getAllSubscriptions,
  updateSubscription,
} from "../services/subscriptionService";

const initialState = {
  subscriptions: [],
  loading: false,
  error: null,
};

export const getAllSubscriptionsAsync = createAsyncThunk(
  "Subscription/fetchData",
  async (params = {}, { rejectWithValue }) => {
    const { page = null, limit = null } = params;
    try {
      const response = await getAllSubscriptions({ page, limit });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSubscriptionsByAdminAsync = createAsyncThunk(
  "Subscription/fetchDataByAdmin",
  async (params = {}, adminId, { rejectWithValue }) => {
    const { page = null, limit = null } = params;
    try {
      const response = await getAllSubscriptions({ page, limit, adminId });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createSubscriptionAsync = createAsyncThunk(
  "Subscription/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createSubscription(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateSubscriptionAsync = createAsyncThunk(
  "Subscription/update",
  async ({subscriptionId,data},{rejectWithValue}) => {
      try {
          const response = await updateSubscription({subscriptionId,data});
          return response;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.error);
        } else {
          return rejectWithValue(error.message);
        }
      }
  }
);

export const deleteSubscriptionAsync = createAsyncThunk(
  "Subscription/delete",
  async (subscriptionId, { rejectWithValue }) => {
    try {
      const response = await deleteSubscription(subscriptionId);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSubscriptionsAsync.fulfilled, (state, action) => {
      state.subscriptions = action.payload.data;
      state.loading = false;
      state.error = null;
    })
    .addCase(getAllSubscriptionsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        
    })
    .addCase(getAllSubscriptionsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
    })
    .addCase(createSubscriptionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
    .addCase(createSubscriptionAsync.fulfilled, (state, action) => {
      if (action.payload.data.data) {
        state.subscriptions.push(action.payload.data.data);
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(createSubscriptionAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateSubscriptionAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updateSubscriptionAsync.fulfilled, (state, action) => {
      const updatedSubscription = action.payload.data.data;
      const index = state.subscriptions.findIndex(subscription => subscription._id === updatedSubscription._id);
      if (index !== -1) {
        state.subscriptions[index] = updatedSubscription;
      }
      state.loading = false;
      state.error = null;
    })
    .addCase(updateSubscriptionAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    })
      .addCase(deleteSubscriptionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubscriptionAsync.fulfilled, (state, action) => {
        const deletedSubscriptionId = action.payload.data;
        state.subscriptions = state.subscriptions.filter(
          (subscription) => subscription._id !== deletedSubscriptionId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteSubscriptionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

}

})
export const subscriptionReducer = subscriptionSlice.reducer;
const {actions} = subscriptionSlice;