import { Container, Row, Col, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { OL, H3 } from "../../AbstractElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Breadcrumbs = ({onClick}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  const handleBreadcrumbClick = (index) => {
    const newPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
    navigate(newPath);
  };
  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6">
            <OL className="breadcrumb">
              <BreadcrumbItem>
                <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                  <i className="f-16 fa fa-home"></i>
                </Link>
              </BreadcrumbItem>
              {pathSegments.map((segment, index) => (
                <BreadcrumbItem
                  key={index}
                  active={index === pathSegments.length - 1}
                  onClick={() => {
                    handleBreadcrumbClick(index);
                    onClick;
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t(segment)}
                </BreadcrumbItem>
              ))}
            </OL>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumbs;
