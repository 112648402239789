import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllAffectationAdmin,
  createAffectationAdmin,
  updateAffectationAdmin,
  deleteAffectationAdminById
} from "../services/affectationAdminService";

const initialStateAffectations = {
  affectationsAdmins: [],
  loading: false,
  error: null,
};

export const getAllAffectationAdminAsync = createAsyncThunk(
  "affectationAdmin/fetchData",
  async ({ establishmentId = '', roleName = '', page = 1, limit = 10 },{rejectWithValue }) => {
    try {
      const response = await getAllAffectationAdmin({ establishmentId, roleName, page, limit});
      return response.data;
    }catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } 
  }
);

export const createAffectationAdminAsync = createAsyncThunk(
  "affectationAdmin/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createAffectationAdmin(data);
      return response;
    }catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
);

export const updateAffectationAdminAsync = createAsyncThunk(
  "affectationAdmin/update",
  async ({ affectationId,affectationAdminData }, { rejectWithValue }) => {
    try {
      const response = await updateAffectationAdmin(affectationId,affectationAdminData);
      return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAffectationAdminByIdAsync = createAsyncThunk(
  "affectationAdmin/delete",
  async (affectationAdminId, { rejectWithValue }) => {
    try {
      const response = await deleteAffectationAdminById(affectationAdminId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const affectationsAdminsSlice = createSlice({
  name: "affectationsAdmins",
  initialState: initialStateAffectations,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAllAffectationAdminAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAllAffectationAdminAsync.fulfilled, (state, action) => {
      state.affectationsAdmins = action.payload.data;
      state.loading = false;
      state.error = null;
    })
    .addCase(getAllAffectationAdminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(createAffectationAdminAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(createAffectationAdminAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.affectationsAdmins.push(action.payload.affectation);
    })
    .addCase(createAffectationAdminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateAffectationAdminAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
      .addCase(updateAffectationAdminAsync.fulfilled, (state, action) => {
        const updatedAffectation = action.payload.data.affectation;
        const index = state.affectationsAdmins.findIndex(
          (affectation) => affectation._id === updatedAffectation._id
        );
      
        if (index !== -1) {
          state.affectationsAdmins[index] = updatedAffectation;
        }
        state.loading = false;
      state.error = null;
      })
    .addCase(updateAffectationAdminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(deleteAffectationAdminByIdAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deleteAffectationAdminByIdAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.affectationsAdmins = state.affectationsAdmins.filter(
        (affectation) => affectation.id !== action.payload.id
      );
    })
    .addCase(deleteAffectationAdminByIdAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
},
});
export const affectationsAdminsReducer = affectationsAdminsSlice.reducer;
const { actions } = affectationsAdminsSlice;
