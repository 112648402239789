import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCategory,
  gelAllCategoryTree,
  gelAllParentCategory,
  updateCategory,
  deleteCategory,
  getSubCategory,
} from "../services/categoryService";

const initialStateCategory = {
  parents: [],
  category: [],
  subCategory: [],
  loading: false,
  error: null,
};

export const createCategoryAsync = createAsyncThunk(
  "category/create",
  async (categoryData) => {
    try {
      const response = await createCategory(categoryData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateCategoryAsync = createAsyncThunk(
  "category/update",
  async ({ idCategory, categoryData }) => {
    try {
      const response = await updateCategory(idCategory, categoryData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getAllCategoryTreeAsync = createAsyncThunk(
  "category/fetchData",
  async (establishmentId) => {
    try {
      const response = await gelAllCategoryTree(establishmentId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAllParentCategoryAsync = createAsyncThunk(
  "parents/fetchData",
  async (establishmentId) => {
    try {
      const response = await gelAllParentCategory(establishmentId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);export const getSubCategoryAsync = createAsyncThunk(
  "subCategory/fetchSubCategpryData",
  async ( {categoryId, establishmentId }) => {
    console.log("cccccc",categoryId);
    console.log("cccccc",establishmentId);
    try {
      const response = await getSubCategory(categoryId, establishmentId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "category/deleteData",
  async (idCategory, { rejectWithValue }) => {
    console.log("🚀 ~ idCategory:", idCategory);
    try {
      const response = await deleteCategory(idCategory);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: initialStateCategory,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCategoryAsync.fulfilled, (state, action) => {
      state.category = [...state.category, action.payload];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createCategoryAsync.rejected, (state, action) => {
      state.category = null;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getAllCategoryTreeAsync.fulfilled, (state, action) => {
      state.category = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllCategoryTreeAsync.rejected, (state, action) => {
      state.category = null;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getAllParentCategoryAsync.fulfilled, (state, action) => {
      state.parents = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllParentCategoryAsync.rejected, (state, action) => {
      state.parents = null;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSubCategoryAsync.fulfilled, (state, action) => {
      state.subCategory = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSubCategoryAsync.rejected, (state, action) => {
      state.subCategory = null;
      state.loading = false;
      state.error = action.payload;
    });
  
    builder.addCase(updateCategoryAsync.fulfilled, (state, action) => {
      state.category = [...state.category, action.payload];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateCategoryAsync.rejected, (state, action) => {
      state.category = null;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteCategoryAsync.fulfilled, (state, action) => {
      state.category = state.category.filter(
        (category) => category._id !== action.payload?._id
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteCategoryAsync.rejected, (state, action) => {
      // state.category = null;
      state.loading = false;
      state.error = action.payload;
    });
  },
});
export const categoryReducer = categorySlice.reducer;
const { actions } = categorySlice;
