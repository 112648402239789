import axiosInstance from "../../api/axios";
const GET_ALL_SUBSCRIPTIONS = `${process.env.REACT_APP_BACK_URL}/api/v1/subscription/`;
const GET_SUBSCRIPTIONS_BY_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/subscription`;
const CREATE_SUBSCRIPTION = `${process.env.REACT_APP_BACK_URL}/api/v1/subscription/createSubscription`;
const UPDATE_SUBSCRIPTION = `${process.env.REACT_APP_BACK_URL}/api/v1/subscription/updateSubscription`;
const DELETE_SUBSCRIPTION = `${process.env.REACT_APP_BACK_URL}/api/v1/subscription/deleteSubscription`;

export const getAllSubscriptions = async (page, limit) => {
  try {
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);

    const url = `${GET_ALL_SUBSCRIPTIONS}?${queryParams.toString()}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSubscriptionsByAdmin = async (page, limit, adminId) => {
  try {
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);

    const url = `${GET_ALL_SUBSCRIPTIONS}/${adminId}?${queryParams.toString()}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createSubscription = async (data) => {
  try {
    const response = await axiosInstance.post(`${CREATE_SUBSCRIPTION}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const updateSubscription = async ({subscriptionId, data}) => {
  try {
    const response = await axiosInstance.put(`${UPDATE_SUBSCRIPTION}/${subscriptionId}`, data);
    return response;
  } catch (error) {
      throw error;
  }
};

export const deleteSubscription = async (subscriptionId) => {
  try {
    const response = await axiosInstance.delete(
      `${DELETE_SUBSCRIPTION}/${subscriptionId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
