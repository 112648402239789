import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useState } from "react";
import PacksForm from "./PacksForm";
import PacksPageContainer from "./PacksPageContainer";
import { Col, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";

const PackPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handelNavigateToForm = () => {
    setIsOpen(true);
  };


  return (
    <>
      <PacksForm
      isOpen = {isOpen}
      setIsOpen = {setIsOpen}
      />
      <div className="page-body">
        <Breadcrumbs />
        <Row
            className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
            style={{ marginTop: "-48px", marginBottom: "20px" }}
          >
            <Col xl="12" sm="12" className="d-flex align-items-end justify-content-end">
                <Btn
                  color="btn btn-primary mb-2"
                  onClick={() => handelNavigateToForm()}
                >
                  Add New Pack
                </Btn>
            </Col>
          </Row>
        <PacksPageContainer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        />
      </div>
    </>
    
  );
};
export default PackPage;