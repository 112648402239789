import axiosInstance from "../../api/axios";

const GET_ALL_ESTABLISHMENTS = `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/`;
const CREATE_ESTABLISHMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/add`
const CREATE_ESTABLISHMENT_WITH_ROLE= `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/createEtablissementWithRole`;
const UPDATE_ESTABLISHMENT =`${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/update`;
const DELETE_ESTABLISHMENT = (idEstablishment) => 
  `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/delete/${idEstablishment}`;

// export const getAllEstablishments = async () => {
//   try{
//     const response = await axiosInstance.get(GET_ALL_ESTABLISHMENTS);
//     console.log("🚀 ~ getAllEstablishments ~ response:", response)
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export const getAllEstablishments = async ({ page= null , limit= null ,name= "" }) => {
  try{
    const queryParams = new URLSearchParams();
    if (page) queryParams.append('page', page);
    if (limit) queryParams.append('limit', limit);
    if (name) queryParams.append('name', name);
    queryParams.append('onModel', 'Establishment');

    const queryString = queryParams.toString();
    const url = queryString ? `${GET_ALL_ESTABLISHMENTS}?${queryString}` : GET_ALL_ESTABLISHMENTS;

    const response = await axiosInstance.get(url);
    console.log("🚀 ~ getAllEstablishments ~ response:", response)
    return response;
  } catch (error) {
    throw error;
  }
}
export const createEstablishment = async (data) => {
  try {
    const response = await axiosInstance.post(CREATE_ESTABLISHMENT,data);
    return response;
  } catch (error) {
    throw error;
  }
}

  export const createEstablishmentWithRole = async (data) => {
    console.log("🚀 ~ createEstablishmentWithRole ~ data:", data)
    try {
      const response = await axiosInstance.post(CREATE_ESTABLISHMENT_WITH_ROLE,data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const deleteEstablishment = async (idEtablissement) => {
    try{
      const response = await axiosInstance.delete(DELETE_ESTABLISHMENT(idEtablissement));
      console.log("🚀 ~ deleteEstablishment ~ response:", response)
      return response;
    } catch (error) {
      throw error;
    }
}

export const updateEstablishment = async (id, data) => {
  if (data instanceof FormData) {
    console.log("🚀 ~ updateEstablishment ~ FormData:", FormData)
    for (let pair of data.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  } else {
    console.log("🚀 ~ updateEstablishmentData ~ data:", data);
  }
  try {
    const response = await axiosInstance.put(
      `${UPDATE_ESTABLISHMENT}/${id}`,
      data
    );
    console.log("🚀 ~ updateEstablishment ~ response:", response);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// //get establishmentById
// export const getEstablishment = async (establishmentId) => {
//   try {
//     const response = await axiosInstance.get(
//       `${GET_ESTABLISHMENT}/${establishmentId}`
//     );
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
