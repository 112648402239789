import { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Input } from "reactstrap";
import { Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import SimpleInput from "../../Components/Inputs/simpleInput";
import { getAdminAuthAsync } from "../../redux-toolkit/slices/authSlice";
import { updateAdminAsync } from "../../redux-toolkit/slices/adminSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const [updatePassword, setUpdatePassword] = useState(false);
  const [toEdit, setToEdit] = useState(false);

  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const adminId = localStorage.getItem("admin");
  useEffect(() => {
    const fetchAdminData = async () => {
      if (!userAdmin) {
        const admin = await dispatch(getAdminAuthAsync(adminId));
        if (getAdminAuthAsync.rejected.match(admin)) {
          console.log("Error:", admin.payload.message);
        }
      }
    };
    fetchAdminData();
  }, [userAdmin, adminId, dispatch]);

  const passwordSchema = yup
    .string()
    .test(
      "password-match",
      "if you want update your password this field must contain at least one uppercase letter, one number, one special character and be at least 3 characters long",
      function (value) {
        if (!value) return true; // Allow empty string
        else {
          setUpdatePassword(true);
          return /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{3,}$/.test(
            value
          );
        }
      }
    );
  const repeatPasswordSchema = yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match");
  const schema = yup.object().shape({
    firstname: yup.string().required("You must enter the first name"),
    lastname: yup.string().required("You must enter the last name"),
    email: yup
      .string()
      .email("Invalid email")
      .required("You must enter the email"),
    phone: yup.string().required("You must enter the phone number"),
    username: yup.string().required("You must enter the username"),
    status: yup.string().required("You must enter the status"),
    newPassword: passwordSchema,
    confirmNewPassword: repeatPasswordSchema,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (userAdmin) {
      reset({
        firstname: userAdmin.firstName || "",
        lastname: userAdmin.lastName || "",
        email: userAdmin.email || "",
        phone: userAdmin.phone || "",
        username: userAdmin.username || "",
        status: userAdmin.status || "",
      });
    }
  }, [userAdmin, reset]);

  const handleBtnEdit = () => {
    setToEdit(true);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("firstName", data?.firstname);
    formData.append("lastName", data?.lastname);
    formData.append("email", data?.email);
    formData.append("phone", data?.phone);
    formData.append("username", data?.username);
    if (data?.newPassword !== "") {
      formData.append("password", data?.newPassword);
    }
    formData.append("status", data?.status);

    try {
      const updatedAdmin = await dispatch(
        updateAdminAsync({
          adminId: userAdmin?._id,
          adminData: formData,
        })
      );
      if (updateAdminAsync.fulfilled.match(updatedAdmin)) {
        setToEdit(false);
        toast.success("Profile updated successfully");
      } else {
        toast.error("Edit Profile Error!");
      }
    } catch (error) {
      toast.error("An error occurred while updating the profile", error);
    }
  };

  return (
    <div className="page-body">
      <Breadcrumbs />
      <Col xl="12">
        <form className="card">
          <CardBody>
            <Row className="w-100">
              <Col xl="12">
                <Row>
                  <Col md="6">
                    <Label>Firstname</Label>
                    <Controller
                      name="firstname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled={!toEdit}
                          style={{
                            backgroundColor: toEdit ? "white" : "#FAFAFA",
                          }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Firstname"
                          errors={errors.firstname?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Lastname</Label>
                    <Controller
                      name="lastname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled={!toEdit}
                          style={{
                            backgroundColor: toEdit ? "white" : "#FAFAFA",
                          }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Lastname"
                          errors={errors.lastname?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                    <Label>Email</Label>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          style={{
                            backgroundColor: toEdit ? "white" : "#FAFAFA",
                          }}
                          disabled={!toEdit}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Email"
                          errors={errors.email?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Username</Label>
                    <Controller
                      name="username"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled={!toEdit}
                          style={{
                            backgroundColor: toEdit ? "white" : "#FAFAFA",
                          }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Username"
                          errors={errors.username?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                    <Label>Phone number</Label>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled={!toEdit}
                          style={{
                            backgroundColor: toEdit ? "white" : "#FAFAFA",
                          }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Phone"
                          errors={errors.phone?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Status</Label>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled
                          style={{ backgroundColor: "#FAFAFA" }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Status"
                          errors={errors.status?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>

                {toEdit && (
                  <Row style={{ paddingTop: "10px" }}>
                    <Col md="6">
                      <Label>Old password</Label>
                      <Controller
                        name="oldPassword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="password"
                            placeholder="Old Password"
                          />
                        )}
                      />
                      <span className="error-msg-input">
                        {errors.oldPassword?.message}
                      </span>
                    </Col>
                    <Col md="6">
                      <Label>New Password</Label>
                      <Controller
                        name="newPassword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="password"
                            placeholder="New Password"
                          />
                        )}
                      />
                      <span className="error-msg-input">
                        {errors.newPassword?.message}
                      </span>
                    </Col>
                  </Row>
                )}
                {toEdit && (
                  <Row style={{ paddingTop: "10px" }}>
                    <Col md="6">
                      <Label>Confirm New Password</Label>
                      <Controller
                        name="confirmNewPassword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="password"
                            placeholder="Confirm New Password"
                          />
                        )}
                      />
                      <span className="error-msg-input">
                        {errors.confirmNewPassword?.message}
                      </span>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            {toEdit ? (
              <Button onClick={handleSubmit(onSubmit)} color="secondary">
                Save
              </Button>
            ) : (
              <Button onClick={handleBtnEdit} color="secondary">
                Edit
              </Button>
            )}
          </CardFooter>
        </form>
      </Col>
    </div>
  );
};

export default Profile;
