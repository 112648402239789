import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProducts,
  addNewProduct,
  updateProduct,
  deleteProduct,
} from "../services/productService";

const initialState = {
  products: [],
  loading: false,
  error: null,
};

export const fetchProducts = createAsyncThunk(
  "products/fetchData",
  async ( service ) => {
    try {
      const response = await getProducts(service);
      console.log("rrrrrrrrrrrrrrrrrrrr", response);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (productData) => {
    console.log("🚀 ~ file: serviceSlice.jsx:45 ~ serviceData:", productData);
    const response = await addNewProduct(productData);
    try {
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateProductAsync = createAsyncThunk(
  "products/updateproduct",
  async ({ productId, productData }) => {
    console.log("🚀 ~ file: serviceSlice.jsx:55 ~ serviceData:", productData);
    try {
      const response = await updateProduct(productId, productData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteProductAsync = createAsyncThunk(
  "services/deleteProduct",
  async (productId) => {
    try {
      const response = await deleteProduct(productId);
      console.log("🚀 ~ file: productSlice.jsx:38 ~ response:", response);
      return response.data;
    } catch (error) {
      console.log("🚀 ~ file: productSlice.jsx:40 ~ error:", error);
      throw error;
    }
  }
);
const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.products = [...state.products, action.payload.data];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateProductAsync.fulfilled, (state, action) => {
      state.products = [...state.products, action.payload.data];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateProductAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteProductAsync.rejected, (state, action) => {
      console.log("action rejected", action);
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteProductAsync.fulfilled, (state, action) => {
      console.log("done");
      console.log("producccts",state.products)
      state.products = state.products.filter(
        (product) => product._id !== action.payload?.data?._id
      );
      state.loading = false;
      state.error = null;
    });
  },
});

export const productsReducer = productSlice.reducer;
export const { actions } = productsReducer;
