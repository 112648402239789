import React, { useEffect } from "react";
import { Col, CardBody, Row, Label, CardFooter, Input } from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import { addDepartement, getDepartmentByIdAsync } from "../../../../redux-toolkit/slices/departementSlice";

const Index = () => {
  const dispatch = useDispatch();
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const schema = yup.object().shape({
    name: yup.string().required("You must enter a first name"),
  });
  const routeParams = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const departmentData = useSelector((state) => state.departement.departementId);
  const affectationSelectedData = useSelector((state) => state.auth.affectationSelected);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const { control, handleSubmit, reset, getValues, formState: { isValid, dirtyFields, errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      workingHours: daysOfWeek.map(day => ({
        day,
        openTime: "08:00:00",
        closeTime: "22:30:00",
        isOpen24Hours: false,
      }))
    }
  });

  const { fields, append, remove} = useFieldArray({
    control,
    name: "workingHours",
  });

  useEffect(() => {
    if (routeParams?.departmentId) {
      dispatch(getDepartmentByIdAsync(routeParams?.departmentId));
    }
  }, [dispatch, routeParams]);

  useEffect(() => {
    if (routeParams?.departmentId && departmentData) {
      reset({
        name: departmentData.name,
        workingHours: departmentData.workingHours,
      });
    }
  }, [departmentData, routeParams]);

  const pathname = location.pathname.split("/");

  
  async function onSubmit(data) {
    const formData = {
      ...data,
      establishment: affectationSelectedData?.establishments._id,
    };
    await dispatch(addDepartement(formData)).then((res) => {
      if (pathname.includes("edit")){
        toast.success("Department was updated successfully!");
      }
      else if (pathname.includes("new")){
        toast.success("Department was added successfully!");
      }
      navigate("/services-manager/department");
    });
  }

  return (
    <div className="page-body">
      <Breadcrumbs />
      <Col xl="12">
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <Row>
              <Col md="12">
                <Label>Department Name</Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input" // change to lowercase
                      className="form-control"
                      placeholder="Name"
                      errors={errors.name?.message}
                    />
                  )}
                />
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <Row>
              <Col md="3">
                <Label>Day</Label>
              </Col>
              <Col md="3">
                <Label>Open time</Label>
              </Col>
              <Col md="3">
                <Label>Close time</Label>
              </Col>
              <Col md="3">
                <Label>24H open</Label>
              </Col>
            </Row>

            {fields.map((item, index) => (
              <Row key={item.id}>
                <Col md="3">
                  <SimpleInput
                    value={item.day}
                    className="form-control"
                    disabled
                  />
                </Col>
                <Col md="3">
                  <Controller
                    name={`workingHours[${index}].openTime`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="digits"
                        type="time"
                      />
                    )}
                  />
                </Col>
                <Col md="3">
                  <Controller
                    name={`workingHours[${index}].closeTime`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="digits"
                        type="time"
                      />
                    )}
                  />
                </Col>
                <Col md="3">
                  {/* <div>24H open</div> */}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "start",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="icon-state switch-outline">
                      <Controller
                        name={`workingHours[${index}].isOpen24Hours`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Label
                            className="switch"
                            style={{ marginBottom: "0px", marginTop: "10px" }}
                          >
                            <Input type="checkbox" {...field} checked={getValues(`workingHours[${index}].isOpen24Hours`)} />
                            <span className="switch-state bg-primary"></span>
                          </Label>
                        )}
                      />
                    </div>
                  </div>
                  {/* <div style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "flex-end" }}>
                    <input
                      type="checkbox"
                      {...control[`workingHours[${index}].isOpen24Hours`]}
                    />
                  </div> */}
                </Col>
              </Row>
            ))}
          </CardBody>

          <CardFooter className="text-end">
            <Button type="submit" color="secondary">
              Save
            </Button>
          </CardFooter>
        </form>
      </Col>
    </div>
  );
};

export default Index;
