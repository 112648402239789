import React, { useState } from "react";
import { Accordion, Col } from "react-bootstrap";
import { Card, CardBody, Collapse } from "reactstrap";
import DynamicAccordion from "./dynamicAccordion";
import HeadingCommon from "../../../../Common/HeadingCommon";
import moment from "moment";
import "moment/locale/fr";

const PrimaryColorAccordion = ({ data }) => {
  const [isOpen, setIsOpen] = useState(null);

  const toggle = (id) => {
    setIsOpen((prevOpen) => (prevOpen === id ? null : id));
  };

  return (
    <Col sm="12" xl="12">
      <Accordion defaultActiveKey="0">
        <Card>
          <CardBody>
            <div className="default-according" id="accordion1">
              {data.map((item, index) => (
                <Card key={index}>
                  <HeadingCommon
                    className="bg-primary"
                    H5ClassName="mb-0"
                    Btn={true}
                    span={true}
                    BtnclassName="btn-link txt-white"
                    BtnColor="default"
                    BtnOnClick={() => toggle(index + 1)}
                    BtnText={item.name}
                    BtnSpanClass="digits"
                    //   BtnSpanText={`#${index + 1}`}
                  />
                  <Collapse isOpen={isOpen === index + 1}>
                    <CardBody>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h6>Description :</h6>
                        <p>{item.description}</p>
                        <h6>Created at :</h6>
                        <p>
                          {moment(item.createdAt).locale("fr").format("LLL")}{" "}
                        </p>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              ))}
            </div>
          </CardBody>
        </Card>
      </Accordion>
    </Col>
  );
};

export default PrimaryColorAccordion;
