import { useNavigate } from "react-router-dom";
import { Btn, H4, H6 } from "../AbstractElements";
import { useState, useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { Eye, EyeOff } from "react-feather";
import {
  Login,
  LoginDetails,
  Password,
  RememberMe,
} from "../Constant";
import SocialIcons from "./SocialIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  signInAuthAsync,
} from "../redux-toolkit/slices/authSlice";
  const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const SimpleLoginHandle = async (event) => {
    event.preventDefault();
    if (username && password) {
      const data = {
        username,
        password,
      };
      try {
        const login = await dispatch(signInAuthAsync(data));
        if (signInAuthAsync.fulfilled.match(login)) {
            toast.success("Login Success...!");
            navigate("/dashboard");
        }

        if (signInAuthAsync.rejected.match(login)) {
          const errorResponse = login.error || {};
          const errorMessage =
            errorResponse.message || "An error occurred during login.";
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    } else {
      toast.error("Please Enter valid username or password...!");
    }
  };

  return (
    <div>
      <form onSubmit={SimpleLoginHandle} className="theme-form">
        <H4 className="text-uppercase">{Login}</H4>
        <H6>{LoginDetails}</H6>
        <FormGroup>
          <Label className="pt-0">Username</Label>
          <Input
            type="text"
            defaultValue={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <div className="password-wrapper" style={{ position: "relative" }}>
            <Input
              type={showPassword ? "text" : "password"}
              defaultValue={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <div
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                padding: "5px",
              }}
            >
              {showPassword ? (
                <EyeOff
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ width: "20px", color: " #655af3" }}
                />
              ) : (
                <Eye
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ width: "20px", color: " #655af3" }}
                />
              )}
            </div>
          </div>
        </FormGroup>
        <div className="checkbox p-0">
          <Input id="checkbox1" type="checkbox" />
          <Label htmlFor="checkbox1">{RememberMe}</Label>
        </div>
        <FormGroup className="row g-2 mt-3 mb-0">
          <Btn color="primary" className="d-block w-100" type="submit">
            {Login}
          </Btn>
        </FormGroup>
        <SocialIcons />
      </form>
      {/* )} */}
    </div>
  );
};

export default LoginForm;
