import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Col,
  Row,
  Label,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import SimpleInput from "../../Inputs/simpleInput";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Permissions from "./permissions";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewRole,
  getAllRolesByEstablishmentIdAsync,
  updateOneRole,
} from "../../../redux-toolkit/slices/roleSlice";
import { toast } from "react-toastify";
import {
  addNewPermissions,
  updatePermissionsData,
} from "../../../redux-toolkit/slices/permissionSlice";
// import { STATIC_PERMISSIONS } from "./utlis";

const AddRole = ({ dataRow, setDataRow, open, handleClose }) => {
  const STATIC_PERMISSIONS = [
    {
      read: true,
      execute: true,
      update: true,
      delete: true,
      name: "dashboard",
    },
    {
      read: true,
      execute: true,
      update: true,
      delete: true,
      name: "profile",
    },
    {
      read: false,
      execute: false,
      update: false,
      delete: false,
      name: "settings",
    },
    {
      read: false,
      execute: false,
      update: false,
      delete: false,
      name: "service manager",
    },
    {
      read: false,
      execute: false,
      update: false,
      delete: false,
      name: "employees",
    },
    {
      read: false,
      execute: false,
      update: false,
      delete: false,
      name: "clients",
    },
    {
      read: false,
      execute: false,
      update: false,
      delete: false,
      name: "room",
    },
    {
      read: false,
      execute: false,
      update: false,
      delete: false,
      name: "orders",
    },
  ];

  const [permissions, setPermissions] = useState(STATIC_PERMISSIONS);
  const [permissionsShow, setPermissionsShow] = useState(false);

  const dispatch = useDispatch();
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const schema = yup.object().shape({
    name: yup.string().required("You must enter a name"),
  });

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { isValid, dirtyFields, errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    console.log("data is ===>", data);
    console.log("permissions is ===>", permissions);
    //lena el update
    const newRoleData = {
      ...data,
      isRoot: false,
      establishment: affectationSelectedData?.establishments._id,
    };
    if (dataRow) {
      // Update existing role and permissions
      const roleUpdated = await dispatch(
        updateOneRole({ id: dataRow._id, data: data })
      );
      if (updateOneRole.fulfilled.match(roleUpdated)) {
        toast.success("Role updated successfully!");
        console.log("permission", permissions);
        const permissionData = permissions.map((item) => ({
          ...item,
          role: dataRow._id,
        }));
        console.log("🚀 ~ permissionData ~ permissionData:", permissionData);

        const permissionsUpdated = await dispatch(
          updatePermissionsData(permissionData)
        );
        if (updatePermissionsData.fulfilled.match(permissionsUpdated)) {
          toast.success("Permissions updated successfully!");
          await dispatch(
            getAllRolesByEstablishmentIdAsync({
              establishementId: affectationSelectedData?.establishments._id,
              page: 1,
              limit: 10,
            })
          );
          handleClose();
          reset();
          setPermissions(STATIC_PERMISSIONS);
        } else {
          toast.error("Error updating Permissions!");
        }
      } else {
        toast.error("Error updating Role!");
      }
    } else {
      const roleCreated = await dispatch(addNewRole(newRoleData));
      if (addNewRole.fulfilled.match(roleCreated)) {
        toast.success("Role was add Success...!");
        console.log("roleCreated", roleCreated);
        const idRoleCreated = roleCreated.payload.data._id;
        const permissionData = permissions.map((item) => ({
          ...item,
          role: idRoleCreated,
        }));
        const PermissionsCreated = await dispatch(
          addNewPermissions(permissionData)
        );
        if (addNewPermissions.fulfilled.match(PermissionsCreated)) {
          toast.success("permissions was add Success...!");
          await dispatch(
            getAllRolesByEstablishmentIdAsync({
              establishementId: affectationSelectedData?.establishments._id,
              page: 1,
              limit: 10,
            })
          );
          handleClose();
          reset();
          setPermissions([]);
        } else {
          toast.error("Fatal error creating Permissions !");
        }
      } else {
        toast.error("Fatal error creating Role !");
      }
    }
  };

  const handleCloseModal = () => {
    handleClose();
    console.log("STATIC_PERMISSIONS", STATIC_PERMISSIONS);
    setPermissions(STATIC_PERMISSIONS);
    setDataRow(null);
  };

  useEffect(() => {
    if (open == true && dataRow) {
      console.log("datarow in permission", dataRow.permissions);
      setValue("name", dataRow.name);
      setPermissions(dataRow.permissions);
      setPermissionsShow(true);
    } else {
      if (open == true && !dataRow) {
        console.log("datarow not inn");
        reset();
        setPermissionsShow(true);
      }
    }
  }, [open, dataRow]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ permissions:", permissions);
  }, [permissions]);

  return (
    <Modal isOpen={open} size="lg" centered={true} toggle={handleCloseModal}>
      <ModalHeader>{dataRow ? "Update Role" : "Add Role"}</ModalHeader>
      <ModalBody>
        <Col xl="12">
          <form>
            <Row>
              <Col xl="6">
                <Label>Role name</Label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input" // change to lowercase
                      className="form-control"
                      placeholder="Name"
                      errors={errors.name?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <Col xl="6">
                <Label>Permissions</Label>
              </Col>
              <Col xl="12">
                {permissionsShow === true && (
                  <Permissions
                    permissions={permissions}
                    setPermissions={setPermissions}
                    dataRow={dataRow}
                  />
                )}
              </Col>
            </Row>
          </form>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Btn color="primary" onClick={handleCloseModal}>
          Close
        </Btn>
        <Button onClick={handleSubmit(onSubmit)} color="secondary">
          {dataRow ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddRole;
