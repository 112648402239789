import { Container, Row} from "reactstrap";
import { Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "sweetalert2";
import {deletePrestationAsync, getAllPrestationsAsync} from "../../../redux-toolkit/slices/prestationSlice";
import TableComp from "../../../Components/dataTable/TableComp";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit, Trash } from "react-feather";
import { dynamicImage } from "../../../Service";
import { useNavigate } from "react-router-dom";

const PrestationPageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prestationsData = useSelector((state)=> state.prestation.prestations);

  useEffect(() => {
      dispatch(getAllPrestationsAsync({ page: 1, limit: 10 }));
  },[dispatch]);

  const handelNavigateEditService = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/affectation-manager/prestations/edit/${rowName}`, { state: row });
  };

  const handelDeletePrestation = async (row) => {
    const prestationId = row?._id;
    if (!prestationId) {
      return;
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this prestation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          const deletedPrestation = await dispatch(deletePrestationAsync(prestationId));
          if (deletePrestationAsync.fulfilled.match(deletedPrestation)) {
            SweetAlert.fire("Deleted",deletedPrestation.payload.data.message,"success");
            dispatch(getAllPrestationsAsync({ page: 1, limit: 10 }));
          } else {
            SweetAlert.fire("Error!",`${deletedPrestation.payload}`,"error");
          }
        } catch (error) {
          SweetAlert.fire("Error!", "Prestation could not be deleted.", "error");
        }
      }
    });
  };

  const PrestationsTableColumns = [
    {
      name: "Image",
      selector: (row) => (
        <div>
          <Image
            src={
              row?.images[0]
                ? process.env.REACT_APP_MINIO_URL + row?.images[0]
                : dynamicImage("ecommerce/options.png")
            }
            style={{ width: "70px", padding: "10px" }}
          />
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      center: true,
    },
    {
      name: "Region",
      selector: (row) => row?.region,
      sortable: true,
      center: true,
    },
    {
      name: "Rate",
      selector: (row) => row?.rate,
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {/* <a
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Trash
              width={20}
              color="red"
              onClick={() => handelDeletePrestation(row)}
            />
          </a> */}
          <button color="link" // Utilisation d'un bouton avec couleur de fond transparente
            style={{  cursor:'not-allowed', border: 'none', padding: 0 }}
            onClick={() => handelDeletePrestation(row)}
            disabled 
            >
              <Trash
                width={20}
                color="red"
              />
          </button>
          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditService(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <Container fluid={true}>
      <Row>
        <TableComp
          data={prestationsData}
          columns={PrestationsTableColumns}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          fixedHeader={true}
          customStyles={customStyles}
        />
      </Row>
    </Container>
  );
};
export default PrestationPageContainer;