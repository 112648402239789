import { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../Components/Inputs/simpleInput";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Btn } from "../../../AbstractElements";
import { getAllPacksAsync } from "../../../redux-toolkit/slices/packSlice";
import { getAllOptionTemplatesAsync } from "../../../redux-toolkit/slices/OptionTemplateSlice";
import { createPackOptionAsync, updatePackOptionAsync } from "../../../redux-toolkit/slices/packOptionSlice";
function PacksOptionsForm(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    let location = useLocation();
    const pathname = location.pathname.split("/");
    const routeParams = useParams();

    const packData = useSelector((state) => state.pack.packs);
    useEffect(() => {
        dispatch(getAllPacksAsync());
    },[dispatch]);

    const optionTemplateData = useSelector((state) => state.optionTemplate.optionsTemplates);
    useEffect(() => {
        dispatch(getAllOptionTemplatesAsync());
    },[dispatch]);

    const schema = yup.object().shape({
        pack: yup.string().transform((value) => value?.value ?? value).required("You must select the pack"),
        optionTemplate: yup.string().transform((value) => value?.value ?? value).required("You must select the optionTemplate"),
        price: yup.number().typeError("You must enter the price"),
      
    });

    const {
      control,
      handleSubmit,
      reset,
      formState: {errors },
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  
   const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("packId", data.pack);
    formData.append("optionTemplateId", data.optionTemplate);
    formData.append("price", data.price);

    if (!pathname.includes("edit")) {
      const optionTemplateAdded = await dispatch(createPackOptionAsync(formData));
      if(createPackOptionAsync.rejected.match(optionTemplateAdded)){
        SweetAlert.fire(
          "Error",
          optionTemplateAdded.payload,
          "error"
        );
      }else{
        SweetAlert.fire(
          "Added!",
          optionTemplateAdded.payload.data.message,
          "success"
        );
        props.setIsOpen(false);
        reset();
      }
          
    }else {
      const packOptionUpdated = await dispatch(updatePackOptionAsync({
        packOptionId: location?.state?._id,
        data: formData,
      }));
      
      if(updatePackOptionAsync.rejected.match(packOptionUpdated)){
        SweetAlert.fire(
          "Error",
          packOptionUpdated.payload,
          "error"
        );
      }else{
        SweetAlert.fire(
          "Updated!",
          packOptionUpdated.payload.data.message,
          "success"
        );
        props.setIsOpen(false);
        reset();
        navigate('/subscription-manager/packOptions');
      }
    }
  }
    useEffect(() => {
      if (location?.state && pathname.includes("edit")) {
          reset({
            pack:{
                label: location?.state?.pack.name,
                value: location?.state?.pack._id,
                },
            optionTemplate:{
                label: location?.state?.optionTemplate.name,
                value: location?.state?.optionTemplate._id,
                },
            price: location?.state?.priceOptionTemplate,
        })
      }
      else{
        reset({
            pack: null,
            optionTemplate: null,
            price: null,
        })
      }
    }, [location]);
  
    return (
      <Modal isOpen={props.isOpen} size="lg" centered={true}>
        <ModalHeader>Create New Pack Option</ModalHeader>
        <ModalBody>
          <div className="page-body">
            <Col xl="12">
              <form className="card">
                <CardBody>
                    <Row>
                      <Col xl="12">
                        <Row>
                            <Col xl="12" className="m-b-15">
                                <Label>Pack</Label>
                                <Controller
                                    name="pack"
                                    control={control}
                                    render={({ field }) => (
                                    <>
                                        <Select
                                        options={packData?.map((p) => ({
                                            label: (
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>{p.name}</span>
                                                    <span style={{ fontSize: '11px', color: 'gray' }}>{p.type}</span>
                                                </div>
                                                ),
                                            value: p._id,
                                        }))}
                                        {...field}
                                        isClearable={true}
                                        isDisabled={pathname.includes("edit")}
                                        onChange={(selected) => field.onChange(selected)}
                                        />
                                        {errors.pack && <span style={{color:"red"}}>{errors.pack.message}</span>}
                                    </>
                                    )}
                                />
                            </Col>
                            <Col xl="12" className="m-b-15">
                                <Label>Option Template</Label>
                                <Controller
                                    name="optionTemplate"
                                    control={control}
                                    render={({ field }) => (
                                    <>
                                        <Select
                                        options={optionTemplateData?.map((o) => ({
                                            label: (
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>{o.customName}</span>
                                                <span style={{ fontSize: '11px', color: 'gray' }}>{o.type}</span>
                                            </div>
                                            ),
                                            value: o._id,
                                        }))}
                                        {...field}
                                        isClearable={true}
                                        isDisabled={pathname.includes("edit")}
                                        onChange={(selected) => field.onChange(selected)}
                                        formatOptionLabel={({ label }) => label}
                                        />
                                        {errors.optionTemplate && <span style={{color:"red"}}>{errors.optionTemplate.message}</span>}
                                    </>
                                    )}
                                />
                                </Col>

                        </Row>
                        <Row>
                          <Col lg="12" className="m-b-15">
                            <Label>Price</Label>
                            <Controller
                              name="price"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="$"
                                  {...field}
                                />
                              )}
                            />
                            <span className="error-msg-input">{errors.price?.message}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                </CardBody>
              </form>
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Btn
            color="primary"
            onClick={() => {
              reset();
              props.setIsOpen(false);
              navigate(`/subscription-manager/packOptions`);
            }}
          >
            Close
          </Btn>
          <Button onClick={handleSubmit(onSubmit)} color="secondary">
            Save
        </Button>
        </ModalFooter>
      </Modal>
    );
}
export default PacksOptionsForm