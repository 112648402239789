import React, { useState, useEffect } from "react";
import TableComp from "../../../Components/dataTable/TableComp";
import { Eye, Edit } from "react-feather";
import { Btn, Image } from "../../../AbstractElements";
import DepartmentView from "./DepartementView";
import { dynamicImage } from "../../../Service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteDepartmentAsync,
  fetchDepartment,
} from "../../../redux-toolkit/slices/departementSlice";
import { toast } from "react-toastify";

const DepartmentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const departmentData = useSelector((state) => state.departement.departement);
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelectedData?.establishments._id) {
        await dispatch(fetchDepartment(affectationSelectedData?.establishments._id));
      }
    };
    fetchData();
  }, [dispatch,affectationSelectedData]);

  const categoryTableColumns = [
    // {
    //   name: "Image",
    //   selector: (row) => (
    //     <Image
    //       src={dynamicImage("ecommerce/options.png")}
    //       className="w-50 m-l-10"
    //     />
    //   ),
    //   sortable: true,
    //   center: true,
    // },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Services",
      selector: (row) => row?.services?.length,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>
          <a
            className="btn-xs"
            onClick={() => handelNavigateToFormEdit(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const ExpandedComponent = ({ data }) => {
    return (
      <div style={{ backgroundColor: "red", width: "75%" }}>
        <div>{data?.name}</div>
      </div>
    );
  };
  const handelOpenToView = (category) => {
    setSelectedCategory(category);
    setIsOpen(true);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handelDeleteDepartment = async () => {
    try {
      const response = await dispatch(
        deleteDepartmentAsync(selectedRows?.[0]?._id)
      );
      if(deleteDepartmentAsync.rejected.match(response)) { 
        toast.error(response.payload); 
      }else{
        toast.success("Department deleted successfully!");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the Department.");
    }
  };

  const handelNavigateToForm = () => {
    navigate("/services-manager/department/new");
  };
  const handelNavigateToFormEdit = (row) => {
    console.log("rowwwwwwwww",row);
    navigate(`/services-manager/department/${row?._id}/edit`);
  };

  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const handelNavigateToSubCategory = (row) => {
    const rowName = row.name.replace(/ /g, "-");
    navigate(`/services-manager/category/${rowName}`, { state: row });
  };
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
        <Btn
          color="danger mb-2 m-r-15"
          onClick={handelDeleteDepartment}
          disabled={
            !selectedRows.some((e) =>
              departmentData.some((item) => item._id === e._id)
            )
          }
        >
          Delete
        </Btn>
        <Btn
          color="btn btn-primary mb-2"
          onClick={() => handelNavigateToForm()}
        >
          Add new department
        </Btn>
      </div>
      <TableComp
        data={departmentData}
        columns={categoryTableColumns}
        expandableRowsComponent={ExpandedComponent}
        handleRowSelected={handleRowSelected}
        toggleDelete={false}
        expandableRows={false}
        highlightOnHover={true}
        fixedHeader={true}
        customStyles={customStyles}
        // onRowClicked={(row) => handelNavigateToSubCategory(row)}
      />
      <DepartmentView
        isOpen={isOpen}
        toggleClose={() => setIsOpen(false)}
        data={selectedCategory}
      />
    </div>
  );
};

export default DepartmentList;
