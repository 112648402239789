import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { updateAdminPasswordAsync } from "../../../redux-toolkit/slices/adminSlice";
import SweetAlert from "sweetalert2";

const UpdatePasswordForm = (props) =>{
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const passwordSchema = yup.object().shape({
    oldPassword: yup.string().required("You must enter the old password"),
    newPassword: yup.string().required("You must enter the new password")
      .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{3,}$/, 'New password must contain at least one uppercase letter, one number, one special character and be at least 3 characters long'),
    confirmPassword: yup.string().required("You must confirm the new password")
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  });
  
  
    const {
      control,
      handleSubmit,
      reset,
      formState: {errors },
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(passwordSchema),
    });
  
    async function onSubmit(data) {
      const formDataa = new FormData();
        formDataa.append("oldPassword", data?.oldPassword);
        formDataa.append("newPassword", data?.newPassword);
        formDataa.append("confirmPassword", data?.confirmPassword);
        try {
          const adminUpdated = await dispatch(updateAdminPasswordAsync({
            adminId: routeParams?.id,
            updatedAdminPasswordData: formDataa
          }));
          if(updateAdminPasswordAsync.rejected.match(adminUpdated)){
            SweetAlert.fire(
              "Conflict",
              adminUpdated.payload,
              "error"
            );
          }else{
            SweetAlert.fire(
              "success",
              adminUpdated.payload.message,
              "success"
            );
            props.setIsOpen2(false);
            navigate(`/affectation-manager/admins`);
            reset();
          }
        } catch (error) {
          console.log("An error occurred while updating the admin password", error);
        }
    }

  return (
    <Modal isOpen={props.isOpen2} size="lg" centered={true}>
            <ModalHeader>Create new affectation</ModalHeader>
            <ModalBody>
                <div className="page-body">
                        <Col lg="12" className="m-b-15">
                          <Label>Old Password</Label>
                          <Controller
                              name="oldPassword"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                              <input
                                  className="form-control"
                                  type="password"
                                  placeholder="********"
                                  {...field}
                              />
                              )}
                          />
                          <span className="error-msg-input">
                              {errors.oldPassword?.message}
                          </span>
                        </Col>
                        <Col lg="12" className="m-b-15">
                          <Label>New password</Label>
                          <Controller
                              name="newPassword"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                              <input
                                  className="form-control"
                                  type="password"
                                  placeholder="********"
                                  {...field}
                              />
                              )}
                          />
                          <span className="error-msg-input">
                              {errors.newPassword?.message}
                          </span>
                        </Col>
                        <Col lg="12" className="m-b-15">
                          <Label>Confirm New password</Label>
                          <Controller
                              name="confirmPassword"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                              <input
                                  className="form-control"
                                  type="password"
                                  placeholder="********"
                                  {...field}
                              />
                              )}
                          />
                          <span className="error-msg-input">
                              {errors.confirmPassword?.message}
                          </span>
                        </Col>

                </div>
            </ModalBody>
            <ModalFooter>
            <Btn
              color="primary"
              onClick={() => {
                reset();
                props.setIsOpen2(false);
                navigate(`/affectation-manager/admins`);
              }}
            >
              Close
            </Btn>
            <Button onClick={handleSubmit(onSubmit)} color="secondary">
              Save Password
            </Button>
        </ModalFooter>
    </Modal>
  )
}

export default UpdatePasswordForm