import { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import {useNavigate,useLocation} from "react-router-dom";
import { getAllAdminsAsync } from "../../../redux-toolkit/slices/adminSlice";
import { createAffectationAdminAsync,updateAffectationAdminAsync } from "../../../redux-toolkit/slices/affectationAdminSlice";
import { getAllRolesByEstablishmentIdAsync } from "../../../redux-toolkit/slices/roleSlice";
import { getAllEstablishmentsAsync } from "../../../redux-toolkit/slices/establishmentSlice";
import { getAllPrestationsAsync } from "../../../redux-toolkit/slices/prestationSlice";
import { Btn } from "../../../AbstractElements";
function AffectationsAdminsForm(props) {
    const dispatch = useDispatch();
    let location = useLocation();
    const navigate = useNavigate();
    const pathname = location.pathname.split("/");

    const establishmentsData = useSelector((state)=> state.establishment.establishments);
    useEffect(() => {
      // if(establishmentsData.length === 0)
        dispatch(getAllEstablishmentsAsync());
    },[dispatch]);
    const prestationsData = useSelector((state) => state.prestation.prestations);
    useEffect(() => {
      // if(prestationsData.length === 0)
        dispatch(getAllPrestationsAsync());
    },[dispatch]);
    // Concaténer les données des établissements et des prestations
    const combinedData = [...establishmentsData, ...prestationsData];

    const adminData = useSelector((state) => state.admin.admins);
    useEffect(() => {
      // if (adminData.length === 0)
      dispatch(getAllAdminsAsync());
  },[dispatch]);
  
 
  
  const schema = yup.object().shape({
    admin: yup.string().transform((value) => value?.value ?? value).required("You must select the admin"),
    establishment: yup.string().transform((value) => value?.value ?? value).required("You must select the establishment"),
  });

    const {
      control,
      watch,
      handleSubmit,
      reset,
      formState: {errors },
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
    });
    const roles = useSelector((state) => state.role.roles);
    const [roleOptions, setRoleOptions] = useState([]);
    const selectedEstablishment = watch('establishment');

    useEffect(() => {
      const fetchRoles = async () => {
        if (selectedEstablishment) {
          const establishmentId = selectedEstablishment.value;
          const filteredRoles = roles.filter(role => role.establishment === establishmentId);
  
          if (filteredRoles.length > 0) {
            // If roles are already in state, use them
            const formattedRoles = filteredRoles.map(role => ({ value: role._id, label: role.name }));
            setRoleOptions(formattedRoles);
          } else {
            try {
              const result = await dispatch(getAllRolesByEstablishmentIdAsync({establishmentId})).unwrap();
              const formattedRoles = result.data.map(role => ({ value: role._id, label: role.name }));
              setRoleOptions(formattedRoles);
            } catch (error) {
              SweetAlert.fire("Error", "Error while getting roles ", "error");
            }
          }
        } else {
          setRoleOptions([]); // Clear roles when no establishment is selected
        }
      };
  
      fetchRoles();
    }, [selectedEstablishment, roles, dispatch]);

    const onSubmit = async (data) => {
   // Convert roleIds to an array
   const roleIds = Array.isArray(data.role) ? data.role.map(role => role.value) : [data.role.value];
    const formData = {
      adminId:data.admin,
      establishmentId:data.establishment,
      roleIds
    }
      if (!pathname.includes("edit")) {
        try {
          const affectationAdminAdded = await dispatch(createAffectationAdminAsync(formData)); 
    
          if (createAffectationAdminAsync.rejected.match(affectationAdminAdded)) {
            SweetAlert.fire("Error", affectationAdminAdded.payload?.error, "error");
          } else {
            SweetAlert.fire("Added!",affectationAdminAdded.payload.message , "success");
            reset(); 
            props.setIsOpen(false);
          }
        } catch (error) {
          SweetAlert.fire("Error", "Failed to add affectation.", "error");
        }
      } else {
        try {
          const affectationAdminUpdated = await dispatch(updateAffectationAdminAsync({
            affectationId: location?.state?._id,
            affectationAdminData: formData,
          }));
          
          if (updateAffectationAdminAsync.rejected.match(affectationAdminUpdated)) {
            SweetAlert.fire("Error", affectationAdminUpdated.payload?.error, "error");
          } else {
            SweetAlert.fire("Updated!", affectationAdminUpdated.payload.message, "success");
            props.setIsOpen(false);
            navigate(`/affectation-manager/affectationsAdmins`);
          }
        } catch (error) {
          SweetAlert.fire("Error", "Failed to update affectation.", "error");
        }
      }
    };
    
  useEffect(() => {
    if (location?.state && pathname.includes("edit")) {
      reset({
        admin: {
          label: location?.state?.admin?.email,
          value: location?.state?.admin?._id,
        },
        establishment: {
          label: location?.state?.establishments?.name,
          value: location?.state?.establishments?._id,
        },
        role: location?.state?.role?.map(role => ({
          label: role.name,
          value: role._id,
        })),
      });
    }
    else {
      reset({
        admin: null,
        establishment: null,
        role: [],
      })
    }
  }, [location]);
  
  
  return (
    <Modal isOpen={props.isOpen} size="lg" centered={true}>
      <ModalHeader>Create new affectation</ModalHeader>
      <ModalBody>
        <div className="page-body">
          <Col xl="12">
            <form className="card">
              <CardBody>
                  <Row>
                      <Col xl="12" className="m-b-15">
                        <Label>Admin</Label>
                        <Controller
                        name="admin"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                            options={adminData?.map((t) => ({
                                label: t.email,
                                value: t._id,
                            }))}
                            {...field}
                            isClearable={true}
                            isDisabled={pathname.includes("edit")}
                            onChange={(selected) => field.onChange(selected)}
                            />
                            {errors.admin && <span style={{color:"red"}}>{errors.admin.message}</span>}
                          </>
                        )}
                        />
                      </Col>
                      <Col xl="12" className="m-b-15">
                        <Label>Establishment</Label>
                        <Controller
                        name="establishment"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                            options={combinedData?.map((t) => ({
                                label: t.name,
                                value: t._id,
                            }))}
                            {...field}
                            isClearable={true}
                            isDisabled={pathname.includes("edit")}
                            onChange={(selected) => field.onChange(selected)}
                            />
                            {errors.establishment && <span style={{color:"red"}}>{errors.establishment.message}</span>}
                            </>
                        )}
                        />
                      </Col>
                      <Col xl="12" className="m-b-15">
                        <Label>Role</Label>
                        <Controller
                        name="role"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                            options={roleOptions}
                            placeholder="Select Role"
                            isDisabled={!selectedEstablishment}
                            {...field}
                            isMulti
                            isClearable={true}
                            onChange={(selected) => field.onChange(selected)}
                            />
                            {errors.role && <span style={{color:"red"}}>{errors.role.message}</span>}
                            </>
                        )}
                        />
                      </Col>
                  </Row>
              </CardBody>
            </form>
          </Col>
        </div>
    </ModalBody>
    <ModalFooter>
        <Btn
          color="primary"
          onClick={() => {
            reset();
            props.setIsOpen(false);
            navigate(`/affectation-manager/affectationsAdmins`);
          }}
        >
          Close
        </Btn>
        <Button onClick={handleSubmit(onSubmit)} color="secondary">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
export default AffectationsAdminsForm