import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import Tree, { useTreeState, treeHandlers } from "react-hyper-tree";
import { Fragment } from "react";

import HeadingCommon from "../../../../Common/HeadingCommon";
import {
  createCategoryAsync,
  deleteCategoryAsync,
} from "../../../../redux-toolkit/slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { Button } from "reactstrap";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import { Col, CardBody, Card, Row, Label, CardFooter } from "reactstrap";

const EnhanceTree = ({ data }) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [treeData, setTreeData] = useState(null);
  const [updatedTreeData, setUpdatedTreeData] = useState(null);
  const [inputText, setInputText] = useState("");
  const dispatch = useDispatch();

  const deleteNode = async (node) => {
    if (node?.children && node?.children?.length === 0) {
      try {
        const response = await dispatch(deleteCategoryAsync(node.id));

        // if (response?.message) {
        //   toast.error(response.message);
        // } else {
          // Update the data after successful deletion
          // const updatedTree = updateTreeData(node?.id, treeData);
          // console.log("tttttttttt111",updatedTree);
          // setTreeData(updatedTree);
          const updatedTreeData = deleteSelectedItem(treeData, node.id);

           setTreeData(updatedTreeData);
          toast.success("Category deleted successfully!");
        // }
      } catch (error) {
        toast.error("An error occurred while deleting the category.");
      }
    } else {
      toast.error("This category has subcategories");
    }
  };
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  function deleteSelectedItem(treeData, selectedItemID) {
    // Base case: if treeData is empty, return empty array
    if (!treeData || !treeData.children.length) {
        return [];
    }

    // Filter out the selected item by its ID
    treeData = treeData.children.filter(node => node.id !== selectedItemID);

    // Loop through each item in the treeData
    for (let i = 0; i < treeData.children.length; i++) {
        const node = treeData.children[i];

        // If the current node has children, recursively call the function on its children
        if (node.children && node.children.length) {
            node.children = deleteSelectedItem(node.children, selectedItemID);
        }

        // If after removing the child node, the current node has no more children, remove it as well
        if (node.children && node.children.length === 0) {
            delete node.children;
        }
    }

    return treeData; // Return the updated treeData
}



  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { isValid, dirtyFields, errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    //resolver: yupResolver(schema),
  });
  const convertObjectToTreeData = (obj) => {
    const traverse = (node) => {
      const treeNode = {
        id: node._id,
        name: node.name,
        children: [],
      };

      if (node.children && node.children.length > 0) {
        treeNode.children = node.children.map((child) => traverse(child));
      }

      return treeNode;
    };

    return traverse(obj);
  };

  useEffect(() => {
    if (data) {
      setTreeData(convertObjectToTreeData(data));
    }
  }, [data]);

  useEffect(() => {
    if (typeof treeData !== "object") {
      const filtredData = treeData?.filter((e) => typeof e === "object");
      setUpdatedTreeData(filtredData);
    }
  }, [treeData]);

  const { required, handlers } = useTreeState({
    id: "tree",
    data: [treeData  || []], 
    defaultOpened: true,
    multipleSelect: false,
  });
 

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };
  async function onSubmit(data) {
   
    const formData = new FormData();
   

    if (selectedNode) {
      formData.append("parent", selectedNode?.id);
    }
    formData.append("name", data?.name);
    formData.append("cible", "internal");
    formData.append("establishment", userAdmin?.establishment);
    for (let i = 0; i < data?.images?.length; i++) {
      formData.append("images", data.images[i]);
    }

    await dispatch(createCategoryAsync(formData)).then((res) => {
      console.log(
        "🚀 ~ file: index.jsx:101 ~ onSubmit ~ categoryCreated:",
        res
      );
      toast.success("Category added successfully!");
      //navigate("/services-manager/category/");
    });
  }
  const handleAddText = () => {
    // Add the input text to the selected node
    if (selectedNode) {
      const newNode = {
        id: Date.now(), // You can use a unique identifier here
        name: inputText,
        children: [],
      };

      // Update the tree data structure
      const traverseAndAdd = (nodes) => {
        return nodes?.map((node) => {
          if (node?.id === selectedNode.id) {
            // Add the new node as a child of the selected node
            node.children.push(newNode);
          } else {
            // If the current node has children, recursively traverse them
            if (node.children && node.children.length > 0) {
              node.children = traverseAndAdd(node.children);
            }
          }
          return node;
        });
      };

      setTreeData((prevData) => traverseAndAdd(prevData));
      setInputText(""); // Clear the input text
      setIsAddVisible(false); // Hide the input field
    }
  };

  const renderNode = useCallback(
    ({ node, onToggle }) => (
      <div className="tree-node" key={node.data.title}>
        <div
          onClick={onToggle}
          className={classNames({
            "tree-icon": true,
            "empty-icon": !node.hasChildren(),
            [node.options.opened ? "close-icon" : "open-icon"]:
              node.hasChildren(),
          })}
        />
        <div
          className={classNames({
            "node-content-wrapper": true,
            "node-selected": node.isSelected(),
          })}
          onClick={() => {
            treeHandlers.trees.tree.handlers.setSelected(
              node,
              !node.isSelected()
            );
            setSelectedNode(node.isSelected() ? node : null); // Update the selected node
          }}
        >
          <div className="titles">
            <div className="node-title">{node.data.name}</div>
            {node.data.title && (
              <div className="node-subtitle">{node.data.title}</div>
            )}
          </div>
          {!!node.options.childrenCount && (
            <div className="children-length">
              <div>{node.options.childrenCount}</div>
            </div>
          )}
        </div>
        {/* {selectedNode === node && ( 
          <Fragment>
            <div
              onClick={() => setIsAddVisible(!isAddVisible)}
              className="add-icon"
            >
              <i className="fa fa-plus"></i>
            </div>
            {isAddVisible && (
              <Fragment>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input" // change to lowercase
                      className="form-control"
                      placeholder="Name"
                      errors={errors.name?.message}
                    />
                  )}
                />
                <Button onClick={handleSubmit(onSubmit)} color="secondary">
                  Add
                </Button>
                
              </Fragment>
            )}
          </Fragment>
        )
        } */}
        {/* {selectedNode === node && ( // Show the delete icon if the node is selected
          <div onClick={() => deleteNode(node)} className="delete-icon">
            <i className="fa fa-trash"></i>
          </div>
        )} */}
      </div>
    ),
    [[selectedNode, treeData, isAddVisible, inputText]]
  );

  return (
    <Fragment>
      <Col sm="12" xl="6">
        <Card style={{ width: "400px" }}>
          <HeadingCommon Heading="" />
          <CardBody>
            <Tree
              {...required}
              {...handlers}
              horizontalLineStyles={{
                stroke: "#c4c4c4",
                strokeWidth: 1,
                strokeDasharray: "1 4",
              }}
              verticalLineStyles={{
                stroke: "#c4c4c4",
                strokeWidth: 1,
                strokeDasharray: "1 4",
              }}
              draggable={true}
              gapMode={"padding"}
              depthGap={20}
              disableLines={false}
              disableHorizontalLines={false}
              disableVerticalLines={false}
              verticalLineTopOffset={-17}
              verticalLineOffset={5}
              renderNode={renderNode}
            />
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default EnhanceTree;
