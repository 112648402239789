import { Container, Row} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "sweetalert2";
import { deleteAffectationAdminByIdAsync, getAllAffectationAdminAsync} from "../../../redux-toolkit/slices/affectationAdminSlice";
import TableComp from "../../../Components/dataTable/TableComp";
import { useEffect, useState} from "react";
import { Edit, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";

const AffectationAdminPageContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AffectationsAdminsData = useSelector((state)=> state.affectationAdmin.affectationsAdmins);
  useEffect(() => {
      dispatch(getAllAffectationAdminAsync({page:1,limit:10}));
  },[dispatch]);

  const handelNavigateEditService = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/affectation-manager/affectationsAdmins/edit/${rowName}`, { state: row });
    props.setIsOpen(true);  
  };
    

  const handelDeleteAffectationAdmin = async (row) => {
    const affectationAdminId = row?._id;
    if (!affectationAdminId) {
      return;
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this affectation admin?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          const deletedAffectationAdmin = await dispatch(deleteAffectationAdminByIdAsync(affectationAdminId));
          if (deleteAffectationAdminByIdAsync.fulfilled.match(deletedAffectationAdmin)) {
            SweetAlert.fire("Deleted","Affectation Admin deleted successfully!","success");
            dispatch(getAllAffectationAdminAsync({ page: 1, limit: 10 }));

          } else {
            SweetAlert.fire("Error!",`${deletedAffectationAdmin.payload} ertgdr`,"error");
          }
        } catch (error) {
          SweetAlert.fire("Error!", "Affectation Admin could not be deleted.", "error");
        }
      }
    });
  };

  const AffectationsAdminsTableColumns = [
    {
      name: "Admin",
      selector: (row) => row?.admin?.email,
      sortable: true,
      center: true,
    },
    {
      name: "establishment",
      selector: (row) => row?.establishments?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Roles",
      selector: (row) => {
        if (row?.role && row?.role?.length > 0 ) {
          return row["role"].map((r => r["name"])).join(", ");
        } else {
          return "N/A";
        }
      },
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            style={{ cursor: "pointer" }}
          >
            <Trash
              width={20}
              color="red"
              onClick={() => handelDeleteAffectationAdmin(row)}
            />
          </a>
          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditService(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <Container fluid={true}>
      <Row>
        <TableComp
          data={AffectationsAdminsData}
          columns={AffectationsAdminsTableColumns}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          fixedHeader={true}
          customStyles={customStyles}
        />
      </Row>
    </Container>
  );
};
export default AffectationAdminPageContainer;