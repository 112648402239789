import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPack, deletePack, getAllPacks, updatePack } from "../services/PackService";

const initialStatePack = {
    packs: [],
    loading: false,
    error: null,
  };

  export const getAllPacksAsync = createAsyncThunk(
    "Pack/fetchData",
    async (params={},{ rejectWithValue }) => {
        const {page=null, limit=null} = params;
      try {
        const response = await getAllPacks({page, limit});
        return response.data;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );
  export const createPackAsync = createAsyncThunk(
    "Pack/create",
    async (data,{rejectWithValue}) => {
        try {
            const response = await createPack(data);
            return response;
        } catch (error) {
            if (error.response && error.response.data) {
              return rejectWithValue(error.response.data.message);
            } else {
              return rejectWithValue(error.message);
            }
        }
    }
  )

  export const deletePackAsync = createAsyncThunk(
    "Pack/delete",
    async(packId,{rejectWithValue}) => {
      try {
        const response = await deletePack(packId);
        return response ;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );

  export const updatePackAsync = createAsyncThunk(
    "Pack/update",
    async ({packId,data},{rejectWithValue}) => {
        try {
            const response = await updatePack({packId,data});
            return response;
        } catch (error) {
          if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
          } else {
            return rejectWithValue(error.message);
          }
        }
    }
  )
  
  
const packSlice = createSlice({
    name: "pack",
    initialState: initialStatePack,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllPacksAsync.fulfilled, (state, action) => {
          state.packs = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(getAllPacksAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            
        })
        .addCase(getAllPacksAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(createPackAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
        .addCase(createPackAsync.fulfilled, (state, action) => {
          if (action.payload.data.data) {
            state.packs.push(action.payload.data.data);
          }
          state.loading = false;
          state.error = null;
        })
        .addCase(createPackAsync.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error;
        })
        .addCase(updatePackAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(updatePackAsync.fulfilled, (state, action) => {
            const updatedPack = action.payload.data.data;
            const index = state.packs.findIndex(pack => pack._id === updatedPack._id);
            if (index !== -1) {
              state.packs[index] = updatedPack;
            }
            state.loading = false;
            state.error = null;
          })
          .addCase(updatePackAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
          })
          .addCase(deletePackAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(deletePackAsync.fulfilled, (state, action) => {
            const deletedPackId = action.payload.data;
            state.packs = state.packs.filter(
              (pack) => pack._id !== deletedPackId
            );
            state.loading = false;
            state.error = null;
          })
          .addCase(deletePackAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
          });
    
    }

})
export const packReducer = packSlice.reducer;
const {actions} = packSlice;