import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addRole,
  deleteRole,
  updateRole,
  getAllRoles,
  getAllRoleByEstablishmentId,
  getAllRolesByEstablishmentId,
} from "../services/roleService";

const initialStateRole = {
  roles: [],
  rolesByEstablishment:[],
  loading: false,
  loadingAddRole: false,
  error: null,
};

export const getAllRolesByEstablishmentIdAsync = createAsyncThunk(
  "role/fetchDataByEstablishmentId",
  async (params= {},{ rejectWithValue }) => {
    const {establishmentId, page = null, limit = null} = params;
    try {
      const response = await getAllRolesByEstablishmentId(establishmentId, page, limit);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllRolesAsync = createAsyncThunk(
  "role/fetchData",
  async (_,{ rejectWithValue }) => {
    try {
      const response = await getAllRoles();
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addNewRole = createAsyncThunk("role/addNewRole", async (data) => {
  console.log("data disapatched", data);
  try {
    const response = await addRole(data);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateOneRole = createAsyncThunk(
  "role/updateRole",
  async ({ id, data }) => {
    console.log("data disapatched id ", id);
    console.log("data disapatched data", data);

    try {
      const response = await updateRole(id, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteOneRole = createAsyncThunk(
  "role/deleteOneRole",
  async (data) => {
    console.log("data disapatched", data);
    try {
      const response = await deleteRole(data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState: initialStateRole,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRolesByEstablishmentIdAsync.fulfilled, (state, action) => {
      state.rolesByEstablishment = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllRolesAsync.fulfilled, (state, action) => {
      state.roles = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addNewRole.fulfilled, (state, action) => {
      state.roles.push(action.payload.data);
      state.loadingAddRole = false;
      state.error = null;
    });
    builder.addCase(addNewRole.pending, (state, action) => {
      state.loadingAddRole = true;
      state.error = null;
    });
    builder.addCase(addNewRole.rejected, (state, action) => {
      state.loadingAddRole = false;
      state.error = action.error;
    });
    builder.addCase(deleteOneRole.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteOneRole.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteOneRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateOneRole.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateOneRole.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateOneRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const roleReducer = roleSlice.reducer;
const { actions } = roleSlice;
